import axios from 'axios';

let BASE_URL = process.env.REACT_APP_INT_URL;

export const getInboxInvoiceList = async (data) => {
    return await axios.post(BASE_URL + '/portal/getInboxInvoiceList', data);
};

export const getOutboxInvoiceList = async (data) => {
    return await axios.post(BASE_URL + '/portal/getOutboxInvoiceList', data);
};

export const getInboxInvoicePdf = async (data) => {
    return await axios.post(BASE_URL + '/portal/getInboxInvoicePdf', data);
};

export const getOutboxInvoicePdf = async (data) => {
    return await axios.post(BASE_URL + '/portal/getOutboxInvoicePdf', data);
};

export const getInboxInvoiceView = async (data) => {
    return await axios.post(BASE_URL + '/portal/getInboxInvoiceView', data);
};

export const getOutboxInvoiceView = async (data) => {
    return await axios.post(BASE_URL + '/portal/getOutboxInvoiceView', data);
};

export const getInboxInvoiceData = async (data) => {
    return await axios.post(BASE_URL + '/portal/getInboxInvoiceData', data);
};

export const getOutboxInvoiceData = async (data) => {
    return await axios.post(BASE_URL + '/portal/getOutboxInvoiceData', data);
};

export const cancelEArchiveInvoice = async (data) => {
    return await axios.post(BASE_URL + '/portal/cancelEArchiveInvoice', data);
};

export const sendDocumentResponse = async (data) => {
    return await axios.post(BASE_URL + '/portal/sendDocumentResponse', data);
};
