import axios from 'axios';

let BASE_URL = process.env.REACT_APP_INT_URL;

async function importHtml(data) {
    return await axios.post(`${BASE_URL}/zenom/importHtml`, data);
}

async function sendReceiptsData(data) {
    return await axios.post(`${BASE_URL}/zenom/sendReceipts`, data);
}

async function getCsvList(data) {
    return await axios.post(`${BASE_URL}/edefter/getCsvList`, data);
}

async function sendCsv(data) {
    return await axios.post(`${BASE_URL}/edefter/sendCsv`, data);
}

async function deleteCsv(data) {
    return await axios.post(`${BASE_URL}/edefter/delete`, data);
}

export { importHtml, sendReceiptsData, getCsvList, sendCsv, deleteCsv };
