import React from 'react';
import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';

export default function InvoiceUpdateDialog({ dialogVisible, selectedInvoice, onCloseDialog, onSubmitDialog }) {
    const [invoiceType, setInvoiceType] = useState(null);
    const [selectedAlias, setSelectedAlias] = useState(null);
    const [checked, setChecked] = useState('');

    useEffect(() => {
        if (selectedInvoice) {
            setInvoiceType(selectedInvoice.fatura_tipi);
        }
    }, [selectedInvoice]);

    useEffect(() => {
        if (!dialogVisible) {
            setSelectedAlias(null);
            setChecked('');
        }
    }, [dialogVisible]);

    if (!selectedInvoice) {
        return null;
    }

    const getAliasTemplate = (rowData) => {
        if (rowData?.isEInvoice === true) {
            const copy = [];
            rowData.aliases.forEach((alias) => {
                copy.push({ label: alias.alias, value: alias.alias });
            });
            if (selectedAlias === null) {
                setSelectedAlias(rowData.alias);
            }
            return (
                <>
                    <div className="mb-2">
                        <label>Mail Güncelleme</label>
                    </div>
                    <Dropdown value={selectedAlias} onChange={(e) => setSelectedAlias(e.value)} options={copy} optionLabel="label" placeholder="Mail Seçiniz" filter className="w-full mb-4" emptyMessage="Mail Bulunamadı" />
                </>
            );
        }
    };

    const paperOrElectronic = (rowData) => {
        if (rowData?.isEInvoice === true) {
            if (checked === '') {
                setChecked(rowData.send_type);
            }
            return (
                <div>
                    <div className="mb-2">
                        <label>Gönderim Şekli</label>
                    </div>
                    <div className="flex align-items-center mb-3">
                        <RadioButton inputId="checked1" value="TEMELFATURA" onChange={(e) => setChecked(e.value)} checked={checked === 'TEMELFATURA'} />
                        <label htmlFor="checked1" className="ml-2">
                            Temel Fatura
                        </label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton inputId="checked2" value="TICARIFATURA" onChange={(e) => setChecked(e.value)} checked={checked === 'TICARIFATURA'} />
                        <label htmlFor="checked2" className="ml-2">
                            Ticari Fatura
                        </label>
                    </div>
                </div>
            );
        } else {
            if (checked === '') {
                setChecked(rowData.send_type);
            }
            return (
                <div>
                    <div className="mb-2">
                        <label>Gönderim Şekli</label>
                    </div>
                    <div className="flex align-items-center mb-3">
                        <RadioButton inputId="checked1" value="KAGIT" onChange={(e) => setChecked(e.value)} checked={checked === 'KAGIT'} />
                        <label htmlFor="checked1" className="ml-2">
                            Kağıt
                        </label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton inputId="checked2" value="ELEKTRONIK" onChange={(e) => setChecked(e.value)} checked={checked === 'ELEKTRONIK'} />
                        <label htmlFor="checked2" className="ml-2">
                            Elektronik
                        </label>
                    </div>
                </div>
            );
        }
    };

    return (
        <Dialog header="Güncelleme" visible={dialogVisible} style={{ width: '30vw' }} modal onHide={() => onCloseDialog()}>
            <div className="py-3">
                <div>{getAliasTemplate(selectedInvoice)}</div>

                <div className="">
                    <div className="mb-2">
                        <label>Fatura Türleri</label>
                    </div>
                    <div className="mb-4">
                        <Dropdown
                            dataKey="value"
                            value={invoiceType}
                            onChange={(e) => setInvoiceType(e.value)}
                            options={[
                                { value: 'refund', text: 'İade Faturası' },
                                { value: 'invoice', text: 'Satış Faturası' },
                                { value: 'ISTISNA', text: 'İstisna Faturası' }
                            ]}
                            optionLabel="text"
                            placeholder="Fatura Türü Seçiniz"
                            filter
                            className="w-full"
                            emptyMessage="Fatura Bulunamadı"
                            emptyFilterMessage="Fatura Bulunamadı"
                        />
                    </div>
                </div>

                <div className="mt-4">{paperOrElectronic(selectedInvoice)}</div>

                <div className="justify-content-end flex mt-5">
                    <Button icon="pi pi-check" label="Güncelle" onClick={() => onSubmitDialog(invoiceType, selectedAlias, checked)} />
                </div>
            </div>
        </Dialog>
    );
}
