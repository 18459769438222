import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatAmount, formatDate } from '../../utils/utils';
import { getStatusColor } from '../utils/utils';
import { getInvoicesStatus } from '../../../module/parasut/utils/parasutRequest';

export default function LogDialog({ visible, toast, companyCode, onCloseDialog }) {
    const [logList, setLogList] = useState([]);

    useEffect(() => {
        if (visible) {
            getStatus();
        }
    }, [visible, companyCode]);

    const getStatus = async () => {
        try {
            let logDate = new Date();
            logDate = formatDate(logDate, 'slash');
            const res2 = await getInvoicesStatus({ companyId: companyCode, date: logDate });
            setLogList(res2?.data.data);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    return (
        <Dialog header="Gönderilmiş E-Fatura Kayıtları" visible={visible} style={{ width: '50vw', height: '50vh' }} modal onHide={() => onCloseDialog()}>
            <DataTable value={logList} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} emptyMessage="Kayıt Bulunamadı">
                <Column field="customer" header="Müşteri" exportable={false}></Column>
                <Column field="invoice_id" header="Fatura Numarası"></Column>
                <Column field="invoice_number" header="Fatura Sıra Numarası"></Column>
                <Column field="net_total" header="Toplam Tutar" body={(rowData) => formatAmount(rowData.net_total, 'TRY')}></Column>
                <Column
                    field="invoice_status"
                    body={(rowData) => (
                        <span className={`p-badge text-white h-auto`} style={{ backgroundColor: getStatusColor(rowData.invoice_status) }}>
                            {rowData.invoice_status}
                        </span>
                    )}
                    header="Fatura Durumu"
                ></Column>
            </DataTable>
        </Dialog>
    );
}
