import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';

export default function InvoiceViewDialog({ invoiceVisible, invoiceView, onCloseDialog, onDownloadPDF, onDownloadXML }) {
    if (!invoiceVisible) return null;

    const dialogFooterTemplate = () => {
        return (
            <div>
                <Button icon="pi pi-file-pdf" label="PDF İndir" className="mr-2"  tooltip="PDF İndir" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded onClick={() => onDownloadPDF()} />
                <Button
                    icon="pi pi-file-excel"
                    label="XML İndir"
                    severity="warning"
                    className="mr-2"
                    tooltip="XML İndir"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    onClick={() => onDownloadXML()}
                />
            </div>
        );
    }
    return (
        <Dialog
            header="Belge"
            visible={invoiceVisible}
            style={{
                width: '50vw',
                overflow: 'hidden'
            }}
            maximizable
            modal
            contentStyle={{ height: '100vh' }}
            onHide={() => onCloseDialog()}
            className="p-dialog-xslt"
            footer={dialogFooterTemplate}
        >
            
            <iframe className="w-full h-full" srcDoc={invoiceView} title="Hello" style={{ border: 'none' }} />
        </Dialog>
    );
}