import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { exciseDuty, comminicationsTax } from '../utils/utils'

const exciseDutyStatus = (value) => {
    return (
        value.excise_duty_value !== null &&
        value.excise_duty_value !== '0.0' &&
        value.excise_duty_rate !== null &&
        value.excise_duty_rate !== '0.0'
    )
}

const comminicationsTaxStatus = (value) => {
    return (
        value.communications_tax_value !== null &&
        value.communications_tax_value !== '0.0' &&
        value.communications_tax_rate !== null &&
        value.communications_tax_rate !== '0.0'
    )
}

export default function VatDialog({ visible, invoiceUnitAttributes, onCloseDialog, onSetTax }) {
    const [selectedExciseDuty, setSelectedExciseDuty] = useState(null)
    const [selectedCommunicationsTax, setSelectedCommunicationsTax] = useState(null)

    useEffect(() => {
        if (!visible) {
            setSelectedExciseDuty(null)
            setSelectedCommunicationsTax(null)
        }
    }, [visible])

    if (!visible) {
        return null
    }

    return (
        <Dialog header="Vergi Ekleme" visible={visible} style={{ width: '30vw' }} modal onHide={() => onCloseDialog()}>
            <div className="py-3">
                {exciseDutyStatus(invoiceUnitAttributes) && (
                        <>
                            <div className="mb-2">
                                <label>ÖTV Vergi Tipi</label>
                            </div>

                            <div className="mb-4">
                                <Dropdown
                                    dataKey="code"
                                    value={selectedExciseDuty}
                                    onChange={(e) => setSelectedExciseDuty(e.value)}
                                    options={exciseDuty}
                                    optionLabel={(option) => option.code + ' - ' + option.name.substring(0, 60)}
                                    placeholder="ÖTV Tipi Seçiniz"
                                    filter
                                    className="w-full"
                                    emptyMessage="ÖTV Tipi Bulunamadı"
                                    emptyFilterMessage="ÖTV Tipi Bulunamadı"
                                />
                            </div>
                        </>
                    )}

                {
                    comminicationsTaxStatus(invoiceUnitAttributes) && (
                    <>
                        <div className="mb-2">
                            <label>ÖİV Vergi Tipi</label>
                        </div>

                        <div className="">
                            <Dropdown
                                dataKey="code"
                                value={selectedCommunicationsTax}
                                onChange={(e) => setSelectedCommunicationsTax(e.value)}
                                options={comminicationsTax}
                                optionLabel={(option) => option.code + ' - ' + option.name.substring(0, 70)}
                                placeholder="ÖİV Tipi Seçiniz"
                                filter
                                className="w-full"
                                emptyMessage="ÖİV Tipi Bulunamadı"
                                emptyFilterMessage="ÖİV Tipi Bulunamadı"
                            />
                        </div>
                    </>
                )}
                <div className="justify-content-end flex mt-5">
                    <Button icon="pi pi-check" label="Vergi Ekle" onClick={() => onSetTax(selectedExciseDuty, selectedCommunicationsTax)} disabled={!selectedCommunicationsTax && !selectedExciseDuty} />
                </div>
            </div>
        </Dialog>
    )
}