import React from 'react'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getStatusColor } from '../utils/utils'
import { formatAmount } from '../../utils/utils'

export default function InvoiceLogDialog({ logVisible, logList, onCloseDialog }) {
    return (
        <Dialog header="Gönderilmiş E-Fatura Kayıtları" visible={logVisible} style={{ width: '50vw', height: '50vh' }} modal onHide={() => onCloseDialog()}>
            <DataTable value={logList} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} emptyMessage="Kayıt Bulunamadı">
                <Column field="customer" header="Müşteri" exportable={false}></Column>
                <Column field="invoice_id" header="Fatura Numarası"></Column>
                <Column field="invoice_number" header="Fatura Sıra Numarası"></Column>
                <Column field="net_total" header="Toplam Tutar" body={rowData => formatAmount(rowData.net_total, "TRY")}></Column>
                <Column
                    field="invoice_status"
                    body={(rowData) => (
                        <span className={`p-badge text-white h-auto`} style={{ backgroundColor: getStatusColor(rowData.invoice_status) }}>
                            {rowData.invoice_status}
                        </span>
                    )}
                    header="Fatura Durumu"
                ></Column>
            </DataTable>
        </Dialog>
    )
}