import React from 'react';

import { useThemeContext } from './context/useThemeContext';

const AppFooter = () => {
    const { colorScheme } = useThemeContext();
    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <img id="footer-logo" className='w-auto' src={`assets/layout/images/bien.png`} alt="atlantis-layout" />
            </div>
        </div>
    );
};

export default AppFooter;
