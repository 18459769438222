import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

export const NotFound = (props) => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/dashboard');
    };

    return (
        <div className="exception-body notfound">
            <div className="exception-panel">
                <h1>404</h1>
                <h3>bulunamadı</h3>
                <p>Erişmeye çalıştığınız sayfa mevcut değil.</p>

                <Button type="button" label="Anasayfaya Dön" onClick={goDashboard}></Button>
            </div>
        </div>
    );
};
