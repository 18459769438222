import React, { useState, useEffect, useRef } from 'react';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSelector } from 'react-redux';

import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';

import WebServicesReq from './components/WebServicesReq';
import Loading from "../../Loading"
import ProgramReq from './components/ProgramReq';

import { ParasutRequest } from './utils/parasutRequest';

import { getCompanyForDropdown } from './utils/util';

import { updateCompanyModuleAsyncThunk } from '../../../store/modules/moduleSlice';

export default function Parasut() {
    const { selectedModule, selectedProgram, module } = useSelector((state) => state.modules);
    const { webServiceReq, programReq } = useSelector((state) => state.parasut);
    const { selectedCompany} = useSelector((state) => state.userCompany);

    const [companyList, setCompanyList] = useState(null);
    const [selectedCompanyInner, setSelectedCompanyInner] = useState(null);

    const [isLoginParasut, setIsLoginParasut] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const [loading, setLoading] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);

    const [parasutCompanies, setParasutCompanies] = useState([]);

    const dispatch = useDispatch();
    const toast = useRef(null);

    useEffect(() => {
        if (loginError) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: loginError, life: 3000 });

            setTimeout(() => {
                setLoginError(null);
            }, 3000);
        }
    }, [loginError]);

    const login = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await ParasutRequest.login(programReq.username, programReq.password, programReq.clientId, programReq.clientSecret);

        if (res.name === 'AxiosError') {
            setLoginError('Paraşüt Giriş bilgileriniz hatalıdır.');
        } else {
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Paraşüt Girişi Başarılı.', life: 3000 });
            }, 100);
            setCompanyList(getCompanyForDropdown(res));
            setSelectedCompanyInner(null);
            setIsLoginParasut(true);
        }

        setLoading(false);
    };

    const handleChangeCompany = (e) => {
        setSelectedCompanyInner(e.value);
    };

    const save = async () => {
        const data = {
            companyId: selectedCompany._id,
            module: {
                moduleId: module._id,
                title: module.title,
                program: {
                    _id: selectedProgram._id,
                    title: selectedProgram.title,
                    requirements: [
                        {
                            username: programReq.username,
                            password: programReq.password,
                            clientId: programReq.clientId,
                            clientSecret: programReq.clientSecret
                        }
                    ],
                    parasutCompanies: parasutCompanies
                },
                webService: {
                    requirements: webServiceReq,
                    _id: selectedModule._id,
                    title: selectedModule.title,
                    url: selectedModule.url
                }
            }
        };

        dispatch(updateCompanyModuleAsyncThunk(data));
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül Eklendi', life: 1500 });

        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    const handleCreateCompany = () => {
        const company = companyList.find((item) => item.value === selectedCompanyInner);
        const data = {
            name: company.title,
            id: company.value,
        };

        setParasutCompanies([...parasutCompanies, data]);

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Eklendi', life: 1500 });
        }, 100);

        setCompanyList(companyList.filter((item) => item.value !== selectedCompanyInner));
        setSelectedCompanyInner(null);
    };

    const handleDeleteCompany = (rowData) => {
        setParasutCompanies(parasutCompanies.filter((item) => item.id !== rowData.id));
        setCompanyList([...companyList, { title: rowData.name, value: rowData.id }]);

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Silindi', life: 1500 });
        }, 100);
    };

    const actionCodeTemplate = (rowData) => {
        return <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="mx-2" rounded outlined severity="danger" onClick={() => handleDeleteCompany(rowData)} />;
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <div className="">
                <div className="flex flex-column md:flex-row">
                    <div className="w-full">
                        <ProgramReq onLogin={login} isLogin={isLoginParasut} />

                        {isLoginParasut && (
                            <div className="mt-5">
                                <label className="mb-2 block">Şirketler</label>
                                <Dropdown
                                    dataKey="_id"
                                    value={selectedCompanyInner}
                                    onChange={handleChangeCompany}
                                    options={companyList}
                                    optionLabel="title"
                                    placeholder="Şirket Seçiniz"
                                    filter
                                    className="w-full mb-4"
                                    emptyMessage="Şirket Bulunamadı"
                                    appendTo="self"
                                />
                                <Button label="Şirket Ekle" icon="pi pi-plus-circle" className="p-button-primary text-white mr-4 mb-0" onClick={() => handleCreateCompany()} disabled={!selectedCompanyInner} />
                            </div>
                        )}
                    </div>

                    <div className="w-full md:w-1">
                        <Divider layout="vertical" className="hidden md:flex"></Divider>
                    </div>

                    <WebServicesReq />
                </div>
            </div>

            {parasutCompanies && isLoginParasut && (
                <DataTable
                    value={parasutCompanies}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                    emptyMessage="Şirket Bulunamadı"
                    className="my-6"
                    header={header}
                    globalFilter={globalFilter}
                >
                    <Column field="id" header="Şirket ID" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="name" header="Şirket Adı" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column header="İşlemler" body={actionCodeTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            )}

            {parasutCompanies.length > 0 && (
                <div className="text-center mt-5">
                    <Button label="Kaydet" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} />
                </div>
            )}
        </div>
    );
}
