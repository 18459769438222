import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import { Provider } from 'react-redux';

import AppWrapper from './AppWrapper';

import store from './store/store';
import { setupAxiosInterceptors } from './service/tokenServices';

import { ThemeProvider } from './context/useThemeContext';

setupAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <HashRouter>
            <ThemeProvider>
                <AppWrapper />
            </ThemeProvider>
        </HashRouter>
    </Provider>
);