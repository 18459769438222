import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

export const Error = () => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/dashboard');
    };

    return (
        <div className="exception-body error">
            <div className="exception-panel">
                <h1>Hata !</h1>
                <h3>Bir şeyler ters gitti : (</h3>
                <Button type="button" label="Anasayfaya Dön" onClick={goDashboard}></Button>
            </div>
        </div>
    );
};
