import React, { useState, useEffect, useRef } from 'react';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { useSelector } from 'react-redux';

import { setUpdatedModule, updateCompanySettingsAsyncThunk } from '../../../store/modules/moduleSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import WebServicesReq from './components/WebServicesReq';
import ProgramReq from './components/ProgramReq';
import Loading from '../../Loading';
import { getCompaniesByUserAsyncThunk } from '../../../store/company/userCompanySlice';

export default function FilterDemo() {
    const { updatedModule, selectedModule } = useSelector((state) => state.modules);
    const { webServiceReq, programReq } = useSelector((state) => state.datasoft);
    const { selectedCompany } = useSelector((state) => state.userCompany);

    const [loading, setLoading] = useState(false);
    const [pending, setPending] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();
    const toast = useRef(null);

    useEffect(() => {
        let path = location.pathname.split('/')[1];
        let module = selectedCompany.modules.find((item) => item.title === path);
        dispatch(setUpdatedModule(module));
    }, [location.pathname, selectedCompany.modules, dispatch, updatedModule]);

    const save = async () => {
        const data = {
            companyId: selectedCompany.id,
            moduleId: updatedModule.id,
            settings: {
                moduleId: updatedModule.id,
                title: updatedModule.title,
                program: {
                    _id: updatedModule.setting.settings.program._id,
                    title: updatedModule.setting.settings.program.title,
                    requirements: [
                        {
                            taxNumber: programReq.taxNumber,
                            eDefterSubeKodu: programReq.eDefterSubeKodu,
                            eDefterSubeAdi: programReq.eDefterSubeAdi
                        }
                    ]
                },
                webService: {
                    requirements: webServiceReq,
                    _id: selectedModule._id || selectedModule.id,
                    title: selectedModule.title,
                    url: selectedModule.url
                }
            }
        };

        setPending(true);
        await dispatch(updateCompanySettingsAsyncThunk(data));
        await dispatch(getCompaniesByUserAsyncThunk());
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül güncellendi', life: 1500 });

        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }
    const programReqState = () => {
        return programReq?.taxNumber;
    };

    const webServiceReqState = () => {
        return webServiceReq?.username && webServiceReq?.password;
    };

    const isDisabled = () => {
        if (programReqState() && webServiceReqState()) {
            return false;
        }

        return true;
    };
    return (
        <div className="card">
            <Toast ref={toast} />
            {/* <Button icon="pi pi-cog" label="Modül Değiştir" onClick={() => setUpdateDialog(true)}></Button> */}
            <div className="">
                <div className="flex flex-column md:flex-row">
                    <ProgramReq req={updatedModule?.setting.settings.program.requirements[0]} title={updatedModule?.setting.settings.program.title} />

                    <div className="w-full md:w-1">
                        <Divider layout="vertical" className="hidden md:flex"></Divider>
                    </div>

                    <WebServicesReq req={updatedModule?.setting.settings.webService.requirements} title={updatedModule?.setting.settings.webService.title} />
                </div>
            </div>

            <div className="text-center mt-5">
                <Button label="Kaydet" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} disabled={isDisabled()} loading={pending} />
            </div>
            {/* <ModuleChangeDialog visible={updateDialog} onCloseDialog={() => setUpdateDialog(false)} /> */}
        </div>
    );
}
