import React, { useState, useRef } from 'react';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';

import WebServicesReq from './components/WebServicesReq';
import ProgramReq from './components/ProgramReq';
import { updateCompanyModuleAsyncThunk } from '../../../store/modules/moduleSlice';
import Loading from "../../Loading"

export default function FilterDemo() {
    const { selectedModule, selectedProgram } = useSelector((state) => state.modules);
    const { webServiceReq, programReq } = useSelector((state) => state.zenom);
    const { selectedCompany } = useSelector((state) => state.userCompany);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const toast = useRef(null);

    const save = async () => {
        let module = selectedCompany.modules.find((item) => item.title === 'E-Defter');
        const data = {
            companyId: selectedCompany._id,
            module: {
                moduleId: module._id,
                title: module.title,
                program: {
                    _id: selectedProgram._id,
                    title: selectedProgram.title,
                    requirements: [
                        {
                            taxNumber: programReq.taxNumber,
                            eDefterSubeKodu: programReq.eDefterSubeKodu,
                            eDefterSubeAdi: programReq.eDefterSubeAdi
                        }
                    ]
                },
                webService: {
                    requirements: webServiceReq,
                    _id: selectedModule._id,
                    title: selectedModule.title,
                    url: selectedModule.url
                }
            }
        };

        dispatch(updateCompanyModuleAsyncThunk(data));
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül Eklendi', life: 1500 });

        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <div className="">
                <div className="flex flex-column md:flex-row">
                    <ProgramReq />

                    <div className="w-full md:w-1">
                        <Divider layout="vertical" className="hidden md:flex"></Divider>
                    </div>

                    <WebServicesReq />
                </div>
            </div>

            <div className="text-center mt-5">
                <Button label="Kaydet" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} />
            </div>
        </div>
    );
}
