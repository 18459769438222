import { controlAllData } from './controller';

export const exciseDuty = [
    { code: '0071', name: 'PETROL VE DOĞALGAZ ÜRÜNLERİNE İLİŞKİN ÖZEL TÜKETİM VERGİSİ (ÖTV 1.LİSTE)' },
    { code: '0073', name: 'KOLALI GAZOZ, ALKOLLÜ İÇEÇEKLER VE TÜTÜN MAMÜLLERİNE İLİŞKİN ÖZEL TÜKETİM VERGİSİ (ÖTV 3.LİSTE)' },
    { code: '0074', name: 'DAYANIKLI TÜKETİM VE DİĞER MALLARA İLİŞKİN ÖZEL TÜKETİM VERGİSİ (ÖTV 4.LİSTE)' },
    { code: '0075', name: 'ALKOLLÜ İÇEÇEKLERE İLİŞKİN ÖZEL TÜKETİM VERGİSİ (ÖTV 3A LİSTE)' },
    { code: '0076', name: 'TÜTÜN MAMÜLLERİNE İLİŞKİN ÖZEL TÜKETİM VERGİSİ (ÖTV 3B LİSTE)' },
    { code: '0077', name: 'KOLALI GAZOZLARA İLİŞKİN ÖZEL TÜKETİM VERGİSİ (ÖTV 3C LİSTE)' },
    { code: '4171', name: 'PETROL VE DOĞALGAZ ÜRÜNLERİNE İLİŞKİN ÖTV TEVKİFATI (PTR-DGZ ÖTV TEVKİFAT)' }
];

export const comminicationsTax = [
    { code: '4080', name: 'ÖZEL İLETİŞİM VERGİSİ (Ö.İLETİŞİM V)' },
    { code: '4081', name: '5035 SAYILI KANUNA GÖRE ÖZEL İLETİŞİM VERGİSİ (5035ÖZİLETV.)' }
];

export const getErrorReceiptCount = (invoices) => {
    let count = 0;
    invoices.forEach((item) => {
        if (controlAllData(item) === false) {
            count++;
        }
    });

    return count;
};

export const getInvoice = (selectedCompany) => {
    let copy = [];
    let selectedModule = selectedCompany?.modules?.find((module) => module.title === 'E-Fatura');
    selectedModule?.setting?.settings?.program?.parasutCompanies?.map((company) => {
        copy.push({ label: company.name.substring(0,28), value: company.id });
    });
    return copy;
};

export const invoiceType = {
    invoice: 'Satış Faturası',
    refund: 'İade Faturası',
    export: 'İhracat Faturası',
    TEVKIFAT: 'Tevkifat Faturası',
    ISTISNA: 'İstisna Faturası',
    OZELMATRAH: 'Özel Matrah Faturası',
    IHRACKAYITLI: 'İhraç Kayıtlı Fatura'
};

export const getInvoiceStatus = (rowData) => {
    if (rowData === true) {
        return <span className="p-badge p-badge-success text-white h-auto">E-Fatura</span>;
    } else {
        return <span className="p-badge p-badge-primary h-auto">E-Arşiv</span>;
    }
};

export const sendTypeEnum = {
    TEMELFATURA: 'Temel Fatura',
    TICARIFATURA: 'Ticari Fatura',
    KAGIT: 'Kağıt',
    ELEKTRONIK: 'Elektronik',
    IHRACAT: 'İhracat',
    ISTISNA: 'İstisna'
};

export const getStatusColor = (statusName) => {
    const colorMap = {
        Taslak: '#FA7268',
        'İptal Edildi': '#FA7268',
        Kuyrukta: '#DAA520',
        İşlemde: '#0376D4',
        'GİB’e gönderildi': '#0376D4',
        Onaylandı: '#1D7736',
        'Onay Bekliyor': '#FEC704',
        Reddedildi: '#CD0513',
        'İade Edildi': '#BD70FF',
        'e-Arşiv İptal': '#FF6347',
        Hata: '#A20510'
    };

    return colorMap[statusName] || '';
};

export const getSendReqData = (selectedCompany, selectedCompanyCode, data) => {
    const program = selectedCompany.modules.filter((module) => module.title === 'E-Fatura')[0].setting.settings.program;
    const parasutCompanies = program.parasutCompanies.filter((company) => company.id === selectedCompanyCode)[0];
    const webServiceUsername = parasutCompanies.webService.requirements.username;
    const webServicePassword = parasutCompanies.webService.requirements.password;
    const webServiceUrl = parasutCompanies.webService.url;
    const companyId = selectedCompanyCode;

    const reqData = {
        webServiceUsername: webServiceUsername,
        webServicePassword: webServicePassword,
        webServiceUrl: webServiceUrl,
        companyId: companyId,
        data: data
    };

    return reqData;
};

export const sendDisabledStatus = (selectedReceipt) => {
    if (selectedReceipt && selectedReceipt.length !== 0) {
        if (getErrorReceiptCount(selectedReceipt) !== 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
};
