import { education } from '../url';
import axios from 'axios';

export const getAllCategories = async () => {
    return await axios.get(education.getAll);
};

export const getCategoryById = async (id) => {
    return await axios.get(education.getById + '?id=' + id);
};

export const createCategory = async (data) => {
    return await axios.post(education.create, data);
};

export const updateCategory = async (data) => {
    return await axios.put(education.update, data);
};

export const deleteCategory = async (id) => {
    return await axios.delete(education.delete + '?id=' + id);
};

export const getAllVideos = async () => {
    return await axios.get(education.getAllVideos);
};

export const getVideoById = async (id) => {
    return await axios.get(education.getVideoById + '?id=' + id);
};

export const createVideo = async (data) => {
    return await axios.post(education.createVideo, data);
};

export const updateVideo = async (data) => {
    return await axios.put(education.updateVideo, data);
};

export const deleteVideo = async (id) => {
    return await axios.delete(education.deleteVideo + '?id=' + id);
};
