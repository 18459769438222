import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getToken, getUser, getCompanyId } from './service/tokenServices';
import { setIsAuthenticated, setStoreUser } from './store/auth/authSlice';

import { setSelectCompany } from './store/company/userCompanySlice';

import App from './App';
import { Login } from './pages/Login';
import Loading from "./pages/Loading"

const AppWrapper = () => {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const { selectedCompany } = useSelector((state) => state.userCompany);

    const dispatch = useDispatch();

    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const [isLoading, setIsLoading] = useState(true);

    const setOptions = async () => {
        const token = getToken();
        const user = getUser();
        const companyId = getCompanyId();
        if (token || user || companyId) {
            dispatch(setIsAuthenticated(true));
            dispatch(setStoreUser(user))
            dispatch(setSelectCompany(companyId));

        } else {
            dispatch(setIsAuthenticated(false));
            dispatch(setStoreUser(null));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setOptions();
    }, []);

    if (isLoading) {
        return <div style={{ height: "100vh" }}>
            <Loading />
        </div>
    }

    return (
        <Routes>
            {isAuthenticated && (selectedCompany || getCompanyId()) ? (
                <>
                    <Route path="/login" element={<Navigate to="/dashboard" />} />
                    <Route path="/*" element={<App />} />
                </>
            ) : (
                <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/*" element={<Navigate to="/login" />} />
                </>
            )}
        </Routes>
    );
};

export default AppWrapper;
