import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { 
getAllCompanies, 
createCompany, 
updateCompany, 
deleteCompany, 
attachUser, 
detachUser, 
attachModule, 
detachModule, 
getModulesByCompany, 
getCompanySettings, 
setCompanySettings 
} from '../../service/request/companyRequest';

export const getAllCompaniesAsyncThunk = createAsyncThunk('company/getAllCompanies', async () => {
    const response = await getAllCompanies();
    return response.data;
});

export const getModulesByCompanyAsyncThunk = createAsyncThunk('company/getModulesByCompany', async (id) => {
    const response = await getModulesByCompany(id);
    return response.data;
});

export const createCompanyAsyncThunk = createAsyncThunk('company/createCompany', async (data) => {
    const response = await createCompany(data);
    return response.data;
});

export const updateCompanyAsyncThunk = createAsyncThunk('company/updateCompany', async (data) => {
    const response = await updateCompany(data);
    return response.data;
});

export const deleteCompanyAsyncThunk = createAsyncThunk('company/deleteCompany', async (id) => {
    const response = await deleteCompany(id);
    return id;
});

export const attachUserAsyncThunk = createAsyncThunk('company/attachUser', async (data) => {
    const response = await attachUser(data);
    return response.data;
});

export const detachUserAsyncThunk = createAsyncThunk('company/detachUser', async (data) => {
    const response = await detachUser(data);
    return response.data;
});

export const attachModuleAsyncThunk = createAsyncThunk('company/attachModule', async (data) => {
    const response = await attachModule(data);
    return response.data;
});

export const detachModuleAsyncThunk = createAsyncThunk('company/detachModule', async (data) => {
    const response = await detachModule(data);
    return response.data;
});

export const setCompanySettingsAsyncThunk = createAsyncThunk('company/setCompanySettings', async (data) => {
    const response = await setCompanySettings(data);
    return response.data;
});

export const getCompanySettingsAsyncThunk = createAsyncThunk('company/getCompanySettings', async (data) => {
    const response = await getCompanySettings(data);
    return response.data;
});

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        companyList: [],
        status: null,
        error: null,
        companies: [],
        modules: [],
        companySettings: {}
    },
    reducers: {
        clearStatus: (state) => {
            state.status = null;
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAllCompaniesAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllCompaniesAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companyList = action.payload.data;
            })
            .addCase(getAllCompaniesAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })

            .addCase(createCompanyAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createCompanyAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companyList = [...state.companyList, action.payload.data];
            })
            .addCase(createCompanyAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })

            .addCase(updateCompanyAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCompanyAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companyList = state.companyList.map((company) => (company._id === action.payload.data._id ? action.payload.data : company));
            })
            .addCase(updateCompanyAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteCompanyAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteCompanyAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companyList = state.companyList.filter((company) => company._id !== action.payload);
            })
            .addCase(deleteCompanyAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(attachUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
            })
            .addCase(attachUserAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(detachUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companies = state.companies.filter((company) => company.id !== action.payload.data.id);
            })
            .addCase(detachUserAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(attachModuleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
            })
            .addCase(attachModuleAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(detachModuleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.modules = state.modules.filter((module) => module.id !== action.payload.data.id);
            })
            .addCase(detachModuleAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getModulesByCompanyAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.modules = action.payload.data;
            })
            .addCase(getModulesByCompanyAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })

            .addCase(setCompanySettingsAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companySettings = action.payload.data;
            })
            .addCase(setCompanySettingsAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })

            .addCase(getCompanySettingsAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companySettings = action.payload.data;
            })
            .addCase(getCompanySettingsAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
    }
});

export const { clearStatus } = companySlice.actions;
export default companySlice.reducer;
