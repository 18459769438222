import ReasonCode from '../../../../assets/data/ReasonCode.json';

export const sendTypeEnum = {
    TEMELFATURA: 'Temel Fatura',
    TICARIFATURA: 'Ticari Fatura',
    KAGIT: 'Kağıt',
    ELEKTRONIK: 'Elektronik',
    IHRACAT: 'İhracat',
    ISTISNA: 'İstisna'
};


export const getErrorReceiptCount = (invoices) => {
    let count = 0;
    invoices.forEach((item) => {
        /* if (controlAllData(item) === false) {

            count++;
        } */
    });

    return count;
};

export const sendReceiptBtnIsDisabled = (selectedReceipt) => {
    if (selectedReceipt && selectedReceipt.length !== 0) {
        if (getErrorReceiptCount(selectedReceipt) !== 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
};

export const getSendingDataDetails = (selectedCompany) => {
    const module = selectedCompany.modules.filter((module) => module.title === 'E-Fatura')[0];
    const webServiceUsername = module.setting.settings.webService.requirements.username;
    const webServicePassword = module.setting.settings.webService.requirements.password;
    const webServiceUrl = module.setting.settings.webService.url;
    const DealerID = module.setting.settings.program.requirements[0].DealerID;
    const EInvoiceCode = module.setting.settings.program.requirements[0].EInvoiceCode;

    return {
        webServiceUsername,
        webServicePassword,
        webServiceUrl,
        DealerID,
        EInvoiceCode
    };
}

//Fatura ortak
export const invoiceDocType = (rowData) => {
    if (rowData) {
        return <span className="p-badge p-badge-success text-white h-auto">E-Fatura</span>;
    } else {
        return <span className="p-badge p-badge-primary h-auto">E-Arşiv</span>;
    }
}

export const formatDate = (inputDate) => {
    
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();


    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

    return formattedDate;
};

export const formatDateSlash = (inputDate) => {

    const [day, month, year] = inputDate.split('/');

    const dateObject = new Date(`${year}-${month}-${day}`);
    
    const formattedDay = dateObject.getDate().toString().padStart(2, '0');
    const formattedMonth = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const formattedYear = dateObject.getFullYear();

    const formattedDate = `${formattedDay}.${formattedMonth}.${formattedYear}`;

    return formattedDate;
};

export const getReasonCode = (invoices, selectedInvoiceUnit) => {
    let result = [];
    for (let invoice of invoices) {
        for (let detail of invoice.EInvoicesLines) {
            if (detail.$id === selectedInvoiceUnit.$id) {
                if (invoice.fatura_tipi === 'ISTISNA') {
                    result = ReasonCode['ISTISNA'];
                    return result
                }
                else if (invoice.fatura_tipi === 'refund') {
                    result = [ReasonCode['refund']];
                    return result
                }
                else if (invoice.fatura_tipi === 'invoice') {
                    result = ReasonCode['invoice'];
                    return result
                }
            }
        };
    };

    return result;
}

//Inbox outbox ortak
export const getStatusColor = (statusName) => {
    const colorMap = {
        Taslak: '#FA7268',
        'İptal Edildi': '#FA7268',
        Kuyrukta: '#DAA520',
        İşlemde: '#0376D4',
        'GİB’e gönderildi': '#0376D4',
        Onaylandı: '#1D7736',
        'Onay Bekliyor': '#FEC704',
        Reddedildi: '#CD0513',
        'İade Edildi': '#BD70FF',
        'e-Arşiv İptal': '#FF6347',
        Hata: '#A20510'
    };

    return colorMap[statusName] || '';
};

export const formatCurrency = (rowData) => {
    let amount = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY'
    }).format(rowData);

    return <p> {amount} </p>;
};