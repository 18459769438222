import React from 'react'
import { Dialog } from 'primereact/dialog'

export default function ViewInvoiceDialog({ visible, invoiceView, onCloseDialog }) {
    return (
        <Dialog
            header="Belge"
            visible={visible}
            style={{
                width: '50vw',
                overflow: 'hidden'
            }}
            maximizable
            modal
            contentStyle={{ height: '100vh' }}
            onHide={() => onCloseDialog()}
            className="p-dialog-xslt"
        >
            <iframe className="w-full h-full" srcDoc={invoiceView} title="Hello" style={{ border: 'none' }} />
        </Dialog>
    )
}