import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

import { isOther } from '../utils/utils';

//E defter ortak
export default function OneReceiptUpdateDialog({ oneEditDialog, onCloseDialog, onUpdateOneReceipt }) {
    const [selectedCode, setSelectedCode] = useState(null);
    const [selectedOtherCode, setSelectedOtherCode] = useState(null);
    const [paymentType, setPaymentType] = useState('');
    const [docNo, setDocNo] = useState('');
    const [docDate, setDocDate] = useState(null);

    useEffect(() => {
        if (!oneEditDialog) {
            setSelectedCode(null);
            setSelectedOtherCode(null);
            setPaymentType('');
            setDocNo('');
            setDocDate(null);
        }
    }, [oneEditDialog]);

    const handleUpdate = () => {
        onUpdateOneReceipt({ paymentType, docNo, docDate });
    };

    return (
        <Dialog
            header="Düzenle"
            visible={oneEditDialog}
            style={{ width: '40vw' }}
            modal
            onHide={() => onCloseDialog()}
            footer={<Button icon="pi pi-check" label="Güncelle" onClick={() => handleUpdate()} disabled={selectedCode && isOther(selectedCode) && !selectedOtherCode} />}
        >
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Belge No</label>
                </div>
                <div className="w-full mb-4">
                    <InputText type="text" className="w-full" value={docNo} onChange={(e) => setDocNo(e.target.value)} placeholder="Belge No" required />
                </div>
                <div className="w-full mb-2">
                    <label>Belge Tarihi</label>
                </div>
                <div className="w-full mb-4">
                    <Calendar value={docDate} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setDocDate(e.value)} showIcon placeholder="Lütfen Belge Tarihi seçiniz" />
                </div>
                <div className="w-full mb-2">
                    <label>Ödeme Yöntemi</label>
                </div>
                <div className="w-full">
                    <InputText type="text" className="w-full" value={paymentType} onChange={(e) => setPaymentType(e.target.value)} placeholder="Ödeme Yöntemi" required />
                </div>
            </div>
        </Dialog>
    );
}
