import React, { useState, useEffect, useRef } from 'react';
import { DataView } from 'primereact/dataview';
import { 
    getAllCategories
} from '../../service/request/educationRequest';
import './iframe.css';
import { Toast } from 'primereact/toast';
import Loading from '../Loading';
import { Dropdown } from 'primereact/dropdown';

export default function Videos() {
    const [categories, setCategories] = useState([]);
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const [selectedCategoryForVideo, setSelectedCategoryForVideo] = useState(null);

    const fetchAllCategories = () => {
        getAllCategories().then((response) => {
            setCategories(response.data.data);

            const copy = [];
            response.data.data.map((category) => {
                category.videos.map((video) => {
                    copy.push(video);
                });
            });
            setLoading(false);
            setVideos(copy);
        });
    };

    useEffect(() => {
        fetchAllCategories();
    }, []);

    const gridItem = (product) => {
        return (
            <div className="p-2" style={{ width: '440px' }} key={product.id}>
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-column gap-1">
                        <div dangerouslySetInnerHTML={{ __html: product.url }}></div>
                        <div className="text-4xl font-bold">{product.title}</div>
                        <div className="text-xl font-semibold">{product.subtitle}</div>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (product) => {
        if (!product) {
            return;
        }
        return gridItem(product);
    };

    const listTemplate = (products) => {
        return <div className="w-full flex flex-wrap justify-content-start gap-4">{products.map((product, index) => itemTemplate(product))}</div>;
    };

    const header = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h1>Videolar</h1>
                <div className='flex align-items-center gap-4 w-4'>
                    <Dropdown
                        dataKey="id"
                        value={selectedCategoryForVideo}
                        onChange={(e) => setSelectedCategoryForVideo(e.value)}
                        appendTo="self"
                        options={categories.map((category) => {
                            return {
                                id: category.id,
                                name: category.module.title + " Modülü - " + category.name
                            }
                        })}
                        optionLabel="name"
                        placeholder="Kategori Seçiniz"
                        filter
                        className="w-full"
                    />
                </div>
            </div>
        );
    };

    if (loading) {
        return <Loading />
    }

    const getFilterVideos = () => {
        if (selectedCategoryForVideo) {
            let copy = videos.filter((video) => video.category_id === selectedCategoryForVideo.id);

            return copy.length > 0 ? [copy] : null;
        }
        return [videos];
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataView
                value={getFilterVideos()}
                itemTemplate={listTemplate}
                layout={"grid"} header={header()}
                emptyMessage='Video Bulunamadı'
                rows={20}
                paginator
            />
        </div>
    );
}
