import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

//Deftere alınacak
export const translateDocType = (kaynakBelgeTuru) => {
    switch (kaynakBelgeTuru) {
        case 'invoice':
            return 'Fatura';
        case 'check':
            return 'Çek';
        case 'order-customer':
            return 'Müşteri Sipariş Belgesi';
        case 'order-vendor':
            return 'Satıcı Sipariş Belgesi';
        case 'voucher':
            return 'Senet';
        case 'shipment':
            return 'Navlun';
        case 'receipt':
            return 'Makbuz';
        case 'other':
            return 'Diğer';
        case '':
            return <Tag severity="danger" value={'BOŞ'} />;
        default:
            return kaynakBelgeTuru;
    }
};

export const docName = (docType, docExp) => {
    if (docType === 'Diğer' && (docExp === '' || docExp === null || docExp === undefined)) {
        return <Tag severity="danger" value={'BOŞ'} />;
    }

    switch (docExp) {
        case docType === 'Diğer' && ('' || null || undefined):
            return <Tag severity="danger" value={'BOŞ'} />;
        default:
            return docType;
    }
};

export const documentData = (docNo, docDate, title) => {
    if (docNo && !docDate) {
        if (title === 'kaynak_belge_tarihi') {
            return <Tag severity="danger" value={'BOŞ'} />;
        }
        return docNo;
    } else if (!docNo && docDate) {
        if (title === 'kaynak_belge_no') {
            return <Tag severity="danger" value={'BOŞ'} />;
        }
        return docDate;
    } else if (docNo && docDate) {
        return title === 'kaynak_belge_no' ? docNo : docDate;
    } else {
        return <Tag severity="danger" value={'BOŞ'} />;
    }
};

export const itemNo = (itemNo) => {
    if (itemNo) {
        return itemNo;
    } else if (itemNo === '' || itemNo === null || itemNo === undefined) {
        return '0';
    }
};

export const checkAmountAndCredit = (amount, credit, title) => {
    if ((amount === '' || amount === null || amount === undefined) && (credit === '' || credit === null || credit === undefined)) {
        return <Tag severity="danger" value={'BOŞ'} />;
    } else if (amount === 0 && credit === 0) {
        return <Tag severity="danger" value={'SIFIR olamaz.'} />;
    } else {
        let amount1 = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY'
        }).format(title === 'borc' ? amount : credit);
        return <p> {amount1} </p>;
    }
};

export const controlSelectedReceipt = (data) => {
    if (data === null) {
        return false;
    }

    for (let i = 0; i < data.length; i++) {
        if (controlAllData(data[i]) === false) return false;
    }
    return true;
};

export const controlAllData = (data) => {
    const receipt = data;

    const receiptDetails = receipt.detay;

    if (receipt.detay.action === false) {
        return;
    }

    if (receipt.detay.length < 2) {
        return false;
    }

    if (String(receipt.borc) === '0' && String(receipt.alacak) === '0') {
        return false;
    }

    if (receipt.borc !== receipt.alacak) {
        return false;
    }

    for (let i = 0; i < Object.values(receiptDetails).length; i++) {
        if (Object.values(receiptDetails[i]).length !== 10 && receiptDetails[i].action === true) {
            return false;
        }
        if (
            (receiptDetails[i]?.kaynak_belge_detay?.kaynak_belge_turu_adi === 'HATA' || receiptDetails[i]?.kaynak_belge_detay?.kaynak_belge_turu_aciklama === 'HATA' || receiptDetails[i]?.kaynak_belge_detay?.kaynak_belge_turu === 'HATA') &&
            receiptDetails[i].action === true
        ) {
            return false;
        }
        if ((receiptDetails[i]?.fis_detay?.DocumentDate  === null || receiptDetails[i]?.fis_detay?.DocumentDate === undefined || receiptDetails[i]?.fis_detay?.DocumentDate === '' ) && receiptDetails[i].action === true) {
            return false;
        }
    }

    return true;
};

export const controlOneData = (data) => {
    const receiptDetail = data;

    if (Object.values(receiptDetail).length !== 10) {
        return false;
    }

    if (receiptDetail?.kaynak_belge_detay?.kaynak_belge_turu_adi === 'HATA' || receiptDetail?.kaynak_belge_detay?.kaynak_belge_turu_aciklama === 'HATA' || receiptDetail?.kaynak_belge_detay?.kaynak_belge_turu === 'HATA') {
        return false;
    }

    if (receiptDetail?.fis_detay?.DocumentDate  === null || receiptDetail?.fis_detay?.DocumentDate === undefined || receiptDetail?.fis_detay?.DocumentDate === '') {
        return false;
    }

    return true;
};

export const rowIsTrue = (rowData, detail) => {
    let check = null;
    if (detail === 'detay') {
        check = controlOneData(rowData);
    } else if (detail === 'fis') {
        check = controlAllData(rowData);
    }

    if (!check) {
        return <Button severity="danger" size="large" icon="pi pi-exclamation-triangle" tooltip="Hatalı satır!" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />;
    }

    return <Button severity="success" icon="pi pi-check" tooltip="Doğru satır!" className="text-white" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />;
};

export const wrongRow = (rowData) => {
    if (!controlAllData(rowData)) {
        return rowData;
    }
};
//Silindi
export const checkBorc = (rowData) => {
    let amount = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY'
    }).format(rowData.borc);

    return <p> {amount} </p>;
};

//Silindi
export const checkAlacak = (rowData) => {
    let amount = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY'
    }).format(rowData.alacak);

    return <p> {amount} </p>;
};

//Silindi
export const formatAmount = (money, formatType) => {
    let amount = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: formatType
    }).format(money);

    return <p> {amount} </p>;
};
