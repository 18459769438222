import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { setUpdatedModule, updateCompanySettingsAsyncThunk } from '../../../store/modules/moduleSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loading from '../../Loading';
import { ParasutRequest } from './utils/parasutRequest';
import { getCompanyForDropdown, deleteCompanyIsHere } from './utils/util';
import ProgramReq from './components/ProgramReq';
import { getInvoiceTypes, uploadXslt, updateXslt, deleteXslt, getXsltByCompanyId, xsltView } from './utils/parasutRequest';
import XsltView from './components/XsltView';
import CompanyTable from './components/CompanyTable';
import XsltTableDialog from './components/XsltTableDialog';
import XsltAddDialog from './components/XsltAddDialog';
import XsltUpdateDialog from './components/XsltUpdateDialog';
import { getCompaniesByUserAsyncThunk } from '../../../store/company/userCompanySlice';

export default function UpdateParasut() {
    const { updatedModule } = useSelector((state) => state.modules);
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const { webServiceReq, programReq } = useSelector((state) => state.parasut);

    const [companyList, setCompanyList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLoginParasut, setIsLoginParasut] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const [parasutCompanies, setParasutCompanies] = useState([]);

    const [addXltsDialog, setAddXltsDialog] = useState(false);

    const [invoiceType, setInvoiceType] = useState(null);

    const [companyId, setCompanyId] = useState(null);
    const [xsltListDialog, setXsltListDialog] = useState(false);
    const [xsltList, setXsltList] = useState(null);
    const [viewXsltDialog, setViewXsltDialog] = useState(false);
    const [xsltHtml, setXsltHtml] = useState(null);

    const [updateXsltDialog, setUpdateXsltDialog] = useState(false);
    const [selectedXslt, setSelectedXslt] = useState(null);
    const [updateDialog, setUpdateDialog] = useState(false);
    const [pending, setPending] = useState(false);
    const [counterDialog, setCounterDialog] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();
    const toast = useRef(null);

    useEffect(() => {
        let path = location.pathname.split('/')[1];
        let module = selectedCompany?.modules?.find((item) => item.title === path);
        dispatch(setUpdatedModule(module));
    }, [location.pathname, selectedCompany?.modules, dispatch, updatedModule]);

    useEffect(() => {
        if (updatedModule) {
            setParasutCompanies(updatedModule.setting.settings.program.parasutCompanies);
        }
    }, [updatedModule]);

    useEffect(() => {
        if (loginError) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: loginError, life: 3000 });

            setTimeout(() => {
                setLoginError(null);
            }, 3000);
        }
    }, [loginError]);

    useEffect(() => {
        if ((addXltsDialog || updateXsltDialog) && !invoiceType) {
            getInvoiceTypes().then((res) => {
                const copy = [];
                res.data.data.forEach((item) => {
                    copy.push({ title: item.name, value: item.id });
                });
                setInvoiceType(copy);
            });
        }
    }, [addXltsDialog, updateXsltDialog, invoiceType]);

    const login = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await ParasutRequest.login(programReq.username, programReq.password, programReq.clientId, programReq.clientSecret);

        if (res.name === 'AxiosError') {
            setLoginError('Paraşüt Giriş bilgileriniz hatalıdır.');
        } else {
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Paraşüt Girişi Başarılı.', life: 1500 });
            }, 100);
            setCompanyList(deleteCompanyIsHere(getCompanyForDropdown(res), parasutCompanies));
            setIsLoginParasut(true);
        }
        setLoading(false);
    };

    const save = async () => {
        const data = {
            moduleId: updatedModule.id,
            companyId: updatedModule.pivot.company_id,
            settings: {
                moduleId: updatedModule.id,
                title: updatedModule.title,
                program: {
                    _id: updatedModule.setting.settings.program._id,
                    title: updatedModule.setting.settings.program.title,
                    requirements: [
                        {
                            username: programReq.username,
                            password: programReq.password,
                            clientId: programReq.clientId,
                            clientSecret: programReq.clientSecret
                        }
                    ],
                    parasutCompanies: parasutCompanies
                }
            }
        };

        setPending(true);
        const res = await dispatch(updateCompanySettingsAsyncThunk(data));
        await dispatch(getCompaniesByUserAsyncThunk());

        setTimeout(() => {
            window.location.reload();
        }, 1500);

        if (res.meta.requestStatus === 'fulfilled') {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül Güncellendi.', life: 1500 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Modül güncellenirken hata oluştu.', life: 1500 });
        }

        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    const handleCreateCompany = (selectedCompany, selectedWebService, username, password) => {
        const data = {
            name: selectedCompany.title,
            id: selectedCompany.value,
            webService: {
                _id: selectedWebService.id,
                title: selectedWebService.title,
                url: selectedWebService.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };

        setParasutCompanies([...parasutCompanies, data]);

        setCompanyList(companyList.filter((item) => item.value !== selectedCompany));

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Eklendi', life: 1500 });
        }, 100);
    };

    const handleUpdateCompany = (selectedCompany, selectedWebService, username, password) => {
        const data = {
            name: selectedCompany.name,
            id: selectedCompany.id,
            webService: {
                _id: selectedWebService.id,
                title: selectedWebService.title,
                url: selectedWebService.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };
        let copy = [...parasutCompanies];
        const index = copy.findIndex((item) => item.id === selectedCompany.id);
        copy[index] = data;

        setParasutCompanies([...copy]);

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Güncellendi', life: 1500 });
        }, 100);
    };

    const handleDeleteCompany = (rowData) => {
        setParasutCompanies(parasutCompanies.filter((item) => item.id !== rowData.id));

        setCompanyList([...companyList, { title: rowData.name, value: rowData.id }]);

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Silindi', life: 1500 });
        }, 100);
    };

    const handleAddXlts = (rowData) => {
        setAddXltsDialog(true);
        setCompanyId(rowData.id);
    };

    const handleXsltDialog = (rowData) => {
        setXsltListDialog(true);
        getXsltByCompanyId(rowData.id).then((res) => {
            setXsltList(res.data.data);
        });
    };

    const handleXsltView = (rowData) => {
        setViewXsltDialog(true);
        xsltView(rowData.id, rowData.company_id).then((res) => {
            setXsltHtml(res.data.data);
        });
    };

    const handleDeleteXslt = (rowData) => {
        deleteXslt(rowData.id, rowData.company_id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şablon Silindi', life: 1500 });
        });

        setXsltList(xsltList.filter((item) => item.id !== rowData.id));
    };

    const handleUpdateXsltDialog = (rowData) => {
        setUpdateXsltDialog(true);
        setSelectedXslt(rowData);
    };

    const handleUploadXslt = async (formData) => {
        try {
            const res = await uploadXslt(formData);

            setAddXltsDialog(false);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şablon Eklendi', life: 1500 });
            setCompanyId(null);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şablon Eklenemedi', life: 1500 });
        }
    };

    const handleUpdateXslt = async (formData) => {
        try {
            const res = await updateXslt(formData);
            getXsltByCompanyId(selectedXslt.company_id).then((res) => {
                setXsltList(res.data.data);
            });
            setUpdateXsltDialog(false);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şablon Güncellendi', life: 1500 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şablon Güncellenemedi', life: 1500 });
        }
    };

    const hideXsltDialog = () => {
        setAddXltsDialog(false);
        setUpdateXsltDialog(false);
    };

    const handleCounterDialog = (rowData) => {
        setCounterDialog(true);
        setCompanyId(rowData.id);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const isDisabled = () => {
        return programReq.username === '' || programReq.password === '' || programReq.clientId === '' || programReq.clientSecret === '' || parasutCompanies.length === 0;
    };

    return (
        <div className="card">
            <Toast ref={toast} />
            <div>
                {/* <Button icon="pi pi-cog" label="Modül Değiştir" onClick={() => setUpdateDialog(true)}></Button> */}
                <div className="flex flex-column md:flex-row">
                    <div className="w-full">
                        <ProgramReq title={updatedModule?.setting.settings.program.title} onLogin={login} isLogin={isLoginParasut} req={programReq ? programReq : updatedModule?.setting.settings.program.requirements[0]} />
                    </div>
                </div>
            </div>

            <CompanyTable
                parasutCompanies={parasutCompanies}
                companyList={companyList}
                isLoginParasut={isLoginParasut}
                onAddXltsDialog={handleAddXlts}
                onXsltListDialog={handleXsltDialog}
                onDeleteCompany={handleDeleteCompany}
                onCreateCompany={handleCreateCompany}
                onUpdateCompany={handleUpdateCompany}
                onCounterDialog={handleCounterDialog}
                companyId={companyId}
            />

            <XsltAddDialog addXltsDialog={addXltsDialog} companyId={companyId} invoiceType={invoiceType} onHideXsltDialog={() => hideXsltDialog()} onUploadXslt={handleUploadXslt} />

            <XsltUpdateDialog updateXsltDialog={updateXsltDialog} invoiceType={invoiceType} onHideXsltDialog={() => hideXsltDialog()} onUpdateXslt={handleUpdateXslt} selectedXslt={selectedXslt} />

            <XsltTableDialog xsltListDialog={xsltListDialog} onCloseDialog={() => setXsltListDialog(false)} xsltList={xsltList} onUpdateXsltDialog={handleUpdateXsltDialog} onXsltView={handleXsltView} onDeleteXslt={handleDeleteXslt} />

            {isLoginParasut && (
                <div className="text-center mt-5">
                    <Button label="Kaydet" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} disabled={isDisabled()} loading={pending} />
                </div>
            )}

            <XsltView viewXsltDialog={viewXsltDialog} onCloseDialog={() => setViewXsltDialog(false)} xsltHtml={xsltHtml} />

            {/* <ModuleChangeDialog visible={updateDialog} onCloseDialog={() => setUpdateDialog(false)} /> */}
        </div>
    );
}
