import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';

import { isOther } from '../utils/utils';

//Ortak e defter
export default function AllReceiptUpdateDialog({ allEditDialog, onCloseDialog, onUpdateAllReceipt }) {
    const [selectedCode, setSelectedCode] = useState(null);
    const [selectedOtherCode, setSelectedOtherCode] = useState(null);
    const [paymentType, setPaymentType] = useState('');
    const [docIsUpdate, setDocIsUpdate] = useState(false);
    const [noDocReceipt, setNoDocReceipt] = useState(false);

    useEffect(() => {
        if (!allEditDialog) {
            setSelectedCode(null);
            setSelectedOtherCode(null);
            setPaymentType('');
            setDocIsUpdate(false);
            setNoDocReceipt(false);
        }
    }, [allEditDialog]);

    const handleUpdate = () => {
        onUpdateAllReceipt({ paymentType, docIsUpdate, noDocReceipt });
    };

    return (
        <Dialog
            header="Toplu Düzenle"
            visible={allEditDialog}
            style={{ width: '40vw' }}
            modal
            onHide={() => onCloseDialog()}
            footer={<Button icon="pi pi-check" label="Güncelle" onClick={() => handleUpdate()} disabled={selectedCode && isOther(selectedCode) && !selectedOtherCode} />}
        >
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Ödeme Yöntemi</label>
                </div>
                <div className="w-full">
                    <InputText type="text" className="w-full" value={paymentType} onChange={(e) => setPaymentType(e.target.value)} placeholder="Ödeme Yöntemi" required />
                </div>

                <div className="flex align-items-center my-4">
                    <Checkbox inputId="noDocReceipt" onChange={() => setNoDocReceipt(!noDocReceipt)} checked={noDocReceipt} />
                    <label htmlFor="noDocReceipt" className="ml-2">
                        Fişlere Ait Belge Detayı Yoktur
                    </label>
                </div>

                <div className="flex align-items-center my-4">
                    <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={() => setDocIsUpdate(!docIsUpdate)} checked={docIsUpdate} />
                    <label htmlFor="ingredient1" className="ml-2">
                        Belge No ve Belge Tarihi Güncelle
                    </label>
                </div>
                <Message severity="info" text="Belge No ve Belge Tarihini, Fiş No ve Fiş Tarihi ile güncellemek için üstteki kutucuğu işaretleyiniz." />
            </div>
        </Dialog>
    );
}
