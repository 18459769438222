import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { getXsltByCompanyId } from '../../parasut/utils/parasutRequest';

export default function XsltTable({ companyId, checkAgain, onCheckAgain, onUpdateXsltDialog, onXsltView, onDeleteXslt, onAddXsltDialog }) {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [xsltList, setXsltList] = useState([]);

    useEffect(() => {
        if (companyId || checkAgain) {
            getXsltByCompanyId(companyId).then((res) => {
                setXsltList(res.data.data);
            });
            onCheckAgain();
        }
    }, [companyId, checkAgain]);

    const actionXsltTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-pencil" tooltip="Düzenle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined severity="primary" className="mr-2" onClick={() => onUpdateXsltDialog(rowData)} />
                <Button icon="pi pi-eye" tooltip="Görüntüle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined severity="info" className="mr-2" onClick={() => onXsltView(rowData)} />
                <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined severity="danger" onClick={() => onDeleteXslt(rowData)} />
            </>
        );
    };

    const getBadge = (status, textSucess, textDanger) => {
        return status ? <span className="p-badge p-badge-success">{textSucess}</span> : <span className="p-badge p-badge-danger">{textDanger}</span>;
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şablonlar</h4>
            <div>
                <Button label="Yeni Şablon" icon="pi pi-plus" className="p-button-primary text-white mr-4 px-6" onClick={() => onAddXsltDialog()} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    return (
        <DataTable value={xsltList} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} emptyMessage="Şablon Bulunamadı" header={header} globalFilter={globalFilter}>
            <Column field="name" header="Şablon Adı"></Column>
            <Column field="invoice_type.name" header="Şablon Tipi"></Column>
            <Column field="is_active" header="Aktif" body={(rowData) => getBadge(rowData.is_active === 1, 'Aktif', 'Pasif')}></Column>
            <Column field="is_default" header="Varsayılan" body={(rowData) => getBadge(rowData.is_default === 1, 'Varsayılan', 'Varsayılan Değil')}></Column>
            <Column header="İşlemler" body={actionXsltTemplate} exportable={false}></Column>
        </DataTable>
    );
}
