export const convertReq = (req) => {
    let copy = {};
    req.forEach((req) => {
        copy[req] = '';
    });

    return copy
}

export const convertName = (req) => {
    switch (req) {
        case 'username':
            return 'Kullanıcı Adı';
        case 'password':
            return 'Şifre';
        case 'taxNumber':
            return 'Vergi Numarası';
        case 'eDefterSubeKodu':
            return 'E-Defter Şube Kodu';
        case 'eDefterSubeAdi':
            return 'E-Defter Şube Adı';
        case 'customerNumber':
            return 'Müşteri Numarası';
        case 'DealerID':
            return 'Bayi Kodu (DealerID)';
        case 'EInvoiceCode':
            return 'Güvenlik Anahtarı (EInvoiceCode)';
        case 'client_id':
            return 'Client ID';
        case 'client_secret':
            return 'Client Secret';
        case 'clientId':
            return 'Client ID';
        case 'clientSecret':
            return 'Client Secret';
        case 'SystemId':
            return 'Sistem Kodu (SystemId)';
        default:
            return req;
    }
};

export const convertToEnglishChars = (text) => {
    const charMap = {
        ş: 's',
        Ş: 'S',
        ı: 'i',
        İ: 'I',
        ğ: 'g',
        Ğ: 'G',
        ü: 'u',
        Ü: 'U',
        ö: 'o',
        Ö: 'O',
        ç: 'c',
        Ç: 'C'
    };

    return text.replace(/[şŞıİğĞüÜöÖçÇ]/g, (match) => charMap[match]);
}