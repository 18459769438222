/* import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { convertReq, convertName } from "../../utils/utils";
import { updateWebServiceReq, setWebservicesReq } from "../../../../store/modules/rapidSlice";

import { InputText } from 'primereact/inputtext';


export default function WebServicesReq({ req = null, title = null }) {
    const { selectedModule } = useSelector((state) => state.modules);
    const { webServiceReq } = useSelector((state) => state.rapid);

    const dispatch = useDispatch();
    useEffect(() => {
        if (selectedModule && !req) {
            dispatch(setWebservicesReq(convertReq(selectedModule?.requirements)));
        }

        if (req) {
            dispatch(setWebservicesReq(req));
        }
    }, [ selectedModule, req, dispatch]);

    const onServiceInputChange = (e, title) => {
        dispatch(updateWebServiceReq({ title, value: e.target.value }));
    };

    return (
        <div className="w-full">
            <div className="mt-5">
                <h3>{title || selectedModule?.title}</h3>
                {webServiceReq &&
                    Object.keys(webServiceReq)?.map((req, i) => {
                        return (
                            <div key={i} className="py-3">
                                <div className="w-full mb-2">
                                    <label htmlFor={req}>{convertName(req)}</label>
                                </div>
                                <div className="w-full">
                                    <InputText onChange={(e) => onServiceInputChange(e, req)}
                                        placeholder={convertName(req)} value={webServiceReq[req]}
                                        type="text" className="w-full"
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
} */

import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { useSelector, useDispatch } from 'react-redux';
import { convertReq, convertName } from '../../utils/utils';
import { updateWebServiceReq, setWebservicesReq } from '../../../../store/modules/rapidSlice';

import { InputText } from 'primereact/inputtext';
import { getAllWebServicesByModuleId } from '../../../../service/request/moduleRequest';
import { setSelectedModule } from '../../../../store/modules/moduleSlice';

export default function WebServicesReq({ req = null, title = null }) {
    const { selectedModule, updatedModule } = useSelector((state) => state.modules);
    const { webServiceReq } = useSelector((state) => state.rapid);

    const [webServicesList, setWebServicesList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedWebService, setSelectedWebService] = useState(null);

    const dispatch = useDispatch();

    const getWebServices = async () => {
        const res = await getAllWebServicesByModuleId(updatedModule?.id);
        setWebServicesList(res.data.data);
        setLoading(false);
    };

    useEffect(() => {
        if (updatedModule && webServicesList.length === 0) {
            getWebServices();
        }
    }, [dispatch, updatedModule]);

    useEffect(() => {
        if (req && !selectedWebService) {
            dispatch(setWebservicesReq(req));
            dispatch(setSelectedModule(updatedModule.setting.settings.webService));
        }
    }, [dispatch, req, selectedWebService]);

    const onServiceInputChange = (e, title) => {
        dispatch(updateWebServiceReq({ title, value: e.target.value }));
    };

    const onInputChange = (value) => {
        setSelectedWebService(value);
        let copy = webServicesList.find((ws) => ws.id === value);
        dispatch(setSelectedModule(copy));
        dispatch(setWebservicesReq(convertReq(copy.requirements.split(','))));
    };

    return (
        <div className="w-full">
            <div>
                <Message severity="warn" className="block mb-3 " text="Web Servis Programını değiştirmek için buradan farklı bir seçim yapınız." />
                <Dropdown
                    emptyFilterMessage="Program bulunamadı"
                    emptyMessage="Program bulunamadı"
                    id="Program Listesi"
                    filter
                    value={selectedWebService}
                    options={webServicesList.map((program) => ({ label: program.title, value: program.id })) || []}
                    onChange={(e) => onInputChange(e.value)}
                    placeholder="Program Listesi"
                    className="w-full"
                />
            </div>
            <div className="mt-5">
                <h3>{selectedWebService ? selectedModule.title : title}</h3>
                {webServiceReq &&
                    Object.keys(webServiceReq)?.map((req, i) => {
                        return (
                            <div key={i} className="py-3">
                                <div className="w-full mb-2">
                                    <label htmlFor={req}>{convertName(req)}</label>
                                </div>
                                <div className="w-full">
                                    <InputText onChange={(e) => onServiceInputChange(e, req)} placeholder={convertName(req)} value={webServiceReq[req]} type="text" className="w-full" />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
