import { Dialog } from 'primereact/dialog';

export default function XsltView({ viewXsltDialog, onCloseDialog, xsltHtml }) {
    if (!xsltHtml) return null;

    return (
        <Dialog
            header="Şablonlar"
            visible={viewXsltDialog}
            style={{
                width: '50vw'
            }}
            maximizable
            modal
            contentStyle={{ height: '100vh' }}
            onHide={() => onCloseDialog()}
            className="p-dialog-xslt"
        >
            <iframe className="w-full h-full" srcDoc={xsltHtml} title="Fatura" style={{ border: 'none' }} />
        </Dialog>
    );
}
