import axios from 'axios';

let BASE_URL = process.env.REACT_APP_INT_URL;

export const getInvoices = async (data) => {
    return await axios.post(`${BASE_URL}/rapid/getInvoices`, data);
};

export const sendInvoices = async (data) => {
    return await axios.post(`${BASE_URL}/rapid/sendInvoices`, data);
};

export const viewInvoice = async (data) => {
    return await axios.post(`${BASE_URL}/rapid/viewInvoice`, data);
};

export const getInvoicesStatus = async (data) => {
    return await axios.post(`${BASE_URL}/invoices/getInvoicesStatus`, data);
};

export const sendStatusInvoice = async (data) => {
    return await axios.post(`${BASE_URL}/rapid/sendStatusInvoice`, data);
};

export const getepackingslips = async (data) => {
    return await axios.post(`${BASE_URL}/rapid/getepackingslips`, data);
};
