import { Button } from 'primereact/button';

export const controlAllData = (data, messages = []) => {
    const invoiceDetails = data.sales_invoice_details;
    let hasError = false;

    invoiceDetails.forEach((invoice) => {
        const vatRate = invoice.attributes.vat_rate;
        const vatExemptionReasonText = invoice.attributes.vat_exemption_reason.text;
        const vatExemptionReasonValue = invoice.attributes.vat_exemption_reason.value;
        const exciseDutyRate = invoice.attributes.excise_duty_rate;
        const exciseDutyValue = invoice.attributes.excise_duty_value;
        const exciseDutyReasonCode = invoice.attributes.excise_duty_reason.code;
        const exciseDutyReasonName = invoice.attributes.excise_duty_reason.name;
        const communicationsTaxRate = invoice.attributes.communications_tax_rate;
        const communicationsTaxReasonCode = invoice.attributes.communications_tax_reason.code;
        const communicationsTaxReasonName = invoice.attributes.communications_tax_reason.name;
        const vatWithholdingRate = invoice.attributes.vat_withholding_rate;
        const vatWithholdingReasonCode = invoice.attributes.vat_withholding_reason.code;
        const vatWithholdingReasonName = invoice.attributes.vat_withholding_reason.name;
        const vatWithholdingReasonRate = invoice.attributes.vat_withholding_reason.rate;

        const isVatRateZero = vatRate === '0.0';
        const isVatExemptionReasonNotEmpty = vatExemptionReasonText !== '' && vatExemptionReasonValue !== '';
        const isExciseDutyRateNotZero = exciseDutyRate !== '0.0' && exciseDutyValue !== '0.0' && exciseDutyRate !== null && exciseDutyValue !== null && exciseDutyReasonCode === '' && exciseDutyReasonName === '';
        const isCommunicationsTaxRateNotZero = communicationsTaxRate !== '0.0' && communicationsTaxRate !== null && communicationsTaxReasonCode === '' && communicationsTaxReasonName === '';
        const isVatWithholdingRateNotZero = vatWithholdingRate !== '0.0' && vatRate !== '0.0' && vatRate !== null && vatWithholdingRate !== null && vatWithholdingReasonCode === '' && vatWithholdingReasonName === '' && vatWithholdingReasonRate === '';

        if (isVatRateZero) {
            if (isVatExemptionReasonNotEmpty) {
                if (isExciseDutyRateNotZero || isCommunicationsTaxRateNotZero) {
                    let msg = ' KDV İstisna Kodu Girilmedi';
                    if (messages.includes(msg) === false) {
                        messages.push(msg);
                    }
                    hasError = true;
                }
            } else {
                let msg = ' KDV İstisna Kodu Girilmedi';
                if (messages.includes(msg) === false) {
                    messages.push(msg);
                }
                hasError = true;
            }
        }

        if (isVatWithholdingRateNotZero) {
            let msg = ' Tevkifat Kodu Girilmedi';
            if (messages.includes(msg) === false) {
                messages.push(msg);
            }
            hasError = true;
        }

        if (isExciseDutyRateNotZero) {
            let msg = ' ÖTV Kodu Girilmedi';
            if (messages.includes(msg) === false) {
                messages.push(msg);
            }
            hasError = true;
        }

        if (isCommunicationsTaxRateNotZero) {
            let msg = ' ÖİV Kodu Girilmedi';
            if (messages.includes(msg) === false) {
                messages.push(msg);
            }
            hasError = true;
        }
    });

    return !hasError;
};


export const rowIsTrue = (rowData) => {
    let messages = [];
    const check = controlAllData(rowData, messages);
    const invoiceUser = rowData.contact.attributes.e_invoice_user;
    const createDate = new Date(rowData.contact.attributes.alias.create_date);
    const issueDate = new Date(rowData.attributes.issue_date);

    if (!check) {
        return <Button severity="danger" icon="pi pi-exclamation-triangle" tooltip={[...messages]} size='large' tooltipOptions={{ position: 'bottom', mouseTrack: false, mouseTrackTop: 15 }} />;
    }

    if (invoiceUser && (createDate > issueDate)) {
        return <Button severity="warning" icon="pi pi-exclamation-circle" size='large'
        tooltip="Fatura tarihi, alıcı müşterinin E-Fatura mükellefi olduğu tarihten önce olduğundan fatura E-Arşiv faturası olarak gönderilecektir!" 
        tooltipOptions={{ position: 'bottom', mouseTrack: false, mouseTrackTop: 15 }} />;
    }

    return <Button severity="success" className='text-white' icon="pi pi-check" tooltip="Doğru satır!" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
};

export const wrongRow = (rowData) => {
    if (!controlAllData(rowData)) {
        return rowData;
    }
};
