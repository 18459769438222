import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { convertReq, convertName } from "../../utils/utils";
import { updateWebServiceReq, setWebservicesReq } from "../../../../store/modules/parasutSlice";

import { InputText } from 'primereact/inputtext';


export default function WebServicesReq({ req = null, title = null }) {
    const { selectedModule } = useSelector((state) => state.modules);
    const { webServiceReq } = useSelector((state) => state.parasut);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedModule && !req) {
            dispatch(setWebservicesReq(convertReq(selectedModule?.requirements)));
        }

        if (req) {
            dispatch(setWebservicesReq(req));
        }
    }, [ selectedModule, req, dispatch ]);

    const onServiceInputChange = (e, title) => {
        dispatch(updateWebServiceReq({ title, value: e.target.value }));
    };

    return (
        <div className="w-full">
            <div className="mt-5">
                <h3>{title || selectedModule?.title}</h3>
                {webServiceReq &&
                    Object.keys(webServiceReq)?.map((req, i) => {
                        return (
                            <div key={i} className="py-3">
                                <div className="w-full mb-2">
                                    <label htmlFor={req}>{convertName(req)}</label>
                                </div>
                                <div className="w-full">
                                    <InputText onChange={(e) => onServiceInputChange(e, req)}
                                        placeholder={convertName(req)} value={webServiceReq[req]}
                                        type="text" className="w-full"
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}