import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useSelector } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { Message } from 'primereact/message';
import { formatAmount } from '../../utils/utils';

export default function SendReceiptDialog({ sendReceiptsDialog, onCloseDialog, sendReceipts, pending, receipts, allDebits, allCredits }) {
    const { selectedCompany } = useSelector((state) => state.userCompany);

    const [enteredBy, setEnteredBy] = useState('');
    const [vknTckn, setVknTckn] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState();

    useEffect(() => {
        if (!sendReceiptsDialog) {
            setEnteredBy('');
            setVknTckn('');
            setStartDate(null);
            setEndDate(null);
            setSelectedMonth(null);
        }
    }, [sendReceiptsDialog]);

    const sendDisabled = () => {
        const module = selectedCompany?.modules.filter((module) => module.title === 'E-Defter')[0];
        if (vknTckn && enteredBy && startDate !== null && endDate !== null) {
            if (vknTckn === module?.setting.settings.program.requirements[0].taxNumber && enteredBy.length >= 2) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };
    const checkVknInput = () => {
        const module = selectedCompany?.modules.filter((module) => module.title === 'E-Defter')[0];
        if (vknTckn !== module?.setting.settings.program.requirements[0].taxNumber) {
            return 'p-invalid';
        }
        return null;
    };

    const checkEnteredByInput = () => {
        if (enteredBy.length < 2) {
            return 'p-invalid';
        }
        return null;
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.value);
        setStartDate(new Date(e.value.getFullYear(), e.value.getMonth(), 1));
        setEndDate(new Date(e.value.getFullYear(), e.value.getMonth() + 1, 0));
    };

    return (
        <Dialog
            header="E-Defter Oluştur"
            visible={sendReceiptsDialog}
            style={{ width: '40vw' }}
            modal
            onHide={!pending ? () => onCloseDialog() : () => {}}
            footer={
                <>
                    <Button icon="pi pi-check" label={pending ? 'E-Defter Oluşturuluyor...' : 'E-Defteri Taslaklara Kaydet'} onClick={() => sendReceipts(enteredBy, vknTckn, startDate, endDate, 0)} disabled={sendDisabled()} loading={pending} />
                    <Button
                        icon="pi pi-check"
                        label={pending ? 'E-Defter Oluşturuluyor...' : 'E-Defteri Oluştur ve Portale Gönder'}
                        onClick={() => sendReceipts(enteredBy, vknTckn, startDate, endDate, 1)}
                        disabled={sendDisabled()}
                        loading={pending}
                    />
                </>
            }
        >
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Gönderen Kişi</label>
                </div>
                <div className="w-full mb-4">
                    <InputText type="text" className={classNames({ 'p-invalid': checkEnteredByInput(), 'w-full': true })} value={enteredBy} onChange={(e) => setEnteredBy(e.target.value)} placeholder="Gönderen Kişi" required />
                    {checkEnteredByInput() && enteredBy.length > 0 && <small className="p-error">E-defteri gönderecek kişi en az iki haneli olmalıdır.</small>}
                    {checkEnteredByInput() && !enteredBy && <small className="p-error">E-defteri gönderecek kişiye ait ad soyad giriniz.</small>}
                </div>
                <div className="w-full mb-2">
                    <label>Vergi No</label>
                </div>
                <div className="w-full">
                    <InputText type="text" className={classNames({ 'p-invalid': checkVknInput(), 'w-full': true })} value={vknTckn} onChange={(e) => setVknTckn(e.target.value)} placeholder="Vergi No" required />
                    {checkVknInput() && vknTckn && <small className="p-error">Girilen vergi numarası şirketin vergi numarası ile eşleşmemektedir.</small>}
                    {!vknTckn && <small className="p-error">Şirketin vergi numarası girilmesi zorunludur.</small>}
                </div>
                <div className="w-full gap-8 mt-4 flex">
                    <div>
                        <label className="mb-2 block">Göndereceğiniz defter dönemini seçiniz.</label>
                        <Calendar className="w-full" value={selectedMonth} view="month" dateFormat="mm/yy" locale="tr" onChange={handleMonthChange} showIcon placeholder="Lütfen bir ay seçiniz" />
                    </div>
                </div>
                <div className="mt-4">
                    <Message
                        severity="warn"
                        icon="pi pi-exclamation-triangle"
                        className="w-full flex justify-content-start align-items-center py-3"
                        text={
                            <>
                                <div className="flex flex-wrap gap-5">
                                    <div className="flex gap-2">
                                        <b>Toplam Fiş Sayısı: </b>
                                        {receipts.length}
                                    </div>
                                    <div className="flex gap-2">
                                        <b>Toplam Borç: </b>
                                        {allDebits}
                                    </div>
                                    <div className="flex gap-2">
                                        <b>Toplam Alacak: </b>
                                        {allCredits}
                                    </div>
                                </div>
                            </>
                        }
                    />
                </div>
                <div className="mt-4">
                    <Message icon="pi pi-info-circle" severity="info" className="w-full py-3" text="Yevmiye ve müteselsil(satır) numaraları servis tarafından otomatik olarak bir önceki dönemi takip edecek şekilde oluşturulmaktadır." />
                </div>
                {pending && (
                    <div className="mt-4">
                        <Message icon="pi pi-info-circle" severity="error" className="w-full py-3 flex justify-content-start" text="İşleminiz biraz uzun sürebilir. Lütfen bekleyiniz." />
                    </div>
                )}
            </div>
        </Dialog>
    );
}
