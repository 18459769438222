import React, { useEffect } from 'react';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

export default function ExemptionDialog({ reasonCode, dialogVisible, onCloseDialog, onSubmitDialog }) {
    const [selectedVatExemption, setSelectedVatExemption] = useState(null);

    useEffect(() => {
        setSelectedVatExemption(null);
    }, [dialogVisible]);

    return (
        <Dialog header="Muafiyet Ekleme" visible={dialogVisible} style={{ width: '30vw' }} modal onHide={() => onCloseDialog()}>
            <div className="py-3">
                <div className="mb-2">
                    <label>Muafiyet Sebebi</label>
                </div>

                <div>
                    <Dropdown
                        dataKey="value"
                        value={selectedVatExemption}
                        onChange={(e) => setSelectedVatExemption(e.value)}
                        options={reasonCode?.map((option) => ({ value: option.value, text: option.text.substring(0, 75) }))}
                        optionLabel="text"
                        placeholder="İstisna Muafiyet Kodu Seçiniz"
                        filter
                        className="w-full"
                        emptyMessage="İstisna Muafiyet Kodu Bulunamadı"
                        emptyFilterMessage="İstisna Muafiyet Kodu Bulunamadı"
                    />
                </div>
                <div className="justify-content-end flex mt-5">
                    <Button icon="pi pi-check" label="Muafiyet Ekle" onClick={() => onSubmitDialog(selectedVatExemption)} disabled={!selectedVatExemption} />
                </div>
            </div>
        </Dialog>
    );
}
