import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';


export default function XsltUpdateDialog({ updateXsltDialog, invoiceType, onHideXsltDialog, onUpdateXslt, selectedXslt }) {
    const [selectedInvoiceType, setSelectedInvoiceType] = useState(null);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [isDefault, setIsDefault] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (!updateXsltDialog) {
            setSelectedInvoiceType(null);
            setFileName('');
            setFile(null);
            setIsDefault(false);
            setIsActive(false);
        } else {
            setSelectedInvoiceType(selectedXslt.invoice_type.id);
            setFileName(selectedXslt.name);
            setIsDefault(selectedXslt.is_default);
            setIsActive(selectedXslt.is_active);
        }
    }, [updateXsltDialog, selectedXslt]);

    const sendButtonIsDisabled = () => {
        return !selectedInvoiceType || !fileName;
    };

    const handleUpdateXslt = () => {
        const formData = new FormData();
        formData.append("id", selectedXslt.id);
        formData.append("invoiceTypeId", selectedInvoiceType);
        formData.append("companyId", String(selectedXslt.company_id));
        formData.append("name", fileName);
        formData.append("xslt", file || "");
        formData.append("isDefault", isDefault ? 1 : 0);
        formData.append("isActive", isActive ? 1 : 0);
        
        onUpdateXslt(formData);
    };

    if (!updateXsltDialog) return null;

    return (
        <Dialog header="Görünümü Güncelle" visible={updateXsltDialog} style={{ width: '50vw' }} modal onHide={() => onHideXsltDialog()}>
            <div className="mt-4">
                <label className="mb-2 block">Fatura Tipleri</label>
                <Dropdown
                    dataKey="id"
                    value={selectedInvoiceType}
                    onChange={(e) => setSelectedInvoiceType(e.value)}
                    options={invoiceType}
                    optionLabel="title"
                    placeholder="Fatura Tipi Seçiniz"
                    filter
                    className="w-full mb-2"
                    emptyMessage="Fatura Tipi Bulunamadı"
                    appendTo="self"
                />
            </div>
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Belge İsmi</label>
                </div>
                <div className="w-full">
                    <InputText onChange={(e) => setFileName(e.target.value)} placeholder="Dosya Adı Giriniz" value={fileName} type="text" className="w-full" />
                </div>
            </div>
            <div className="mb-2">
                <label className="mb-2 block">Dosya Yükle</label>
                <FileUpload
                    name="demo[]"
                    accept=".xslt"
                    maxFileSize={3000000}
                    emptyTemplate={<p className="m-0">Dosyalarınızı buraya sürükleyip bırakabilirsiniz.</p>}
                    chooseOptions={{ label: 'Dosya Seç', icon: 'pi pi-fw pi-file', className: 'p-button-info' }}
                    uploadOptions={{ label: 'Yükle', icon: 'pi pi-fw pi-upload', className: 'p-button-success text-white' }}
                    cancelOptions={{ label: 'İptal', icon: 'pi pi-fw pi-times', className: 'p-button-danger' }}
                    className="w-full"
                    customUpload
                    uploadHandler={(e) => setFile(e.files[0])}
                    invalidFileSizeMessageDetail=""
                    invalidFileSizeMessageSummary=" Dosya boyutu 3MB dan büyük olamaz."
                />
            </div>
            <div className="py-3">
                <Checkbox inputId="isDefault" onChange={(e) => setIsDefault(e.checked)} checked={isDefault} className="mr-2" />
                <label htmlFor="isDefault" className="p-checkbox-label">
                    Varsayılan
                </label>
            </div>
            <div className="py-3">
                <Checkbox inputId="isActive" onChange={(e) => setIsActive(e.checked)} checked={isActive} className="mr-2" />
                <label htmlFor="isActive" className="p-checkbox-label">
                    Aktif
                </label>
            </div>
            <div className="text-center mt-5">
                <Button label="Güncelle" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => handleUpdateXslt()} disabled={sendButtonIsDisabled() && file} />
            </div>
        </Dialog>
    );
}