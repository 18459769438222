import React from 'react';
import { useLocation } from 'react-router-dom';

const AppBreadcrumb = (props) => {
    const location = useLocation();

    const activeRoute = props.routes.filter((route) => {
        return route.path === location.pathname.toLowerCase().replace(/\s/g, '').slice(1);
    });

    let items = [];

    switch (location.pathname) {
        case '/selectmodule':
            items = [{ label: 'Modül' }, { label: 'Bağlı Modüller' }];
            break;
        case location.pathname.includes('/csv-listesi') ? location.pathname : '':
            items = [{ label: 'E-Defter' }, { label: 'E-Defter Taslaklar' }];
            break;
        case location.pathname.includes('/usercompany') ? location.pathname : '':
            items = [{ label: 'Kullanıcılar' }, { label: 'Kullanıcı Şirketleri' }];
            break;
        case location.pathname.includes('/moduleupdate') ? location.pathname : '':
            items = [{ label: 'Modüller' }, { label: 'Modül Güncelleme' }];
            break;
        case location.pathname.includes('/matchmodules') ? location.pathname : '':
            items = [{ label: 'Şirketler' }, { label: 'Şirket Modülü Ekleme' }];
            break;
        case '/modules':
            items = [{ label: 'Şirketler' }, { label: 'Şirket Modülleri Bağlama' }];
            break;
        case '/moduleUpdateSettings':
            items = [{ label: 'Modüller' }, { label: 'Modül Güncelleme' }];
            break;
        case '/modulesettings':
            items = [{ label: 'Modüller' }, { label: 'Modül Ayarları' }];
            break;
        case '/match':
            items = [{ label: 'Kullanıcılar' }, { label: 'Bağlı Şirketler' }];
            break;
        case location.pathname.includes('gonder') ? location.pathname : '':
            if (location.pathname.includes('/E-Defter')) {
                items = [{ label: 'E-Defter' }, { label: 'Fiş Listesi' }];
            } else if (location.pathname.includes('/E-Fatura')) {
                items = [{ label: 'E-Fatura' }, { label: 'Fatura Listesi' }];
            }
            break;
        case location.pathname.includes('gelen') ? location.pathname : '':
            if (location.pathname.includes('/E-Defter')) {
                items = [{ label: 'E-Defter' }, { label: 'Gelen Fiş Listesi' }];
            } else if (location.pathname.includes('/E-Fatura')) {
                items = [{ label: 'E-Fatura' }, { label: 'Gelen Fatura Listesi' }];
            }
            break;
        case location.pathname.includes('giden') ? location.pathname : '':
            if (location.pathname.includes('/E-Defter')) {
                items = [{ label: 'E-Defter' }, { label: 'Giden Fiş Listesi' }];
            } else if (location.pathname.includes('/E-Fatura')) {
                items = [{ label: 'E-Fatura' }, { label: 'Giden Fatura Listesi' }];
            }
            break;
        case location.pathname.includes('/allmodules') ? location.pathname : '':
            items = [{ label: 'Modüller' }, { label: 'Modül Güncelleme' }];
            break;
        default:
            if (!activeRoute.length) {
                items = [{ label: '' }, { label: '' }];
            } else {
                items = [{ label: activeRoute[0].parent }, { label: activeRoute[0].label }];
            }
    }

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    return (
        <div className="layout-breadcrumb-container" style={{ marginBottom: 0 }}>
            <div className="layout-breadcrumb-left-items">
                {isStatic() && (
                    <>
                        <button className="menu-button p-link" onClick={props.onMenuButtonClick}>
                            <i className="pi pi-bars"></i>
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default AppBreadcrumb;
