import { Tag } from 'primereact/tag';
import receiptCode from '../../../../assets/data/receiptCode.json';
import { controlAllData } from './controller';

export const getCompany = (selectedCompany) => {
    let copy = [];
    let selectedModule = selectedCompany?.modules?.find((module) => module.title === 'E-Defter');
    selectedModule?.setting?.settings?.program?.lucaCompanies?.map((company) => {
        copy.push({ label: company.companyName, value: company.companyCode });
    });
    return copy;
};

export const getPeriods = (selectedCompany, companyCode) => {
    let copy = null;
    let selectedModule = selectedCompany?.modules?.find((module) => module.title === 'E-Defter');
    selectedModule?.setting?.settings?.program?.lucaCompanies?.map((company) => {
        if (company.companyCode === companyCode) {
            copy = company.periodTitle;
        }
        //copy.push({ label: company.companyName, value: company.companyCode, periodTitle: company.periodTitle });
    });
    return copy;
};

export const formatDateToCustomFormat = (date) => {
    if (date) {
        const day = String(date.getDate()).padStart(2, '0'); // Günü 2 haneli olarak al
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ayı 2 haneli olarak al
        const year = date.getFullYear(); // Günü 2 haneli olarak al

        return `${day}/${month}/${year}`;
    }
};

export const formatDateToInvoice = (inputDate) => {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const mapTipNoToLabel = (tipNo) => {
    const tipLabels = {
        1: 'Tahsil',
        2: 'Tediye',
        0: 'Açılış',
        4: 'Kapanış',
        3: 'Mahsup'
    };

    return tipLabels[tipNo] || '';
};

export const localeDate = {
    firstDayOfWeek: 1,
    dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
    dayNamesShort: ['Pz', 'Pt', 'Sl', 'Çr', 'Pr', 'Cu', 'Ct'],
    dayNamesMin: ['P', 'P', 'S', 'Ç', 'P', 'C', 'C'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
    monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
    today: 'Bugün',
    clear: 'Temizle'
};

export const getReceiptCode = (receipt) => {
    const copy = [];
    receipt.map((item) => {
        let find = null;

        if (item.EdefterEnum === 'other') {
            find = copy.find((x) => x.title === 'Diğer');
        }

        if (!find) {
            if (item.EdefterEnum !== 'other') {
                copy.push({ title: item.content, value: item.code });
            } else {
                copy.push({ title: 'Diğer', value: item.code });
            }
        }
    });

    return copy;
};

export const isOther = (code) => {
    return receiptCode.find((item) => item.code === code).EdefterEnum === 'other';
};

export const getOtherCode = () => {
    const copy = [];
    receiptCode.map((item) => {
        if (item.EdefterEnum === 'other') {
            copy.push({ title: item.content, value: item.code });
        }
    });

    return copy;
};

export const getErrorReceiptCount = (receipts) => {
    let count = 0;
    receipts.forEach((item) => {
        if (controlAllData(item) === false) {
            count++;
        }
    });

    return count;
};

export const getReceiptDetailsDocType = (docType) => {
    if (docType === null || docType === undefined || docType === '') {
        return <Tag severity="danger" value={'BOŞ'} />;
    }

    return <span>{docType}</span>;
};
