import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

import { isOther, getReceiptCode, getOtherCode } from '../utils/utils';
import receiptCode from '../../../../assets/data/receiptCode.json';

export default function OneReceiptUpdateDialog({ oneEditDialog, onCloseDialog, onUpdateOneReceipt }) {
    const [selectedCode, setSelectedCode] = useState(null);
    const [selectedOtherCode, setSelectedOtherCode] = useState(null);
    const [paymentType, setPaymentType] = useState('');
    const [docNo, setDocNo] = useState('');
    const [docDate, setDocDate] = useState(null);

    useEffect(() => {
        if (!oneEditDialog) {
            setSelectedCode(null);
            setSelectedOtherCode(null);
            setPaymentType('');
            setDocNo('');
            setDocDate(null);
        }
    }, [oneEditDialog]);

    const handleUpdate = () => {
        onUpdateOneReceipt({ selectedCode, selectedOtherCode, paymentType, docNo, docDate });
    };

    return (
        <Dialog
            header="Düzenle"
            visible={oneEditDialog}
            style={{ width: '40vw' }}
            modal
            onHide={() => onCloseDialog()}
            footer={<Button icon="pi pi-check" label="Güncelle" onClick={() => handleUpdate()} disabled={selectedCode && isOther(selectedCode) && !selectedOtherCode} />}
        >
            <div className="py-3">
                <div className="mb-3">
                    <label className="mb-2 block">Kaynak Belge Türü</label>
                    <Dropdown
                        dataKey="id"
                        value={selectedCode}
                        onChange={(e) => setSelectedCode(e.value)}
                        options={getReceiptCode(receiptCode)}
                        optionLabel="title"
                        placeholder="Kaynak Belge Türü Seçiniz"
                        filter
                        className="w-full mb-2"
                        emptyMessage="Kaynak Belge Türü Bulunamadı"
                        appendTo="self"
                    />
                </div>
                {selectedCode && isOther(selectedCode) && (
                    <div className="mb-3">
                        <label className="mb-2 block">Kaynak Belge Türü Açıklaması</label>
                        <Dropdown
                            dataKey="id"
                            value={selectedOtherCode}
                            onChange={(e) => setSelectedOtherCode(e.value)}
                            options={getOtherCode()}
                            optionLabel="title"
                            placeholder="Kaynak Belge Türü Açıklaması Seçiniz"
                            filter
                            className="w-full mb-2"
                            emptyMessage="Kaynak Belge Türü Açıklaması Bulunamadı"
                            appendTo="self"
                            required
                        />
                    </div>
                )}
                <div className="w-full mb-2">
                    <label>Belge No</label>
                </div>
                <div className="w-full mb-4">
                    <InputText type="text" className="w-full" value={docNo} onChange={(e) => setDocNo(e.target.value)} placeholder="Belge No" required />
                </div>
                <div className="w-full mb-2">
                    <label>Belge Tarihi</label>
                </div>
                <div className="w-full mb-4">
                    <Calendar value={docDate} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setDocDate(e.value)} showIcon placeholder="Lütfen Belge Tarihi seçiniz" />
                </div>
                <div className="w-full mb-2">
                    <label>Ödeme Yöntemi</label>
                </div>
                <div className="w-full">
                    <InputText type="text" className="w-full" value={paymentType} onChange={(e) => setPaymentType(e.target.value)} placeholder="Ödeme Yöntemi" required />
                </div>
            </div>
        </Dialog>
    );
}
