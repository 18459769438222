import receiptCode from "../../../../assets/data/receiptCode.json"
import { controlAllData } from "./controller";

export const getCompany = (selectedCompany) => {
    let copy = [];
    let selectedModule = selectedCompany.modules.find((module) => module.title === "E-Defter");
    selectedModule.setting.settings.program.lucaCompanies.map((company) => {
        copy.push({ label: company.companyName, value: company.companyCode });
    });
    return copy;
};


//Faturaya alınacak
export const getReceiptCode = (receipt) => {
    const copy = [];
    receipt.map((item) => {
        let find = null

        if (item.EdefterEnum === "other") {
            find = copy.find((x) => x.title === "Diğer");
        }

        if (!find) {
            if (item.EdefterEnum !== "other") {
                copy.push({ title: item.content, value: item.code });
            }
            else {
                copy.push({ title: "Diğer", value: item.code });
            }
        }
    });

    return copy;
};

export const isOther = (code) => {
    return receiptCode.find((item) => item.code === code).EdefterEnum === "other";
};

export const getOtherCode = () => {
    const copy = [];
    receiptCode.map((item) => {
        if (item.EdefterEnum === "other") {
            copy.push({ title: item.content, value: item.code });
        }
    });

    return copy;
}

export const getErrorReceiptCount = (receipts) => {
    let count = 0;
    receipts.forEach((item) => {
        if (controlAllData(item) === false) {
            count++;
        }
    });

    return count;
}