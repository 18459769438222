import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { getAllWebServicesByModuleId } from '../../../../service/request/moduleRequest';
import { useSelector, useDispatch } from 'react-redux';
import CounterTable from '../../rapid/components/CounterTable';
import { portalFaturaCheck } from '../utils/parasutRequest';
import { Toast } from 'primereact/toast';

export default function CompanyTable({ parasutCompanies, companyList, isLoginParasut, onAddXltsDialog, onXsltListDialog, onDeleteCompany, onCreateCompany, onUpdateCompany, onCounterDialog, companyId }) {
    const { updatedModule } = useSelector((state) => state.modules);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedWebService, setSelectedWebService] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [webServicesList, setWebServicesList] = useState(null);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [counterDialog, setCounterDialog] = useState(false);

    const toast = useRef(null);

    const dispatch = useDispatch();

    const getWebServices = async () => {
        const res = await getAllWebServicesByModuleId(updatedModule?.id);
        setWebServicesList(res.data.data);
    };

    useEffect(() => {
        if (updatedModule && webServicesList === null) {
            getWebServices();
        }
    }, [dispatch]);

    if (!parasutCompanies || !isLoginParasut) return null;

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <div>
                <Button label="Şirket Ekle" icon="pi pi-plus-circle" className="p-button-primary text-white mr-4 mb-0" onClick={() => setDialogVisible(true)} disabled={!isLoginParasut} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const checkCreate = () => {
        return selectedCompany && username && password && selectedWebService;
    };

    const actionCodeTemplate = (rowData) => {
        return (
            <>
                <Button
                    icon="pi pi-pencil"
                    tooltip="Güncelle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    severity="success"
                    className="mr-2 text-white"
                    onClick={() => {
                        setSelectedCompany(rowData);
                        setUpdateVisible(true);
                        setSelectedWebService(rowData.webService._id);
                        setUsername(rowData.webService.requirements.username);
                        setPassword(rowData.webService.requirements.password);
                    }}
                />
                <Button icon="pi pi-plus" tooltip="Şablon Ekle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded severity="primary" className="mr-2 text-white" onClick={() => onAddXltsDialog(rowData)} />
                <Button
                    icon="pi pi-key"
                    tooltip="Sayaç Tablosunu Görüntüle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    severity="warning"
                    className="mr-2 text-white"
                    onClick={() => {
                        onCounterDialog(rowData);
                        setCounterDialog(true);
                    }}
                />
                <Button
                    icon="pi pi-eye"
                    tooltip="Xslt Tablosunu Görüntüle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    severity="info"
                    className="mr-2 text-white"
                    size="large"
                    onClick={() => onXsltListDialog(rowData)}
                />
                <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded severity="danger" onClick={() => onDeleteCompany(rowData)} />
            </>
        );
    };

    const dialogFooterTemplate = () => {
        return <Button label="Oluştur" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleCreateCompany()} disabled={!checkCreate()} />;
    };

    const hideDialog = () => {
        setCounterDialog(false);
        setDialogVisible(false);
        setSelectedCompany(null);
        setSelectedWebService(null);
        setUsername(null);
        setPassword(null);
        setUpdateVisible(false);
    };

    const changeWebServices = (e) => {
        setUsername('');
        setPassword('');
        setSelectedWebService(e.value);
    };

    const handleCreateCompany = () => {
        const company = companyList.find((item) => item.value === selectedCompany);
        const webService = webServicesList.find((item) => item.id === selectedWebService);

        onCreateCompany(company, webService, username, password);
        hideDialog();
    };

    const handleUpdateCompany = () => {
        const webService = webServicesList.find((item) => item.id === selectedWebService);

        onUpdateCompany(selectedCompany, webService, username, password);
        hideDialog();
    };

    const handleCheckPortal = async () => {
        const webService = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            url: webService.url,
            username: username,
            password: password
        };

        const res = await portalFaturaCheck(data);

        if (res.data.success) {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Web servis kullanıcı bilgileriniz doğrudur', life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Web servis kullanıcı bilgileriniz hatalıdır', life: 3000 });
        }
    };
    return (
        <div>
            <Toast ref={toast} />
            <Dialog header="Sayaç Tablosu" visible={counterDialog} style={{ width: '50vw' }} maximizable modal onHide={() => hideDialog()}>
                <CounterTable companyId={companyId} />
            </Dialog>
            <DataTable
                value={parasutCompanies}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                emptyMessage="Şirket Bulunamadı"
                className="my-6"
                header={header}
                globalFilter={globalFilter}
            >
                <Column field="id" header="ID" sortable style={{ minWidth: '10rem' }}></Column>
                <Column field="name" header="Şirket İsmi" sortable style={{ minWidth: '16rem' }}></Column>
                <Column field="webService.title" header="Web Servis Adı" sortable style={{ minWidth: '16rem' }}></Column>
                <Column header="İşlemler" body={actionCodeTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>

            <Dialog header="Şirket Ekle" visible={dialogVisible} style={{ width: '50vw' }} maximizable modal onHide={() => hideDialog()} footer={dialogFooterTemplate}>
                <div className="mt-4">
                    {isLoginParasut && (
                        <>
                            <label className="mb-2 block">Şirketler</label>
                            <Dropdown
                                dataKey="_id"
                                value={selectedCompany}
                                onChange={(e) => setSelectedCompany(e.value)}
                                options={companyList || []}
                                optionLabel="title"
                                placeholder="Şirket Seçiniz"
                                filter
                                className="w-full mb-4"
                                emptyMessage="Şirket Bulunamadı"
                            />
                        </>
                    )}

                    <div>
                        <div className="w-full mb-2">
                            <label>Web Servis Programları</label>
                        </div>
                        <div>
                            <Dropdown
                                dataKey="id"
                                value={selectedWebService}
                                onChange={(e) => changeWebServices(e)}
                                options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                optionLabel="label"
                                placeholder="Web Servis Seçiniz"
                                filter
                                className="w-full mb-3"
                                emptyMessage="Web Servis Bulunamadı"
                            />
                        </div>
                    </div>
                    {selectedWebService && (
                        <div className="my-2">
                            <div className="w-full mb-2">
                                <label>Web Servis Kullanıcı Adı</label>
                            </div>
                            <div className="w-full">
                                <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                            </div>
                            <div className="w-full mb-2">
                                <label>Web Servis Şifre</label>
                            </div>
                            <div className="flex w-full align-items-center gap-3">
                                <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>

            <Dialog
                header="Şirket Güncelle"
                visible={updateVisible}
                style={{ width: '50vw' }}
                maximizable
                modal
                onHide={() => hideDialog()}
                footer={<Button label="Güncelle" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleUpdateCompany()} disabled={!checkCreate()} />}
            >
                <div className="mt-4">
                    <div>
                        <div className="w-full mb-2">
                            <label>Web Servis Programları</label>
                        </div>
                        <div>
                            <Dropdown
                                dataKey="id"
                                value={selectedWebService}
                                onChange={(e) => changeWebServices(e)}
                                options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                optionLabel="label"
                                placeholder="Web Servis Seçiniz"
                                filter
                                className="w-full mb-3"
                                emptyMessage="Web Servis Bulunamadı"
                            />
                        </div>
                    </div>
                    {selectedWebService && (
                        <div className="my-2">
                            <div className="w-full mb-2">
                                <label>Web Servis Kullanıcı Adı</label>
                            </div>
                            <div className="w-full">
                                <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                            </div>
                            <div className="w-full mb-2">
                                <label>Web Servis Şifre</label>
                            </div>
                            <div className="flex w-full align-items-center gap-3">
                                <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>
        </div>
    );
}
