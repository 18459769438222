import axios from 'axios';

let BASE_URL = process.env.REACT_APP_INT_URL;

const parasutReq = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

async function loginParasut(username, password, clientId, clientSecret) {
    return await parasutReq.post(`${BASE_URL}/parasut/login`, {
        clientId,
        clientSecret,
        username,
        password
    });
}

async function getCompanies(bearerToken) {
    return await axios.post(`${BASE_URL}/parasut/getCompanies`, { bearerToken });
}

async function getSalesInvoices(bearerToken, companyId, issueDate) {
    return await axios.post(`${BASE_URL}/parasut/getSalesInvoices`, { bearerToken, companyId, issueDate });
}

async function getInvoiceTypes() {
    return await axios.get(`${BASE_URL}/invoices/getInvoiceTypes`);
}

async function uploadXslt(data) {
    return await axios.post(`${BASE_URL}/xslt/uploadXslt`, data);
}

async function updateXslt(data) {
    return await axios.post(`${BASE_URL}/xslt/updateXslt`, data);
}

async function deleteXslt(id, companyId) {
    return await axios.post(`${BASE_URL}/xslt/deleteXslt`, { id, companyId });
}

async function getXsltByCompanyId(companyId) {
    return await axios.post(`${BASE_URL}/xslt/getXsltByCompanyId`, { companyId });
}

async function xsltView(id, companyId) {
    return await axios.get(`${BASE_URL}/xslt/xsltView` + '?id=' + id + '&companyId=' + companyId);
}

async function getVatExemptionReasons() {
    return await axios.get(`${BASE_URL}/invoices/getVatExemptionReasons`);
}

async function getVatWithholdingReasons() {
    return await axios.get(`${BASE_URL}/invoices/getVatWithholdingReasons`);
}

async function viewInvoice(data) {
    return await axios.post(`${BASE_URL}/parasut/viewInvoice`, data);
}

async function sendInvoice(data) {
    return await axios.post(`${BASE_URL}/parasut/sendInvoice`, data);
}

async function getInvoicesStatus(data) {
    return await axios.post(`${BASE_URL}/invoices/getInvoicesStatus`, data);
}

async function portalFaturaCheck(data) {
    return await axios.post(`${BASE_URL}/portal/check`, data);
}

class ParasutRequest {
    static async login(username, password, clientId, clientSecret) {
        try {
            const response = await parasutReq.post(`${BASE_URL}/parasut/login`, {
                clientId,
                clientSecret,
                username,
                password
            });

            this.bearerToken = response.data.data.bearerToken;

            const companies = await this.getCompanies(response.data.data.access_token);

            return companies;
        } catch (error) {
            return error;
        }
    }

    static async getCompanies(bearerToken) {
        try {
            const response = await parasutReq.post(`${BASE_URL}/parasut/getCompanies`, { bearerToken });
            return response.data.data;
        } catch (error) {
            return error;
        }
    }
}

export {
    loginParasut,
    getCompanies,
    ParasutRequest,
    getSalesInvoices,
    getInvoiceTypes,
    uploadXslt,
    updateXslt,
    deleteXslt,
    getXsltByCompanyId,
    xsltView,
    getVatExemptionReasons,
    getVatWithholdingReasons,
    viewInvoice,
    sendInvoice,
    getInvoicesStatus,
    portalFaturaCheck
};
