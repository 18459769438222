import React, { useState, useEffect, useRef } from 'react';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSelector } from 'react-redux';

import { Dropdown } from 'primereact/dropdown';
import { addLucaAsyncThunk } from '../../../store/modules/moduleSlice';
import { useDispatch } from 'react-redux';

import LucaDocCode from './components/LucaDocCode';
import LucaCompanyReq from './components/LucaCompanyReq';
import WebServicesReq from './components/WebServicesReq';
import Loading from '../../Loading';
import ProgramReq from './components/ProgramReq';

import { LucaRequest } from './utils/lucaRequest';
import { clearAllLuca } from '../../../store/modules/lucaSlice';

import { getPeriodForDropdown, getCompanyForDropdown } from './utils/util';

export default function FilterDemo() {
    const { selectedModule, selectedProgram, module } = useSelector((state) => state.modules);
    const { codeList, companyReq, webServiceReq, programReq } = useSelector((state) => state.luca);
    const { selectedCompany } = useSelector((state) => state.userCompany);

    const [response, setResponse] = useState(null);
    const [companyList, setCompanyList] = useState(null);
    const [selectedCompanyInner, setSelectedCompanyInner] = useState(null);

    const [isLoginLuca, setIsLoginLuca] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const [loading, setLoading] = useState(false);

    const [periodList, setPeriodList] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [lucaCompanies, setLucaCompanies] = useState([]);

    const dispatch = useDispatch();
    const toast = useRef(null);

    useEffect(() => {
        if (loginError) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: loginError, life: 3000 });

            setTimeout(() => {
                setLoginError(null);
            }, 3000);
        }
    }, [loginError]);

    const login = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await LucaRequest.login(programReq.username, programReq.password, programReq.customerNumber);

        if (res.name === 'AxiosError') {
            setLoginError(res?.response?.data?.message);
        } else {
            setResponse(res);
            setCompanyList(getCompanyForDropdown(res));
            setSelectedCompanyInner(null);
            setIsLoginLuca(true);
        }

        setLoading(false);
    };

    const handleChangeCompany = async (e) => {
        setSelectedCompanyInner(e.value);
        setPeriodList(getPeriodForDropdown(response, e.value));
    };

    const save = async () => {
        const data = {
            companyId: selectedCompany._id,
            module: {
                moduleId: module._id,
                title: module.title,
                program: {
                    _id: selectedProgram._id,
                    title: selectedProgram.title,
                    requirements: [
                        {
                            username: programReq.username,
                            password: programReq.password,
                            customerNumber: programReq.customerNumber
                        }
                    ],
                    lucaCompanies: lucaCompanies
                },
                webService: {
                    requirements: webServiceReq,
                    _id: selectedModule._id,
                    title: selectedModule.title,
                    url: selectedModule.url
                }
            }
        };

        dispatch(addLucaAsyncThunk(data));
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül Eklendi', life: 1500 });

        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    const handleCreateCompany = () => {
        const company = response.find((item) => item.company.value === selectedCompanyInner);
        const period = company.periods.find((item) => item.value === selectedPeriod);

        const data = {
            companyName: company.company.label,
            companyCode: company.company.value,
            periodId: String(period.value),
            periodTitle: String(period.label),
            eDefterSubeAdi: companyReq[0].value,
            eDefterSubeKodu: companyReq[1].value,
            taxId: companyReq[2].value,
            belgeTurleri: codeList
        };

        setLucaCompanies([...lucaCompanies, data]);

        setCompanyList(companyList.filter((item) => item.value !== selectedCompanyInner));
        setDialogVisible(false);
        setSelectedCompanyInner(null);
        setSelectedPeriod(null);
        setPeriodList(null);
        dispatch(clearAllLuca());
    };

    const handleDeleteCompany = (rowData) => {
        setLucaCompanies(lucaCompanies.filter((item) => item.companyCode !== rowData.companyCode));
        setCompanyList([...companyList, { title: rowData.companyName, value: rowData.companyCode }]);
    };

    const checkCreate = () => {
        return selectedCompanyInner && selectedPeriod && companyReq[2].value;
    };

    const dialogFooterTemplate = () => {
        return <Button label="Oluştur" icon="pi pi-check" className="p-button-info" onClick={() => handleCreateCompany()} disabled={!checkCreate()} />;
    };

    const actionCodeTemplate = (rowData) => {
        return <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="mx-2" rounded outlined severity="danger" onClick={() => handleDeleteCompany(rowData)} />;
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <div>
                <Button label="Şirket Ekle" icon="pi pi-plus-circle" className="p-button-primary text-white mr-4 mb-0" onClick={() => setDialogVisible(true)} disabled={!isLoginLuca} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <div className="">
                <div className="flex flex-column md:flex-row">
                    <ProgramReq onLogin={login} isLogin={isLoginLuca} />

                    <div className="w-full md:w-1">
                        <Divider layout="vertical" className="hidden md:flex"></Divider>
                    </div>

                    <WebServicesReq />
                </div>
            </div>

            <Dialog header="Yeni Kodlar" visible={dialogVisible} style={{ width: '50vw' }} maximizable modal onHide={() => setDialogVisible(false)} footer={dialogFooterTemplate}>
                <div className="mt-4">
                    {isLoginLuca && (
                        <>
                            <label className="mb-2 block">Şirketler</label>
                            <Dropdown dataKey="_id" value={selectedCompanyInner} onChange={handleChangeCompany} options={companyList} optionLabel="title" placeholder="Şirket Seçiniz" filter className="w-full mb-4" emptyMessage="Şirket Bulunamadı" />
                            {periodList && (
                                <>
                                    <label className="mb-2 block">Periyotlar</label>
                                    <Dropdown
                                        dataKey="id"
                                        value={selectedPeriod}
                                        onChange={(e) => setSelectedPeriod(e.value)}
                                        options={periodList}
                                        optionLabel="title"
                                        placeholder="Periyot Seçiniz"
                                        filter
                                        className="w-full mb-2"
                                        emptyMessage="Periyot Bulunamadı"
                                    />
                                </>
                            )}
                        </>
                    )}

                    <LucaCompanyReq />
                    <LucaDocCode />
                </div>
            </Dialog>
            {lucaCompanies && isLoginLuca && (
                <DataTable
                    value={lucaCompanies}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                    emptyMessage="Şirket Bulunamadı"
                    className="my-6"
                    header={header}
                    globalFilter={globalFilter}
                >
                    <Column field="companyName" header="Şirket İsmi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="periodTitle" header="Periyot" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column header="İşlemler" body={actionCodeTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            )}

            {lucaCompanies.length > 0 && (
                <div className="text-center mt-5">
                    <Button label="Kaydet" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} />
                </div>
            )}
        </div>
    );
}
