import { modules } from '../url';
import axios from 'axios';

export const getAllModules = async () => {
    return await axios.get(modules.getAllModules);
}

export const addLuca = async (data) => {
    return await axios.post(modules.addLuca, data);
}

export const updateLuca = async (data) => {
    return await axios.put(modules.updateLuca, data);
}

export const addParasut = async (data) => {
    return await axios.post(modules.addParasut, data);
}

export const updateParasut = async (data) => {
    return await axios.put(modules.updateParasut, data);
}

export const addRapid = async (data) => {
    return await axios.post(modules.addRapid, data);
};

export const updateRapid = async (data) => {
    return await axios.put(modules.updateRapid, data);
};

export const deleteModule = async (data) => {
    return await axios.delete(modules.deleteModule, { data: data });
}

export const updateCompanyModule = async (data) => {
    return await axios.post(modules.updateCompanyModule, data);
}

export const addDatasoft = async (data) => {
    return await axios.post(modules.addDatasoft, data);
};

export const updateDatasoft = async (data) => {
    return await axios.put(modules.updateDatasoft, data);
};

export const getAllWebServicesByModuleId = async (id) => {
    return await axios.get(modules.getAllWebServicesByModuleId + `?moduleId=${id}`);
};


export const updateLucaPassword = async (data) => {
    return await axios.post(modules.updateLucaPassword, data);
}


