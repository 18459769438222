import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';

import UpdateLuca from './pages/module/luca/UpdateLuca';
import LucaReceiptPage from './pages/receipt/LucaReceiptPage';
import ParasutInvoicePage from './pages/receipt/ParasutInvoicePage';
import RapidInvoicePage from './pages/receipt/RapidInvoicePage';

import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import SelectModule from './pages/tickets/SelectModule';

import UpdateParasut from './pages/module/parasut/UpdateParasut';
import { Error } from './pages/Error';
import UpdateRapid from './pages/module/rapid/UpdateRapid';
import { useSelector } from 'react-redux';
import { SidebarData } from './assets/sidebarData';
import AllModule from './pages/module/AllModule';
import InboxInvoiceList from './pages/receipt/InboxInvoiceList';
import OutboxInvoiceList from './pages/receipt/OutboxInvoiceList';
import RapidPage from './pages/module/rapid/RapidPage';
import LucaPage from './pages/module/luca/LucaPage';
import ParasutPage from './pages/module/parasut/ParasutPage';

import DatasoftPage from './pages/module/datasoft/DatasoftPage';
import UpdateDatasoft from './pages/module/datasoft/UpdateDatasoft';
import DatasoftReceiptPage from './pages/receipt/DatasoftReceiptPage';
import UpdateRapidWaybill from './pages/module/rapidWaybill/UpdateRapidWaybill';
import RapidWaybillPage from './pages/receipt/RapidWaybillPage';
import ZenomPage from './pages/module/zenom/ZenomPage';
import UpdateZenom from './pages/module/zenom/UpdateZenom';
import ZenomReceiptPage from './pages/receipt/ZenomReceiptPage';

import CsvPageLuca from './pages/receipt/CsvPageLuca';
import CsvPageDatasoft from './pages/receipt/CsvPageDatasoft';
import CsvPageZenom from './pages/receipt/CsvPageZenom';

import { convertToEnglishChars } from './pages/module/utils/utils';
import Loading from './pages/Loading';
import { NotFound } from './pages/NotFound';
import Videos from './pages/videos/Videos';

const App = () => {
    const [menuMode, setMenuMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const [menu, setMenu] = useState([]);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedCompany) {
            if (loading) setLoading(false);

            let copyMenu = [];
            copyMenu.push(SidebarData['Anasayfa']);

            selectedCompany.modules.map((module) => {
                if (!copyMenu?.includes(SidebarData[module.title])) {
                    let dt = SidebarData[module.title];
                    let dtCopy = dt.items[0].items.map((item) => {
                        return { ...item, to: `/${convertToEnglishChars(module.title)}/${convertToEnglishChars(module.setting.settings.program.title.split(' ')[0])}/${item.key}` };
                    });
                    dt.items[0].items = dtCopy;
                    copyMenu.push(dt);
                }
            });
            copyMenu.push(SidebarData['Eğitim Videoları']);
            setMenu(copyMenu);
        } else {
            setLoading(true);
        }
    }, [selectedCompany]);

    const routes = [
        { parent: 'Kullanıcılar', label: 'Kullanıcı Tablosu', path: 'users' },
        { parent: 'Kullanıcılar', label: 'Bağlı Şirketler', path: `/match/:id` },
        { parent: 'Şirketler', label: 'Bağlı Modüller', path: `/matchmodules/:id` },
        { parent: 'Şirketler', label: 'Şirket Tablosu', path: 'companies' },
        { parent: 'Modüller', label: 'Modül Ayarları', path: `/modulesettings/:id` },
        { parent: 'Modüller', label: 'Modül Güncelleme', path: `/moduleUpdateSettings/:id` }
    ];

    let menuClick;
    let topbarItemClick;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        topbarItemClick = false;
        menuClick = false;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static'
    });

    if (loading) {
        return (
            <div className="w-full flex justify-content-center align-items-center h-screen">
                <Loading />
            </div>
        );
    }

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-main">
                <AppTopbar
                    items={menu}
                    menuMode={menuMode}
                    menuActive={menuActive}
                    topbarMenuActive={topbarMenuActive}
                    activeInlineProfile={activeInlineProfile}
                    onTopbarItemClick={onTopbarItemClick}
                    onMenuButtonClick={onMenuButtonClick}
                    onSidebarMouseOver={onSidebarMouseOver}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onToggleMenu={onToggleMenu}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    onMenuClick={onMenuClick}
                    onMenuItemClick={onMenuItemClick}
                    onRootMenuItemClick={onRootMenuItemClick}
                    resetActiveIndex={resetActiveIndex}
                />

                <AppMenu
                    model={menu}
                    onRootMenuItemClick={onRootMenuItemClick}
                    onMenuItemClick={onMenuItemClick}
                    onToggleMenu={onToggleMenu}
                    onMenuClick={onMenuClick}
                    menuMode={menuMode}
                    menuActive={menuActive}
                    sidebarActive={sidebarActive}
                    sidebarStatic={sidebarStatic}
                    pinActive={pinActive}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onSidebarMouseOver={onSidebarMouseOver}
                    activeInlineProfile={activeInlineProfile}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    resetActiveIndex={resetActiveIndex}
                />

                <AppBreadcrumb routes={routes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode} />

                <div className="layout-main-content">
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<SelectModule />} />
                        <Route path="/education-videos" element={<Videos />} />
                        <Route path="/allmodules" element={<AllModule />} />
                        <Route path="/matchmodules/E-Fatura/Rapid360" element={<RapidPage />} />
                        <Route path="/matchmodules/E-Defter/Luca" element={<LucaPage />} />
                        <Route path="/matchmodules/E-Defter/Datasoft" element={<DatasoftPage />} />
                        <Route path="/matchmodules/E-Defter/Zenom" element={<ZenomPage />} />
                        <Route path="/matchmodules/E-Fatura/Parasut" element={<ParasutPage />} />
                        <Route path="/E-Defter/Luca/e-defter-taslaklar" element={<CsvPageLuca />} />
                        <Route path="/E-Defter/Datasoft/e-defter-taslaklar" element={<CsvPageDatasoft />} />
                        <Route path="/E-Defter/Zenom/e-defter-taslaklar" element={<CsvPageZenom />} />
                        <Route path="/E-Defter/Luca/e-defter-gonder" element={<LucaReceiptPage />} />
                        <Route path="/E-Defter/Datasoft/e-defter-gonder" element={<DatasoftReceiptPage />} />
                        <Route path="/E-Defter/Zenom/e-defter-gonder" element={<ZenomReceiptPage />} />
                        <Route path="/E-Fatura/Parasut/e-fatura-gonder" element={<ParasutInvoicePage />} />
                        <Route path="/E-Fatura/Rapid360/e-fatura-gonder" element={<RapidInvoicePage />} />
                        <Route path="/E-Irsaliye/Rapid360/e-irsaliye-gonder" element={<RapidWaybillPage />} />
                        <Route path="/E-Defter/Luca/moduleupdate" element={<UpdateLuca />} />
                        <Route path="/E-Defter/Datasoft/moduleupdate" element={<UpdateDatasoft />} />
                        <Route path="/E-Defter/Zenom/moduleupdate" element={<UpdateZenom />} />
                        <Route path="/E-Fatura/Parasut/moduleupdate" element={<UpdateParasut />} />
                        <Route path="/E-Fatura/Rapid360/moduleupdate" element={<UpdateRapid />} />
                        <Route path="/E-Irsaliye/Rapid360/moduleupdate" element={<UpdateRapidWaybill />} />
                        <Route path="/E-Fatura/Rapid360/gelen-e-fatura" element={<InboxInvoiceList />} />
                        <Route path="/E-Fatura/Rapid360/giden-e-fatura" element={<OutboxInvoiceList />} />
                        <Route path="/E-Irsaliye/Rapid360/gelen-e-irsaliye" />
                        <Route path="/E-Irsaliye/Rapid360/giden-e-irsaliye" />
                        <Route path="/E-Fatura/Parasut/gelen-e-fatura" element={<InboxInvoiceList />} />
                        <Route path="/E-Fatura/Parasut/giden-e-fatura" element={<OutboxInvoiceList />} />
                        <Route path="/error" element={<Error />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>

                <AppFooter />
            </div>
        </div>
    );
};

export default App;
