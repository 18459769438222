import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { convertReq, convertName } from '../../utils/utils';
import { updateProgramReq, setProgramReq } from '../../../../store/modules/rapidSlice';

import { InputText } from 'primereact/inputtext';

export default function ProgramReq({ req = null, title = null }) {
    const { selectedProgram } = useSelector((state) => state.modules);
    const { programReq } = useSelector((state) => state.rapid);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedProgram && !req) {
            dispatch(setProgramReq(convertReq(selectedProgram?.requirements)));
        }
        if (req) {
            dispatch(setProgramReq(req));
        }
    }, [req, selectedProgram, dispatch]);

    const onProgramInputChange = (e, title) => {
        dispatch(updateProgramReq({ title, value: e.target.value }));
    };

    return (
        <div className="w-full">
            <form className="mt-5">
                <h3>{title ||selectedProgram?.title}</h3>
                {programReq &&
                    Object.keys(programReq)?.map((req, i) => {
                        return (
                            <div key={i} className="py-3">
                                <div className="w-full mb-2">
                                    <label htmlFor={req}>{convertName(req)}</label>
                                </div>
                                <div className="w-full">
                                    <InputText onChange={(e) => onProgramInputChange(e, req)} placeholder={convertName(req)} value={programReq[req]} type="text" className="w-full" />
                                </div>
                            </div>
                        );
                    })}
            </form>
        </div>
    );
}
