import React, { useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';

import { getInboxInvoiceList, getInboxInvoicePdf, getInboxInvoiceView, getInboxInvoiceData, sendDocumentResponse } from './PortalRequest.js';
import { formatDate, getSendingDataDetails, localeDate, formatAmount, HeaderProgram } from './utils/utils.js';
import InvoiceViewDialog from './utils/InvoiceViewDialog.js';
import Loading from '../Loading.js';
import { getDateTemplate, getVknNameTemplate, InvoiceStatus, getStatusColor } from './eFatura/utils/utils.js';

import { addWipList, removeWipList } from '../../store/controller/controllerSlice.js';
import { Dropdown } from 'primereact/dropdown';

export default function InboxInvoiceList() {
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const { wipList } = useSelector((state) => state.controller);
    const [invoices, setInvoices] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [invoiceViewDialog, setInvoiceViewDialog] = useState(false);
    const [invoiceView, setInvoiceView] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [pending, setPending] = useState(false);
    const [reason, setReason] = useState(null);
    const [reasonDialog, setReasonDialog] = useState(false);
    const [approveDialog, setApproveDialog] = useState(false);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);

    const dt = useRef(null);
    const toast = useRef(null);
    const dispatch = useDispatch();

    addLocale('tr', localeDate);

    const getReceiptList = async () => {
        setSelectedReceipt(null);
        setLoading(true);
        let req = null;
        let selectedModule = selectedCompany.modules.find((module) => module.title === 'E-Fatura');

        if (selectedModule.setting.settings.program.title === 'Paraşüt E-Fatura') {
            let company = selectedModule.setting.settings.program.parasutCompanies.find((company) => company.id === selectedCompanyCode);
            req = company.webService;
        } else {
            req = selectedModule.setting.settings.webService;
        }

        let invoiceList = null;
        try {
            invoiceList = await getInboxInvoiceList({
                username: req.requirements.username,
                password: req.requirements.password,
                webServiceUrl: req.url,
                startDate: formatDate(startDate, 'dashReverse'),
                endDate: formatDate(endDate, 'dashReverse')
            });

            if (invoiceList.success === false) {
                setLoading(false);
                setTimeout(() => {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: invoiceList.message, life: 3000 });
                }, 100);
                return;
            } else {
                for (let wip of wipList) {
                    if (invoiceList.data.data.find((invoice) => invoice.InvoiceId === wip).StatusCode !== 1100) {
                        dispatch(removeWipList(wip));
                    }
                }

                const copy = [...invoiceList.data.data];
                setInvoices(copy.slice().reverse());
            }
        } catch (error) {
            setLoading(false);
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
            }, 100);
            return;
        }

        setLoading(false);

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fatura başarıyla getirildi.', life: 3000 });
        }, 100);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const sendViewReceipts = async (rowData) => {
        const data = rowData.DocumentId;
        const reqData = getSendingDataDetails(selectedCompany, selectedCompanyCode);
        reqData.documentId = data;

        setPending(true);
        try {
            const res = await getInboxInvoiceView(reqData);

            if (res.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.message, life: 3000 });
            } else {
                setInvoiceView(res.data.data.Html);
                setInvoiceViewDialog(true);
            }

            setPending(false);
        } catch (error) {
            setPending(false);
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h4 className="m-0">Fatura Listesi</h4>
            </div>
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const approveReceipts = async () => {
        const data = selectedInvoice;
        const reqData = getSendingDataDetails(selectedCompany, selectedCompanyCode);
        reqData.documentId = data.DocumentId;
        reqData.responseStatus = 'Approved';

        setApproveDialog(false);

        setPending(true);
        if (wipList.includes(selectedInvoice.InvoiceId)) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Fatura şu an işlemde.', life: 3000 });
            setPending(false);
            setApproveDialog(false);
            setSelectedInvoice(null);
            return;
        }
        try {
            const res = await sendDocumentResponse(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                dispatch(addWipList(selectedInvoice.InvoiceId));
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fatura onay yanıtı gönderildi.', life: 3000 });
                setTimeout(() => {
                    getReceiptList();
                }, 1500);
                setPending(false);
            }
        } catch (error) {
            setPending(false);
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }

        setSelectedInvoice(null);
    };

    const rejectReceipts = async () => {
        const data = selectedInvoice;
        const reqData = getSendingDataDetails(selectedCompany, selectedCompanyCode);
        reqData.documentId = data.DocumentId;
        reqData.responseStatus = 'Declined';
        reqData.reason = reason;

        setReasonDialog(false);

        setPending(true);

        if (wipList.includes(selectedInvoice.InvoiceId)) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Fatura şu an işlemde.', life: 3000 });
            setPending(false);
            setReasonDialog(false);
            setSelectedInvoice(null);
            setReason(null);
            return;
        }

        try {
            const res = await sendDocumentResponse(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                dispatch(addWipList(selectedInvoice.InvoiceId));
                setPending(false);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fatura reddedilme talebi gönderildi.', life: 3000 });
                setTimeout(() => {
                    getReceiptList();
                }, 1500);
            }
        } catch (error) {
            setPending(false);
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }

        setSelectedInvoice(null);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="align-items-center flex justify-content-center">
                <Button
                    icon="pi pi-eye"
                    severity="info"
                    className="mr-2"
                    size="large"
                    tooltip="Görüntüle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    loading={pending}
                    onClick={() => {
                        setSelectedInvoice(rowData);
                        sendViewReceipts(rowData);
                    }}
                />
                {rowData.StatusCode === 1100 && (
                    <>
                        <Button
                            icon="pi pi-check"
                            severity="success"
                            className="mr-2 text-white"
                            size="large"
                            tooltip="Onayla"
                            tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                            rounded
                            loading={pending}
                            onClick={() => {
                                setApproveDialog(true);
                                setSelectedInvoice(rowData);
                            }}
                        />
                        <Button
                            icon="pi pi-times"
                            severity="danger"
                            className="mr-2"
                            size="large"
                            tooltip="Reddet"
                            tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                            rounded
                            loading={pending}
                            onClick={() => {
                                setReasonDialog(true);
                                setSelectedInvoice(rowData);
                            }}
                        />
                    </>
                )}
            </div>
        );
    };

    const onCloseInvoiceViewDialog = () => {
        setInvoiceViewDialog(false);
        setInvoiceView(null);
    };

    const onCloseInvoiceDialog = () => {
        setReasonDialog(false);
        setApproveDialog(false);
        setReason(null);
        setSelectedInvoice(null);
    };

    const getInvoicePdf = async () => {
        const data = selectedInvoice.DocumentId;
        const reqData = getSendingDataDetails(selectedCompany, selectedCompanyCode);
        reqData.documentId = data;

        setPending(true);
        try {
            const res = await getInboxInvoicePdf(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                const linkSource = `data:application/pdf;base64,${res.data.data.Data}`;
                const downloadLink = document.createElement('a');
                const fileName = `${selectedInvoice.InvoiceId}.pdf`;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }

        setPending(false);
    };

    const getInvoiceXML = async () => {
        const data = selectedInvoice.DocumentId;
        const reqData = getSendingDataDetails(selectedCompany, selectedCompanyCode);
        reqData.documentId = data;

        setPending(true);

        try {
            const res = await getInboxInvoiceData(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                const linkSource = `data:application/xml;base64,${res.data.data.Data}`;
                const downloadLink = document.createElement('a');
                const fileName = `${selectedInvoice.InvoiceId}.xml`;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        } catch (error) {
            error.code === 'ERR_NETWORK'
                ? toast.current.show({ severity: 'error', summary: 'Hata', detail: 'İstek zaman aşımına uğradı', life: 3000 })
                : toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }

        setPending(false);
    };

    const filterParasutCompanies = () => {
        const copy = [];
        selectedCompany.modules
            .filter((module) => module.title === 'E-Fatura')[0]
            .setting.settings.program.parasutCompanies.map((company) => {
                copy.push({ label: company.name.substring(0, 28), value: company.id });
            });
        return copy;
    };

    return (
        <div>
            <Toast ref={toast} />
            <HeaderProgram selectedCompany={selectedCompany} moduleName="E-Fatura" />
            <br />
            <h3 className="mb-5">Fatura Tablosu</h3>
            <div className="card flex align-items-end justify-content-between">
                <div className="flex align-items-end gap-5">
                    {selectedCompany.modules[0].setting.settings.program.title === 'Paraşüt E-Fatura' && (
                        <div className="w-17rem">
                            <label className="mb-2 block">Şirketler</label>
                            <Dropdown
                                dataKey="_id"
                                value={selectedCompanyCode}
                                onChange={(e) => setSelectedCompanyCode(e.value)}
                                options={filterParasutCompanies()}
                                optionLabel="label"
                                placeholder="Şirket Seçiniz"
                                filter
                                className="w-full"
                                emptyMessage="Şirket Bulunamadı"
                            />
                        </div>
                    )}
                    <div className="w-17rem">
                        <label className="block mb-2" htmlFor="cal_date">
                            Başlangıç Tarih
                        </label>
                        <Calendar className="w-full" value={startDate} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setStartDate(e.value)} showIcon placeholder="Lütfen başlangıç tarihi seçiniz" />
                    </div>
                    <div className="w-17rem">
                        <label className="block mb-2" htmlFor="cal_date">
                            Bitiş Tarih
                        </label>
                        <Calendar className="w-full" value={endDate} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setEndDate(e.value)} showIcon placeholder="Lütfen bitiş tarihi seçiniz" />
                    </div>
                    <Button icon="pi pi-ticket" onClick={() => getReceiptList()} className="px-4" label="Fatura Getir" disabled={!(startDate && endDate)} />
                </div>
            </div>

            <div className="card">
                <DataTable
                    ref={dt}
                    value={invoices}
                    dataKey="InvoiceId"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} faturadan {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Fatura Bulunamadı"
                    className="p-datatable-gridlines"
                    selectionMode={'checkbox'}
                    selection={selectedReceipt}
                    onSelectionChange={(e) => setSelectedReceipt(e.value)}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="InvoiceId" header="Fatura No" sortable body={(rowData) => <p className="text-pink-500 font-semibold">{rowData.InvoiceId}</p>} style={{ minWidth: '6rem' }}></Column>
                    <Column header="Fatura Tarihi / Oluşturulma Tarihi" body={(rowData) => getDateTemplate(rowData)} style={{ minWidth: '6rem' }}></Column>
                    <Column header="Gönderen VKN-TCKN / Unvan" body={(rowData) => getVknNameTemplate(rowData)} style={{ minWidth: '6rem' }}></Column>
                    <Column field="PayableAmount" header="Ödenecek Tutar" sortable body={(rowData) => formatAmount(rowData.PayableAmount, rowData.DocumentCurrencyCode)} style={{ minWidth: '6rem' }}></Column>
                    <Column field="TaxExclusiveAmount" header="KDV'siz Tutar" sortable body={(rowData) => formatAmount(rowData.TaxExclusiveAmount, rowData.DocumentCurrencyCode)} style={{ minWidth: '6rem' }}></Column>
                    <Column field="TaxTotal" header="Toplam KDV" sortable body={(rowData) => formatAmount(rowData.TaxTotal, rowData.DocumentCurrencyCode)} style={{ minWidth: '6rem' }}></Column>
                    <Column header="Senaryo Tipi / Fatura Tipi" body={(rowData) => InvoiceStatus(rowData)} style={{ minWidth: '12rem' }}></Column>
                    <Column field="StatusCode" header="Fatura Durumu" sortable body={(rowData) => getStatusColor(rowData)} style={{ minWidth: '12rem' }}></Column>
                    <Column header="İşlemler" body={actionBodyTemplate} className="text-center" exportable={false} style={{ minWidth: '5rem' }}></Column>
                </DataTable>
            </div>

            <InvoiceViewDialog invoiceVisible={invoiceViewDialog} invoiceView={invoiceView} onDownloadPDF={() => getInvoicePdf()} onDownloadXML={() => getInvoiceXML()} onCloseDialog={onCloseInvoiceViewDialog} />

            <Dialog header="Red Sebebi Ekleme" visible={reasonDialog} style={{ width: '30vw' }} modal onHide={() => onCloseInvoiceDialog()}>
                <div className="py-3">
                    <div className="mb-2">
                        <label>Red Sebebi</label>
                    </div>
                    <div className="mb-2">
                        <InputTextarea placeholder="Lütfen Red Sebebinizi Giriniz" className="w-full" rows={6} value={reason} onChange={(e) => setReason(e.target.value)} />
                    </div>
                    <div className="justify-content-end flex mt-5">
                        <Button icon="pi pi-ban" label="Onayla" onClick={() => rejectReceipts()} disabled={!reason} />
                    </div>
                </div>
            </Dialog>

            <Dialog header="Onaylama" visible={approveDialog} style={{ width: '30vw' }} modal onHide={() => onCloseInvoiceDialog()}>
                <div className="py-3">
                    <div className="mb-2">
                        <label>Onaylamak istediğinize emin misiniz ?</label>
                    </div>
                    <div className="justify-content-end flex mt-5">
                        <Button icon="pi pi-check" label="Onayla" onClick={() => approveReceipts()} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
