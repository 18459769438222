import { createSlice } from '@reduxjs/toolkit';


export const lucaSlice = createSlice({
    name: 'luca',
    initialState: {
        codeList: [],
        companyReq: [
            {
                title: "E Defter Şube Adı",
                value: ""
            },
            {
                title: "E Defter Şube Kodu",
                value: ""
            },
            {
                title: "Vergi Numarası",
                value: ""
            },
        ],
        webServiceReq: null,
        programReq: null
    },
    reducers: {
        setCodeList: (state, action) => {
            state.codeList = action.payload;
        },
        addNewCode: (state, action) => {
            state.codeList.push(action.payload);
        },
        deleteCode: (state, action) => {
            state.codeList = state.codeList.filter((item) => item.enum !== action.payload.enum);
        },
        updateCompanyReq: (state, action) => {
            state.companyReq = state.companyReq.map((item) => {
                if (item.title === action.payload.title) {
                    item.value = action.payload.value;
                }
                return item;
            });
        },
        setWebservicesReq: (state, action) => {
            state.webServiceReq = action.payload;
        },
        updateWebServiceReq: (state, action) => {
            state.webServiceReq[action.payload.title] = action.payload.value;
        },
        setProgramReq: (state, action) => {
            state.programReq = action.payload;
        },
        updateProgramReq: (state, action) => {
            state.programReq[action.payload.title] = action.payload.value;
        },
        clearAllLuca: (state) => {
            state.codeList = [];
            state.companyReq = [
                {
                    title: "E Defter Şube Adı",
                    value: ""
                },
                {
                    title: "E Defter Şube Kodu",
                    value: ""
                },
                {
                    title: "Vergi Numarası",
                    value: ""
                },
            ];
        }
    }
});

export const { 
    addNewCode, 
    deleteCode, 
    updateCompanyReq, 
    updateWebServiceReq, 
    setWebservicesReq, 
    clearAllLuca, 
    setCodeList,
    setProgramReq,
    updateProgramReq
} = lucaSlice.actions;

export default lucaSlice.reducer;
