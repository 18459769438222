import { createSlice } from '@reduxjs/toolkit';

export const rapidSlice = createSlice({
    name: 'rapid',
    initialState: {
        webServiceReq: null,
        programReq: null
    },
    reducers: {
        updateCompanyReq: (state, action) => {
            state.companyReq = state.companyReq.map((item) => {
                if (item.title === action.payload.title) {
                    item.value = action.payload.value;
                }
                return item;
            });
        },
        setWebservicesReq: (state, action) => {
            state.webServiceReq = action.payload;
        },
        updateWebServiceReq: (state, action) => {
            state.webServiceReq[action.payload.title] = action.payload.value;
        },
        setProgramReq: (state, action) => {
            state.programReq = action.payload;
        },
        updateProgramReq: (state, action) => {
            state.programReq[action.payload.title] = action.payload.value;
        }
    }
});

export const { updateCompanyReq, updateWebServiceReq, setWebservicesReq, setProgramReq, updateProgramReq } = rapidSlice.actions;

export default rapidSlice.reducer;
