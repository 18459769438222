import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { getCounters, deleteCounter } from '../../../../service/request/countersRequest';
import Loading from '../../../Loading';
import CounterAddDialog from '../../parasut/components/CounterAddDialog';
import CounterUpdateDialog from '../../parasut/components/CounterUpdateDialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

export default function CounterTable({ companyId, invoiceType }) {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [addCounterDialog, setAddCounterDialog] = useState(false);
    const [updateCounterDialog, setUpdateCounterDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedCounter, setSelectedCounter] = useState(null);
    const [counters, setCounters] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);

    const getCounterAsync = async () => {
        const res = await getCounters(companyId);
        setCounters(res.data.data);
        setLoading(false);
    };

    useEffect(() => {
        if (companyId) {
            getCounterAsync();
        }
    }, [companyId]);

    const hideCounterDialog = () => {
        setAddCounterDialog(false);
        setUpdateCounterDialog(false);
        getCounters(companyId)
            .then((res) => {
                setCounters(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDeleteCounter = () => {
        setDeleteDialog(false);
        const data = {
            id: String(selectedCounter.id)
        };

        deleteCounter(data)
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Sayaç Silindi', life: 3000 });
                getCounters(companyId)
                    .then((res) => {
                        setCounters(res.data.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const actionCounterTemplate = (rowData) => {
        return (
            <>
                <Button
                    icon="pi pi-pencil"
                    tooltip="Güncelle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    severity="primary"
                    className="mr-2"
                    onClick={() => {
                        setSelectedCounter(rowData);
                        setUpdateCounterDialog(true);
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    tooltip="Sil"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    severity="danger"
                    onClick={() => {
                        setSelectedCounter(rowData);
                        setDeleteDialog(true);
                    }}
                />
            </>
        );
    };

    const getBadge = (status, textSucess, textDanger) => {
        return status ? <span className="p-badge p-badge-success text-white">{textSucess}</span> : <span className="p-badge p-badge-danger text-white">{textDanger}</span>;
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Sayaçlar</h4>
            <div>
                <Button label="Yeni Sayaç" icon="pi pi-plus" className="p-button-primary text-white mr-4 px-6" onClick={() => setAddCounterDialog(true)} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Toast ref={toast} />
            <CounterAddDialog companyId={companyId} addCounterDialog={addCounterDialog} onHideCounterDialog={() => hideCounterDialog()} />
            <CounterUpdateDialog companyId={companyId} updateCounterDialog={updateCounterDialog} onHideCounterDialog={() => hideCounterDialog()} selectedCounter={selectedCounter} />

            <DataTable value={counters} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} emptyMessage="Sayaç Bulunamadı" header={header} globalFilter={globalFilter}>
                <Column field="prefix" header="Ön Ek" sortable></Column>
                <Column field="counter" header="Sayaç" sortable></Column>
                <Column field="year" header="Yıl" sortable></Column>
                <Column field="invoice_type.name" header="Sayaç Tipi" sortable></Column>
                <Column field="is_default" sortable header="Varsayılan" body={(rowData) => getBadge(rowData.is_default === 1, 'Varsayılan', 'Varsayılan Değil')}></Column>
                <Column header="İşlemler" body={actionCounterTemplate} exportable={false}></Column>
            </DataTable>

            <Dialog
                header="Sayaç Silme"
                visible={deleteDialog}
                style={{ width: '20vw' }}
                onHide={() => setDeleteDialog(false)}
                footer={
                    <div className="flex justify-content-end">
                        <Button label="İptal" icon="pi pi-times" severity="secondary" className="mr-2" onClick={() => setDeleteDialog(false)} />
                        <Button label="Sil" icon="pi pi-trash" severity="danger" onClick={() => handleDeleteCounter()} />
                    </div>
                }
            >
                <p className="mt-3 mb-4">{selectedCounter?.prefix} ön ekli sayacı silmek istediğinize emin misiniz?</p>
            </Dialog>
        </div>
    );
}
