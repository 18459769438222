import { Button } from 'primereact/button';

export const controlAllData = (data, messages = []) => {
    const invoiceDetails = data.EInvoicesLines;
    let hasError = false;

    invoiceDetails.forEach((invoice) => {
        const vatRate = invoice.KDVOrani;
        const vatExemptionReasonText = invoice.vat_exemption_reason.text;
        const vatExemptionReasonValue = invoice.vat_exemption_reason.value;

        const isVatRateZero = vatRate === 0.0;
        const isVatExemptionReasonNotEmpty = vatExemptionReasonText !== '' && vatExemptionReasonValue !== '';

        if (isVatRateZero && !isVatExemptionReasonNotEmpty) {
            hasError = true;
            messages.push('KDV oranı 0 olan faturalarda KDV istisnası seçilmelidir!');
        }

    });

    return !hasError;
};


export const rowIsTrue = (rowData) => {
    let messages = [];
    const check = controlAllData(rowData, messages);
    const invoiceUser = rowData.isEInvoice;
    const createDate = new Date(rowData.alias?.create_date);
    const issueDate = new Date(rowData.FaturaTarihi);

    if (!check) {
        return <Button severity="danger" icon="pi pi-exclamation-triangle" tooltip={[...messages]} size='large' tooltipOptions={{ position: 'bottom', mouseTrack: false, mouseTrackTop: 15 }} />;
    }

    if (invoiceUser && (createDate > issueDate)) {
        return <Button severity="warning" icon="pi pi-exclamation-circle" size='large'
        tooltip="Fatura tarihi, alıcı müşterinin E-Fatura mükellefi olduğu tarihten önce olduğundan fatura E-Arşiv faturası olarak gönderilecektir!" 
        tooltipOptions={{ position: 'bottom', mouseTrack: false, mouseTrackTop: 15 }} />;
    }

    return <Button severity="success" className='text-white' icon="pi pi-check" tooltip="Doğru satır!" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
};

export const wrongRow = (rowData) => {
    if (!controlAllData(rowData))
    {
        return rowData;
    }
};
