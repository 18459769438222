import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getByUser } from '../../service/request/companyRequest';
import { setCompanyId, getCompanyId } from '../../service/tokenServices';
import { updateLuca } from '../../service/request/moduleRequest';
import { getCompaniesByUserId } from '../../service/request/userRequest';

export const getCompaniesByUserAsyncThunk = createAsyncThunk('userCompany/getCompaniesByUser', async () => {
    const response = await getByUser();
    return response.data;
});

export const getCompaniesByIdAsyncThunk = createAsyncThunk('userCompany/getCompaniesById', async (id) => {
    const response = await getCompaniesByUserId(id);
    return response.data;
});

export const updateLucaAsyncThunk = createAsyncThunk('modules/updateLuca', async (data) => {
    const response = await updateLuca(data);
    return response.data;
});

export const userCompanySlice = createSlice({
    name: 'userCompany',
    initialState: {
        companyList: [],
        selectedCompany: null,
        error: null,
        status: null
    },
    reducers: {
        clearStatus: (state) => {
            state.status = null;
            state.error = null;
        },
        setSelectCompany: (state, action) => {
            state.selectedCompany = action.payload;
            setCompanyId(action.payload);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getCompaniesByUserAsyncThunk.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getCompaniesByUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companyList = action.payload.data;

                if (getCompanyId()) {
                    let company = state.companyList.find((company) => company.id === parseInt(getCompanyId().id));
                    state.selectedCompany = company;
                    setCompanyId(company);
                }
            })
            .addCase(getCompaniesByUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getCompaniesByIdAsyncThunk.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getCompaniesByIdAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.selectedCompany = action.payload.data;
            })
            .addCase(getCompaniesByIdAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateLucaAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.selectedCompany = action.payload.data;
            })
            .addCase(updateLucaAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export const { clearStatus, setSelectCompany } = userCompanySlice.actions;
export default userCompanySlice.reducer;
