import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';

import receiptCode from '../../../../assets/data/receiptCode.json';
import { isOther, getReceiptCode, getOtherCode } from '../utils/utils';

export default function AllReceiptUpdateDialog({ allEditDialog, onCloseDialog, onUpdateAllReceipt }) {
    const [selectedCode, setSelectedCode] = useState(null);
    const [selectedOtherCode, setSelectedOtherCode] = useState(null);
    const [paymentType, setPaymentType] = useState('');
    const [docIsUpdate, setDocIsUpdate] = useState(false);
    const [noDocReceipt, setNoDocReceipt] = useState(false);

    useEffect(() => {
        if (!allEditDialog) {
            setSelectedCode(null);
            setSelectedOtherCode(null);
            setPaymentType('');
            setDocIsUpdate(false);
            setNoDocReceipt(false);
        }
    }, [allEditDialog]);

    const handleUpdate = () => {
        onUpdateAllReceipt({ selectedCode, selectedOtherCode, paymentType, docIsUpdate, noDocReceipt });
    };

    return (
        <Dialog
            header="Toplu Düzenle"
            visible={allEditDialog}
            style={{ width: '40vw' }}
            modal
            onHide={() => onCloseDialog()}
            footer={<Button icon="pi pi-check" label="Güncelle" onClick={() => handleUpdate()} disabled={selectedCode && isOther(selectedCode) && !selectedOtherCode} />}
        >
            <div className="py-3">
                <div className="mb-3">
                    <label className="mb-2 block">Kaynak Belge Türü</label>
                    <Dropdown
                        dataKey="id"
                        value={selectedCode}
                        onChange={(e) => setSelectedCode(e.value)}
                        options={getReceiptCode(receiptCode)}
                        optionLabel="title"
                        placeholder="Kaynak Belge Türü Seçiniz"
                        filter
                        className="w-full mb-2"
                        emptyMessage="Kaynak Belge Türü Bulunamadı"
                        appendTo="self"
                    />
                </div>
                {selectedCode && isOther(selectedCode) && (
                    <div className="mb-3">
                        <label className="mb-2 block">Kaynak Belge Türü Açıklaması</label>
                        <Dropdown
                            dataKey="id"
                            value={selectedOtherCode}
                            onChange={(e) => setSelectedOtherCode(e.value)}
                            options={getOtherCode()}
                            optionLabel="title"
                            placeholder="Kaynak Belge Türü Açıklaması Seçiniz"
                            filter
                            className="w-full mb-2"
                            emptyMessage="Kaynak Belge Türü Açıklaması Bulunamadı"
                            appendTo="self"
                            required
                        />
                    </div>
                )}

                <div className="w-full mb-2">
                    <label>Ödeme Yöntemi</label>
                </div>
                <div className="w-full">
                    <InputText type="text" className="w-full" value={paymentType} onChange={(e) => setPaymentType(e.target.value)} placeholder="Ödeme Yöntemi" required />
                </div>

                <div className="flex align-items-center my-4">
                    <Checkbox inputId="noDocReceipt" onChange={() => setNoDocReceipt(!noDocReceipt)} checked={noDocReceipt} />
                    <label htmlFor="noDocReceipt" className="ml-2">
                        Fişlere Ait Belge Detayı Yoktur
                    </label>
                </div>
                <div className="flex align-items-center my-4">
                    <Checkbox inputId="docIsUpdate" onChange={() => setDocIsUpdate(!docIsUpdate)} checked={docIsUpdate} />
                    <label htmlFor="docIsUpdate" className="ml-2">
                        Belge No ve Belge Tarihi Güncelle
                    </label>
                </div>

                <Message severity="info" text="Belge No ve Belge Tarihini, Fiş No ve Fiş Tarihi ile güncellemek için üstteki kutucuğu işaretleyiniz." />
            </div>
        </Dialog>
    );
}
