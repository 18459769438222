export const getDateTemplate = (rowData) => {
    const date1 = getInvoiceDate(rowData.CreateDateUtc);
    const date2 = getInvoiceDate(rowData.ExecutionDate);

    return (
        <div className='text-center'>
            <div className="text-green-400 font-semibold mb-2">{date1}</div>

            <div className="text-blue-400 font-semibold">{date2}</div>
        </div>
    );
};


export const getInvoiceDate = (rowData) => {
    const date = new Date(rowData);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedHour = hour.toString().padStart(2, '0');
    const formattedMinute = minute.toString().padStart(2, '0');
    const formattedSecond = second.toString().padStart(2, '0');

    const formattedDate = `${formattedDay}.${formattedMonth}.${year} ${formattedHour}:${formattedMinute}:${formattedSecond}`;

    return formattedDate;
};


export const getVknNameTemplate = (rowData) => {
    return (
        <div>
            <div className="text-pink-500 font-semibold mb-2">{rowData.TargetTcknVkn}</div>

            <div>{rowData.TargetTitle}</div>
        </div>
    );
};


export const getStatusColor = (rowData) => {
    const statusName = inboxInvoiceStatusList.find((status) => status.StatusCode === rowData.StatusCode).statusName;

    const colorMap = {
        Taslak: '#999',
        İptal: '#FF0000',
        Kuyrukta: '#FA7268',
        İşleniyor: '#FA7268',
        'GİB’e iletildi': '#FA7268',
        Onaylandı: '#468847',
        'Onay Bekliyor': '#00CC66',
        'Kabul Ediliyor': '#468847',
        Reddediliyor: '#FF6666',
        'İade Ediliyor': '#990099',
        Reddedildi: '#FF6666',
        'İade Edildi': '#990099',
        'e-Arşiv İptal': '#999',
        Hata: '#FF0000'
    };

    return (
        <div className="text-center">
            <span className={`p-badge text-white h-auto`} style={{ backgroundColor: colorMap[statusName] }}>
                {statusName}
            </span>
        </div>
    );
};


export const inboxInvoiceStatusList = [
    { StatusCode: 0, statusName: 'Taslak', color: '#999' }, //
    { StatusCode: 10, statusName: 'İptal', color: '#FF0000' }, //
    { StatusCode: 100, statusName: 'Kuyrukta', color: '#FA7268' }, //
    { StatusCode: 200, statusName: 'İşleniyor', color: '#FA7268' }, //
    { StatusCode: 300, statusName: "GIB'e iletildi", color: '#FA7268' }, //
    { StatusCode: 1000, statusName: 'Onaylandı', color: '#468847' }, //
    { StatusCode: 1100, statusName: 'Onay Bekliyor', color: '#00CC66' }, //
    { StatusCode: '1100-0', statusName: 'Kabul Ediliyor', color: '#468847' }, //
    { StatusCode: '1100-1', statusName: 'Reddediliyor', color: '#FF6666' },
    { StatusCode: '1100-2', statusName: 'İade Ediliyor', color: '#990099' }, //
    { StatusCode: 1200, statusName: 'Reddedildi', color: '#FF6666' }, //
    { StatusCode: 1300, statusName: 'İade Edildi', color: '#990099' }, //
    { StatusCode: 1400, statusName: 'e-Arşiv İptal', color: '#999' }, //
    { StatusCode: 2000, statusName: 'Hata', color: '#FF0000' } //
];


export const InvoiceTypeCode = [
    { id: 0, value: 'SATIS', name: 'Satış', color: '#00CC66' }, //
    { id: 1, value: 'IADE', name: 'İade', color: '#990099' }, //
    { id: 2, value: 'TEVKIFAT', name: 'Tevkifat', color: '#3333FF' }, //
    { id: 3, value: 'ISTISNA', name: 'İstisna', color: '#0000FF' }, //
    { id: 4, value: 'OZELMATRAH', name: 'Özel Matrah', color: '#0033CC' }, //
    { id: 6, value: 'IHRACKAYITLI', name: 'İhraç kayıtlı', color: '#468847' }, //
    { id: 7, value: 'SGK', name: 'SGK', color: '#ffa037' }, //
    { id: 8, value: 'KOMISYONCU', name: 'Komisyoncu', color: '#64b3d6' }, //
    { id: 9, value: 'HKSSATIS', name: 'Hks Satış', color: '#00CC66' }, //
    { id: 10, value: 'HKSKOMISYONCU', name: 'Hks komisyoncu', color: '#64b3d6' }, //
    { id: 11, value: 'TEVKIFATIADE', name: 'Tevkifat İade', color: '#990099' }, //
    { id: 12, value: 'KONAKLAMAVERGISI', name: 'Konaklama Vergisi', color: '#3b71d8' } //
];

export const InvoiceTypes = [
    { id: 0, value: 'TEMELFATURA', name: 'Temel', color: '#47d1de' }, //
    { id: 1, value: 'TICARIFATURA', name: 'Ticari', color: '#ffa037' }, //
    { id: 2, value: 'YOLCUBERABERFATURA', name: 'Yolcu Beraber', color: '#999' }, //
    { id: 3, value: 'IHRACAT', name: 'İhracat', color: '#990099' }, //
    { id: 4, value: 'EARSIV', name: 'E - Arşiv', color: '#FF6666' }, //
    { id: 5, value: 'Hks', name: 'Hal Tipi', color: '#bb72bb' }, //
    { id: 6, value: 'KAMU', name: 'Kamu', color: '#64b3d6' } //
];

export const InvoiceStatus = (rowData) => {
    const typeCode = InvoiceTypes.find((type) => type.id === rowData.TypeCode);
    const invoiceTipTypeCode = InvoiceTypeCode.find((type) => type.id === rowData.InvoiceTipTypeCode);

    return (
        <div className="text-center">
            <span className={`p-badge text-white mb-3 px-3 h-auto`} style={{ backgroundColor: typeCode.color }}>
                {typeCode.name}
            </span>
            <br />
            <span className={`p-badge text-white px-3 h-auto`} style={{ backgroundColor: invoiceTipTypeCode.color }}>
                {invoiceTipTypeCode.name}
            </span>
        </div>
    );
};