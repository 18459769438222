import axios from 'axios';

let BASE_URL = process.env.REACT_APP_INT_URL;

const counters = {
    getCounters: BASE_URL + '/counters/getCounters',
    addCounter: BASE_URL + '/counters/addCounter',
    updateCounter: BASE_URL + '/counters/updateCounter',
    deleteCounter: BASE_URL + '/counters/deleteCounter'
};

export const getCounters = async (data) => {
    return await axios.get(counters.getCounters + `?companyId=${data}`);
};

export const addCounter = async (data) => {
    return await axios.post(counters.addCounter, data);
};

export const updateCounter = async (data) => {
    return await axios.post(counters.updateCounter, data);
};

export const deleteCounter = async (data) => {
    return await axios.post(counters.deleteCounter, data);
};
