import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { getInvoiceTypes } from '../utils/parasutRequest';
import { addCounter } from '../../../../service/request/countersRequest';

export default function CounterAddDialog({companyId, addCounterDialog, onHideCounterDialog}) {
    const [selectedInvoiceType, setSelectedInvoiceType] = useState(null);
    const [prefix, setPrefix] = useState('');
    const [year, setYear] = useState('');
    const [counter, setCounter] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const [invoiceType, setInvoiceType] = useState([]);

    useEffect(() => {
        if (!addCounterDialog) {
            setSelectedInvoiceType(null);
            setPrefix('');
            setYear('');
            setCounter('');
            setIsDefault(false);
        }
    }, [addCounterDialog]);

    useEffect(() => {
        if ((addCounterDialog) && (invoiceType.length === 0)) {
            getInvoiceTypes().then((res) => {
                const copy = [];
                res.data.data.forEach((item) => {
                    copy.push({ title: item.name, value: item.id });
                });
                setInvoiceType(copy);
            });
        }
    }, [invoiceType, addCounterDialog]);

    const sendButtonIsDisabled = () => {
        return !selectedInvoiceType || !year || !prefix || !counter || year.length !== 4 || prefix.length !== 3; 
    };

    const handleSave = () => {
        onHideCounterDialog()
        const data = {
            companyId: companyId,
            invoiceTypeId: String(selectedInvoiceType),
            prefix: prefix,
            year: year,
            counter: counter,
            isDefault: isDefault ? 1 : 0
        }

        addCounter(data).then((res) => {
            onHideCounterDialog();
        }
        ).catch((err) => {
            console.log(err);
        });

    };

    function convertToEnglishUpperCase(str) {
        const turkishChars = { ç: 'C', ğ: 'G', ı: 'I', i: 'I', ö: 'O', ş: 'S', ü: 'U', ü: 'U' };
        return str
            .replace(/[çğıiöşüĞÇİÖÜŞ0-9]/g, (m) => turkishChars[m] || m)
            .replace(/[^a-zA-Z0-9]/g, '')
            .toUpperCase();
    }

    const handleInputChangeYear = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');

        setYear(inputValue);
    };

    const handleInputChangeCounter = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');

        setCounter(inputValue);
    };

    return (
        <Dialog header="Sayaç ekle" visible={addCounterDialog} style={{ width: '50vw' }} modal onHide={() => onHideCounterDialog()}>
            <div className="mt-4">
                <label className="mb-2 block">Fatura Tipleri</label>
                <Dropdown
                    dataKey="id"
                    value={selectedInvoiceType}
                    onChange={(e) => setSelectedInvoiceType(e.value)}
                    options={invoiceType}
                    optionLabel="title"
                    placeholder="Fatura Tipi Seçiniz"
                    filter
                    className="w-full mb-2"
                    emptyMessage="Fatura Tipi Bulunamadı"
                    appendTo="self"
                />
            </div>
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Yıl</label>
                </div>
                <div className="w-full">
                    <InputText onChange={(e) => handleInputChangeYear(e)} placeholder="Yıl Giriniz" maxLength={4} minLength={4} value={year} type="text" className="w-full" />
                    {year.length !== 4 && year.length !== 0 && <small className="p-error">Yıl 4 haneli olmalıdır.</small>}
                </div>
            </div>
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Ön Ek</label>
                </div>
                <div className="w-full">
                    <InputText onChange={(e) => setPrefix(convertToEnglishUpperCase(e.target.value))} maxLength={3} minLength={3} placeholder="Ön Ek Giriniz" value={prefix} type="text" className="w-full" />
                    {prefix.length !== 3 && prefix.length !== 0 && <small className="p-error">Ön Ek 3 haneli olmalıdır.</small>}
                </div>
            </div>
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Sayaç</label>
                </div>
                <div className="w-full">
                    <InputText onChange={(e) => handleInputChangeCounter(e)} placeholder="0" maxLength={9} value={counter} type="text" className="w-full" />
                    {counter.length !== 9 && counter.length !== 0 && <small className="p-error">Sayaç maksimum 9 haneli olmalıdır.</small>}
                </div>
            </div>
            <div className="py-3">
                <Checkbox inputId="isDefault" onChange={(e) => setIsDefault(e.checked)} checked={isDefault} className="mr-2" />
                <label htmlFor="isDefault" className="p-checkbox-label">
                    Varsayılan
                </label>
            </div>
            <div className="text-center mt-5">
                <Button label="Ekle" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => handleSave()} disabled={sendButtonIsDisabled()} />
            </div>
        </Dialog>
    );
}
