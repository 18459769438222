import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';


export default function XsltTableDialog({ xsltListDialog, onCloseDialog, xsltList, onUpdateXsltDialog, onXsltView, onDeleteXslt }) {
    const actionXsltTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-pencil" tooltip="Düzenle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded severity="primary"
                    className='mr-2'
                    onClick={() => onUpdateXsltDialog(rowData)}
                />
                <Button icon="pi pi-eye" tooltip="Görüntüle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded severity="info"
                    className='mr-2'
                    onClick={() => onXsltView(rowData)}
                />
                <Button icon="pi pi-trash" tooltip="Sil"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded severity="danger"
                    onClick={() => onDeleteXslt(rowData)}
                />
            </>
        );
    };

    const getBadge = (status, textSucess, textDanger) => {
        return status ? <span className='p-badge p-badge-success'>{textSucess}</span> : <span className='p-badge p-badge-danger'>{textDanger}</span>;
    };

    return (
        <Dialog
            header="Şablonlar"
            visible={xsltListDialog}
            style={{ width: '50vw' }}
            maximizable modal
            contentStyle={{ height: '500px' }}
            onHide={() => onCloseDialog()}
        >
            <DataTable value={xsltList} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} emptyMessage="Şablon Bulunamadı">
                <Column field="name" header="Şablon Adı"></Column>
                <Column field="invoice_type.name" header="Şablon Tipi"></Column>
                <Column field="is_active" header="Aktif"
                    body={rowData => getBadge(rowData.is_active === 1, "Aktif", "Pasif")}></Column>
                <Column field="is_default" header="Varsayılan"
                    body={rowData => getBadge(rowData.is_default === 1, "Varsayılan", "Varsayılan Değil")}></Column>
                <Column header="İşlemler" body={actionXsltTemplate} exportable={false}></Column>
            </DataTable>
        </Dialog>
    );
}