import React, { useState, useRef, useEffect } from 'react';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { useSelector } from 'react-redux';
import { updateCompanySettingsAsyncThunk} from '../../../store/modules/moduleSlice';
import { useDispatch } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';

import WebServicesReq from './components/WebServicesReq';
import Loading from "../../Loading"

import ProgramReq from './components/ProgramReq';

import XsltTable from './components/XsltTable.js';
import XsltAddDialog from '../parasut/components/XsltAddDialog';
import XsltUpdateDialog from '../parasut/components/XsltUpdateDialog';
import XsltView from '../parasut/components/XsltView';

import { uploadXslt, getInvoiceTypes, updateXslt, deleteXslt, xsltView } from '../parasut/utils/parasutRequest';

import { useLocation } from 'react-router-dom';
import { setUpdatedModule } from '../../../store/modules/moduleSlice';
import CounterTable from './components/CounterTable';
import { getCompaniesByUserAsyncThunk } from '../../../store/company/userCompanySlice.js';

export default function UpdateParasut() {
    const { updatedModule, selectedModule } = useSelector((state) => state.modules);
    const { webServiceReq, programReq } = useSelector((state) => state.rapid);
    const { selectedCompany } = useSelector((state) => state.userCompany);

    const [addXltsDialog, setAddXltsDialog] = useState(false);
    const [updateXsltDialog, setUpdateXsltDialog] = useState(false);

    const [companyId, setCompanyId] = useState(null);
    const [invoiceType, setInvoiceType] = useState(null);
    const [selectedXslt, setSelectedXslt] = useState(null);
    const [xsltIsUpdate, setXsltIsUpdate] = useState(false);
    const [viewXsltDialog, setViewXsltDialog] = useState(false);
    const [xsltHtml, setXsltHtml] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pending, setPending] = useState(false);

    const location = useLocation();

    const dispatch = useDispatch();

    const toast = useRef(null);

    useEffect(() => {
        let path = location.pathname.split('/')[1];
        let module = selectedCompany?.modules.find((item) => item.title === path);
        dispatch(setUpdatedModule(module));
    }, [location.pathname, selectedCompany?.modules, dispatch, updatedModule]);

    useEffect(() => {
        if (programReq && webServiceReq) {
            let id = programReq.DealerID + programReq.EInvoiceCode;
            setCompanyId(id);
        }
    }, [programReq, webServiceReq]);

    useEffect(() => {
        if ((addXltsDialog || updateXsltDialog) && !invoiceType) {
            getInvoiceTypes().then((res) => {
                const copy = [];
                res.data.data.forEach((item) => {
                    copy.push({ title: item.name, value: item.id });
                });
                setInvoiceType(copy);
            });
        }
    }, [addXltsDialog, updateXsltDialog, invoiceType]);

    const save = async () => {
        const data = {
            companyId: selectedCompany.id,
            moduleId: updatedModule.id,
            settings: {
                moduleId: updatedModule.id,
                title: updatedModule.title,
                program: {
                    _id: updatedModule.setting.settings.program._id,
                    title: updatedModule.setting.settings.program.title,
                    requirements: [
                        {
                            client_id: programReq.client_id,
                            client_secret: programReq.client_secret,
                            DealerID: programReq.DealerID,
                            EInvoiceCode: programReq.EInvoiceCode,
                            SystemId: programReq.SystemId
                        }
                    ]
                },
                webService: {
                    requirements: webServiceReq,
                    _id: selectedModule._id || selectedModule.id,
                    title: selectedModule.title,
                    url: selectedModule.url
                }
            }
        };

        setPending(true);
        await dispatch(updateCompanySettingsAsyncThunk(data));
        await dispatch(getCompaniesByUserAsyncThunk());
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül güncellendi', life: 1500 });

        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const handleUploadXslt = async (formData) => {
        try {
            const res = await uploadXslt(formData);

            setXsltIsUpdate(true);
            setAddXltsDialog(false);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şablon Eklendi', life: 1500 });
            setCompanyId(null);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şablon Eklenemedi', life: 1500 });
        }
    };

    const handleUpdateXslt = async (formData) => {
        try {
            const res = await updateXslt(formData);
            setUpdateXsltDialog(false);
            setXsltIsUpdate(true);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şablon Güncellendi', life: 1500 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şablon Güncellenemedi', life: 1500 });
        }
    };

    const handleUpdateXsltDialog = (rowData) => {
        setUpdateXsltDialog(true);
        setSelectedXslt(rowData);
    };

    const handleDeleteXslt = (rowData) => {
        deleteXslt(rowData.id, rowData.company_id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şablon Silindi', life: 1500 });
        });
        setXsltIsUpdate(true);
    };

    const handleXsltView = (rowData) => {
        setViewXsltDialog(true);
        xsltView(rowData.id, rowData.company_id).then((res) => {
            setXsltHtml(res.data.data);
        });
    };

    const hideXsltDialog = () => {
        setAddXltsDialog(false);
        setUpdateXsltDialog(false);
    };

    const programReqState = () => {
        return programReq?.client_id && programReq?.client_secret && programReq?.DealerID && programReq?.EInvoiceCode && programReq?.SystemId;
    };

    const webServiceReqState = () => {
        return webServiceReq?.username && webServiceReq?.password;
    };

    const isDisabled = () => {
        if (programReqState() && webServiceReqState() && selectedModule !== null) {
            return false;
        }

        return true;
    };

    return (
        <div className="card">
            <Toast ref={toast} />
            {/* <Button icon="pi pi-cog" label="Modül Değiştir" onClick={() => setUpdateDialog(true)}></Button> */}
            <div>
                <div className="flex flex-column md:flex-row">
                    <div className="w-full">{updatedModule && <ProgramReq req={updatedModule?.setting.settings.program.requirements[0]} title={updatedModule?.setting.settings.program.title} />}</div>
                    <div className="w-full md:w-1">
                        <Divider layout="vertical" className="hidden md:flex"></Divider>
                    </div>

                    {updatedModule && (
                        <div className="w-full md:w-1/2">
                            <WebServicesReq req={updatedModule?.setting.settings.webService.requirements} title={updatedModule?.setting.settings.webService.title} />
                        </div>
                    )}
                </div>
            </div>

            <TabView className='mt-6'>
                <TabPanel header="Şablonlar">
                        <XsltTable
                            companyId={companyId}
                            onAddXsltDialog={() => setAddXltsDialog(true)}
                            onUpdateXsltDialog={handleUpdateXsltDialog}
                            checkAgain={xsltIsUpdate}
                            onCheckAgain={() => setXsltIsUpdate(false)}
                            onDeleteXslt={handleDeleteXslt}
                            onXsltView={handleXsltView}
                        />
                </TabPanel>
                <TabPanel header="Sayaçlar">
                    <CounterTable companyId={companyId} invoiceType={invoiceType} />
                </TabPanel>
            </TabView>

            <XsltAddDialog addXltsDialog={addXltsDialog} companyId={companyId} invoiceType={invoiceType} onHideXsltDialog={() => hideXsltDialog()} onUploadXslt={handleUploadXslt} />

            <XsltUpdateDialog updateXsltDialog={updateXsltDialog} invoiceType={invoiceType} onHideXsltDialog={() => hideXsltDialog()} onUpdateXslt={handleUpdateXslt} selectedXslt={selectedXslt} />

            <XsltView
                viewXsltDialog={viewXsltDialog}
                onCloseDialog={() => {
                    setViewXsltDialog(false);
                    setXsltHtml(null);
                }}
                xsltHtml={xsltHtml}
            />

            <div className="text-center mt-5">
                <Button label="Kaydet" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} loading={pending} disabled={isDisabled()} />
            </div>

            {/* <ModuleChangeDialog visible={updateDialog} onCloseDialog={() => setUpdateDialog(false)} /> */}
        </div>
    );
}
