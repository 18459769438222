import { createSlice } from '@reduxjs/toolkit';

export const controllerSlice = createSlice({
    name: 'controller',
    initialState: {
        wipList: []
    },
    reducers: {
        addWipList: (state, action) => {
            state.wipList = [...state.wipList, action.payload];
        },
        removeWipList: (state, action) => {
            state.wipList = state.wipList.filter((item) => item !== action.payload)[0];
        },
        isHereWipList: (state, action) => {
            if (state.wipList.includes(action.payload)) {
                return true;
            } else {
                return false;
            }
        }
    }
});

export const { addWipList, removeWipList, isHereWipList } = controllerSlice.actions;
export default controllerSlice.reducer;
