import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';


export default function UpdatePasswordDiaolog({ visible, onHide, onSubmit }) {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(true);

    return <Dialog
        header="Şifre Değiştir"
        visible={visible}
        style={{ width: '20vw' }}
        onHide={() => {
            onHide();
            setPassword('');
        }}
        footer={
            <div className="flex justify-content-end">
                <Button
                    label="İptal"
                    icon="pi pi-times"
                    severity="secondary"
                    className="mr-2"
                    onClick={() => {
                        onHide();
                        setPassword('');
                    }}
                />
                <Button icon="pi pi-check" label="Onayla" onClick={() => onSubmit(password)} />
            </div>
        }
    >
        <div className="relative">
            <label className="block mb-3">Şifre Güncelleme</label>
            <InputText type={showPassword ? "password" : "text"} className="w-full" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" />
            <Button
                type="button"
                className="p-button-text p-0 text-sky-600 ml-2 absolute right-0"
                style={{ bottom: ".5rem" }}
                icon={showPassword ? "pi pi-eye" : "pi pi-eye-slash"}
                onClick={() => setShowPassword(!showPassword)}
            />
        </div>
    </Dialog>
}