import React, { useEffect, useState } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { addLocale } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';

import { translateDocType, documentData, docName, checkAmountAndCredit, rowIsTrue, wrongRow } from './eDefterZenom/utils/controller';
import { getErrorReceiptCount } from './eDefterZenom/utils/utils';

import { importHtml, sendReceiptsData } from '../module/zenom/utils/ZenomRequest';

import SendReceiptDialog from './eDefterZenom/components/SendReceiptDialog';
import AllReceiptUpdateDialog from './eDefterZenom/components/AllReceiptsUpdateDialog';
import OneReceiptUpdateDialog from './eDefterZenom/components/OneReceiptsUpdateDialog';
import Loading from '../Loading';
import { formatAmount, HeaderProgram, formatDate, localeDate, EditButtonTemplate } from './utils/utils';
import { InputSwitch } from 'primereact/inputswitch';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import { Tag } from 'primereact/tag';

export default function ReceiptList() {
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [receipts, setReceipts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);

    const [selectedReceipt, setSelectedReceipt] = useState(null);

    const [oneEditDialog, setOneEditDialog] = useState(false);
    const [allEditDialog, setAllEditDialog] = useState(false);
    const [sendReceiptsDialog, setSendReceiptsDialog] = useState(false);
    const [pending, setPending] = useState(false);
    const [errorReceiptsCount, setErrorReceiptsCount] = useState(0);

    const [receiptFile, setReceiptFile] = useState(null);
    const [accountPlansFile, setAccountPlansFile] = useState(null);
    const [checkErrorReceipts, setCheckErrorReceipts] = useState(false);

    const dt = useRef(null);
    const toast = useRef(null);

    addLocale('tr', localeDate);

    useEffect(() => {
        if (receipts.length !== 0) setErrorReceiptsCount(getErrorReceiptCount(receipts));
    }, [receipts]);

    useEffect(() => {
        if (errorReceiptsCount === 0) {
            setCheckErrorReceipts(false);
        }
    }, [errorReceiptsCount]);

    const getReceiptList = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('html_file', receiptFile);

        importHtml(formData)
            .then((res) => {
                const sortedReceipts = res.data.data.sort((a, b) => {
                    const dateA = a.tarih.split('.').reverse().join('');
                    const dateB = b.tarih.split('.').reverse().join('');
                    if (dateA !== dateB) {
                        return dateA.localeCompare(dateB);
                    }
                    return parseInt(a.yevmiye_no, 10) - parseInt(b.yevmiye_no, 10);
                });
                setReceipts(sortedReceipts);
                setLoading(false);
                setTimeout(() => {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fişler başarıyla getirildi.', life: 3000 });
                }, 100);
            })
            .catch((error) => {
                setLoading(false);
                setTimeout(() => {
                    if (error) {
                        toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Bir hata oluştu.', life: 3000 });
                    }
                }, 100);
            });

        setReceiptFile(null);
        setAccountPlansFile(null);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const controlDebitCredit = (rowData) => {
        let debit = null;
        let credit = null;

        if (!rowData || !rowData?.fis_detay || !['D', 'C'].includes(rowData?.fis_detay?.DebitCreditCode)) {
            return <Tag severity="danger" value={'SIFIR OLAMAZ'} />;
        } else {
            if (rowData.fis_detay.DebitCreditCode === 'D') {
                debit = rowData.Detay !== '0' ? `₺${rowData.Detay}` : '₺0';
                return debit;
            } else if (rowData.fis_detay.DebitCreditCode === 'C') {
                credit = rowData.Detay !== '0' ? `₺${rowData.Detay}` : '₺0';
                return credit;
            }
        }
    };

    const rowExpansionTemplate = (rowData) => {
        const receiptDetails = rowData.detay.filter((item) => item.action === true);
        return (
            <div className="p-3">
                <h5>Fiş Detayı</h5>
                <DataTable emptyMessage="Modül Bulunamadı" value={receiptDetails}>
                    <Column field="" header="" style={{ minWidth: '2rem' }} body={(rowData) => rowIsTrue(rowData, 'detay')}></Column>
                    <Column field="code" header="Hesap Kodu" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="name" header="Hesap Adı" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="borc" header="Borç" style={{ minWidth: '12rem' }} sortable body={(rowData) => (rowData?.fis_detay?.DebitCreditCode === 'D' ? controlDebitCredit(rowData) : '₺0')}></Column>
                    <Column field="alacak" header="Alacak" style={{ minWidth: '12rem' }} sortable body={(rowData) => (rowData?.fis_detay?.DebitCreditCode === 'C' ? controlDebitCredit(rowData) : '₺0')}></Column>
                    <Column field="aciklama" header="Açıklama" style={{ minWidth: '12rem' }} sortable body={(rowData) => (rowData?.aciklama ? rowData?.aciklama : <Tag severity="danger" value={'BOŞ'} />)}></Column>
                    <Column
                        field="kaynak_belge_detay.kaynak_belge_turu_adi"
                        header="Kaynak Belge Türü"
                        sortable
                        body={(rowData) => (rowData?.kaynak_belge_detay?.kaynak_belge_turu_adi ? rowData?.kaynak_belge_detay?.kaynak_belge_turu_adi : <Tag severity="danger" value={'BOŞ'} />)}
                        style={{ minWidth: '12rem' }}
                    ></Column>
                    <Column
                        field="kaynak_belge_detay.kaynak_belge_turu_aciklama"
                        header="Kaynak Belge Türü Açıklama"
                        sortable
                        body={(rowData) =>
                            rowData?.kaynak_belge_detay?.kaynak_belge_turu_aciklama ? rowData?.kaynak_belge_detay?.kaynak_belge_turu_aciklama : rowData?.kaynak_belge_detay?.kaynak_belge_turu_adi ? '' : <Tag severity="danger" value={'BOŞ'} />
                        }
                        style={{ minWidth: '12rem' }}
                    ></Column>
                    <Column
                        field="fis_detay.DocumentDate"
                        header="Kaynak Belge Tarihi"
                        sortable
                        body={(rowData) => (rowData?.fis_detay?.DocumentDate ? rowData?.fis_detay?.DocumentDate : <Tag severity="danger" value={'BOŞ'} />)}
                        style={{ minWidth: '12rem' }}
                    ></Column>
                    <Column
                        field="fis_detay.DocumentNumber"
                        header="Kaynak Belge Numarası"
                        sortable
                        body={(rowData) => (rowData?.fis_detay?.DocumentNumber ? rowData?.fis_detay?.DocumentNumber : <Tag severity="danger" value={'BOŞ'} />)}
                        style={{ minWidth: '12rem' }}
                    ></Column>
                </DataTable>
            </div>
        );
    };

    const handleHideOneEditDialog = () => {
        setOneEditDialog(false);
        setSelectedReceipt(null);
    };

    const handleUpdateOneReceipt = ({ paymentType, docNo, docDate }) => {
        const rec = selectedReceipt[0].altBasliklar;

        rec.forEach((item) => {
            item.kaynak_belge_no = docNo || item.kaynak_belge_no;
            item.kaynak_belge_tarihi = formatDate(docDate, 'slash') || item.kaynak_belge_tarihi;
            item.odeme_sekli = paymentType || item.odeme_sekli;
        });

        const recCopy = [...receipts];

        const index = recCopy.findIndex((r) => r.fis_no === rec.fis_no);

        recCopy[index] = rec;

        setReceipts(recCopy);

        setOneEditDialog(false);
        setSelectedReceipt(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fiş başarıyla güncellendi.', life: 3000 });
    };

    const handleUpdateAllReceipt = ({ paymentType, docIsUpdate, noDocReceipt }) => {
        const recCopy = [...receipts];

        selectedReceipt.forEach((item) => {
            const rec = item;

            if (noDocReceipt) {
                rec.belgesiz_fis = 1;
            } else {
                rec.belgesiz_fis = 0;
            }

            rec.altBasliklar.forEach((i) => {
                if (docIsUpdate) {
                    i.kaynak_belge_no = rec.fis_no || i.kaynak_belge_no;
                    i.kaynak_belge_tarihi = rec.fis_tarihi || i.kaynak_belge_tarihi;
                    i.odeme_sekli = paymentType || i.odeme_sekli;
                }
            });

            const index = recCopy.findIndex((r) => r.fis_no === rec.fis_no);

            recCopy[index] = rec;
        });

        setReceipts(recCopy);

        setAllEditDialog(false);
        setSelectedReceipt(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fişler başarıyla güncellendi.', life: 3000 });
    };

    const allDebits = formatAmount(
        receipts.reduce((acc, item) => acc + parseFloat(item.borc.replace(/\./g, '').replace(',', '.') || 0), 0),
        'TRY'
    );
    const allCredits = formatAmount(
        receipts.reduce((acc, item) => acc + parseFloat(item.alacak.replace(/\./g, '').replace(',', '.') || 0), 0),
        'TRY'
    );

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h4 className="m-0">Fiş Listesi</h4>
                {receipts.length !== 0 && (
                    <div className="flex align-items-center gap-5">
                        <div className="m-0 p-badge p-overlay-badge p-badge-info">
                            Hatalı Fiş Sayısı <span className="p-badge p-badge-danger">{errorReceiptsCount}</span>
                        </div>
                        <div className="flex align-items-end gap-2">
                            <label className="block font-normal">Hatalı Fişleri Listele</label>
                            <InputSwitch
                                checked={checkErrorReceipts}
                                onChange={(e) => {
                                    setCheckErrorReceipts(e.value);
                                    setSelectedReceipt(null);
                                }}
                            />
                        </div>
                        <div className="p-badge p-overlay-badge p-badge-success text-white">
                            Fiş Adedi <span className="p-badge p-badge-danger">{receipts.length}</span>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {receipts.length !== 0 && <h6 className="mb-0 mr-6 p-badge p-overlay-badge p-badge-danger text-white h-auto w-auto">Toplam Borç {allDebits}</h6>}
                {receipts.length !== 0 && <h6 className="m-0 p-badge p-overlay-badge p-badge-success text-white h-auto w-auto">Toplam Alacak {allCredits}</h6>}
            </div>
            <div>
                <EditButtonTemplate data={selectedReceipt} onEdit={() => setOneEditDialog(true)} onAllEdit={() => setAllEditDialog(true)} />
                <Button icon="pi pi-arrow-right" severity="success" className="mr-4 text-white" label="E-Defter Oluştur" onClick={() => setSendReceiptsDialog(true)} disabled={!(receipts.length > 0 && errorReceiptsCount === 0)} loading={pending} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const sendReceipts = async (enteredBy, vknTckn, startDate, endDate, directSendValue) => {
        const module = selectedCompany.modules.filter((module) => module.title === 'E-Defter')[0];

        const programReq = module.setting.settings.program.requirements[0];
        const webServiceReq = module.setting.settings.webService.requirements;

        setSendReceiptsDialog(false);

        const data = {
            taxNumber: vknTckn,
            enteredBy: enteredBy,
            eDefterSubeAdi: programReq.eDefterSubeAdi,
            eDefterSubeKodu: programReq.eDefterSubeKodu,
            webServiceUrl: module.setting.settings.webService.url,
            username: webServiceReq.username,
            password: webServiceReq.password,
            data: receipts,
            startDate: formatDate(startDate, 'dashReverse'),
            endDate: formatDate(endDate, 'dashReverse'),
            direct_send: directSendValue
        };

        try {
            setPending(true);
            const res = await sendReceiptsData(data);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: res.data.message, life: 3000 });
        } catch (error) {
            error.code === 'ERR_NETWORK'
                ? toast.current.show({ severity: 'error', summary: 'Hata', detail: 'İstek zaman aşımına uğradı', life: 3000 })
                : toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
        setPending(false);
        setSelectedReceipt(null);
    };

    return (
        <div>
            <Toast ref={toast} />
            <HeaderProgram selectedCompany={selectedCompany} moduleName="E-Defter" />
            <br />
            <h3 className="mb-5">Fiş Tablosu</h3>
            <div className="card flex align-items-end justify-content-between">
                <div className="flex align-items-end pb-3 gap-5">
                    <div>
                        <label className="mb-2 block">Fiş Dosyası</label>
                        <FileUpload chooseLabel="Fiş Html dosyasını seçiniz." mode="basic" accept=".html" maxFileSize={100000000} onSelect={(e) => setReceiptFile(e.files[0])} />
                        <small className="block text-pink-500 absolute">*Dosya uzantısı .html olmalıdır</small>
                    </div>
                    <Button icon="pi pi-ticket" onClick={() => getReceiptList()} className="px-4" label="Dosyaları Yükle" disabled={!receiptFile} />
                </div>
            </div>
            {errorReceiptsCount !== 0 && (
                <Message
                    className="mb-3 h-4rem w-full flex justify-content-start font-semibold"
                    style={{ backgroundColor: '#FC6161' }}
                    sticky
                    severity="error"
                    text="Hatalı fiş kayıtlarını görüntülemek için 'Hatalı Fişleri Listele' butonunu aktif hale getirmelisiniz."
                    closable
                />
            )}
            <div className="card">
                <DataTable
                    ref={dt}
                    value={checkErrorReceipts ? receipts.filter((receipt) => wrongRow(receipt)) : receipts}
                    dataKey="yevmiye_no"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} fişten {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Fiş Bulunamadı"
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    className="p-datatable-gridlines"
                    selectionMode={'checkbox'}
                    selection={selectedReceipt}
                    onSelectionChange={(e) => setSelectedReceipt(e.value)}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column expander style={{ width: '2rem' }} />
                    <Column field="" header="" style={{ minWidth: '2rem' }} body={(rowData) => rowIsTrue(rowData, 'fis')}></Column>
                    <Column field="tarih" header="Fiş Tarihi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="yevmiye_no" header="Yevmiye No" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="borc" header="Toplam Borç" sortable style={{ minWidth: '12rem' }} body={(rowData) => <p>₺{rowData?.borc}</p>}></Column>
                    <Column field="alacak" header="Toplam Alacak" sortable style={{ minWidth: '12rem' }} body={(rowData) => <p>₺{rowData?.alacak}</p>}></Column>
                    <Column field="aciklama" header="Açıklama" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="baslik" header="Başlık" sortable style={{ minWidth: '8rem' }}></Column>
                </DataTable>
            </div>

            <OneReceiptUpdateDialog oneEditDialog={oneEditDialog} onCloseDialog={() => handleHideOneEditDialog()} onUpdateOneReceipt={handleUpdateOneReceipt} />

            <AllReceiptUpdateDialog allEditDialog={allEditDialog} onCloseDialog={() => setAllEditDialog(false)} onUpdateAllReceipt={handleUpdateAllReceipt} />

            <SendReceiptDialog
                receipts={receipts}
                sendReceiptsDialog={sendReceiptsDialog}
                onCloseDialog={() => setSendReceiptsDialog(false)}
                sendReceipts={sendReceipts}
                pending={pending}
                selectedCompanyCode={selectedCompanyCode}
                allDebits={allDebits}
                allCredits={allCredits}
            />
        </div>
    );
}
