import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllModules, addLuca, updateLuca, deleteModule, addParasut, updateParasut, addRapid, updateRapid, updateCompanyModule, addDatasoft, updateDatasoft } from '../../service/request/moduleRequest';
import { updateSettings } from '../../service/request/companyRequest';

export const getAllModulesAsyncThunk = createAsyncThunk('modules/getAllModules', async () => {
    const response = await getAllModules();
    return response.data;
});

export const addLucaAsyncThunk = createAsyncThunk('modules/addLuca', async (data) => {
    const response = await addLuca(data);
    return response.data;
});

export const updateLucaAsyncThunk = createAsyncThunk('modules/updateLuca', async (data) => {
    const response = await updateLuca(data);
    return response.data;
});

export const addDatasoftAsyncThunk = createAsyncThunk('modules/addDatasoft', async (data) => {
    const response = await addDatasoft(data);
    return response.data;
});

export const updateDatasoftAsyncThunk = createAsyncThunk('modules/updateDatasoft', async (data) => {
    const response = await updateDatasoft(data);
    return response.data;
});

export const deleteModuleAsyncThunk = createAsyncThunk('modules/deleteModule', async (data) => {
    const response = await deleteModule(data);
    return response.data;
});

export const addParasutAsyncThunk = createAsyncThunk('modules/addParasut', async (data) => {
    const response = await addParasut(data);
    return response.data;
});

export const updateParasutAsyncThunk = createAsyncThunk('modules/updateParasut', async (data) => {
    const response = await updateParasut(data);
    return response.data;
});

export const addRapidAsyncThunk = createAsyncThunk('modules/addRapid', async (data) => {
    const response = await addRapid(data);
    return response.data;
});

export const updateRapidAsyncThunk = createAsyncThunk('modules/updateRapid', async (data) => {
    const response = await updateRapid(data);
    return response.data;
});

export const updateCompanyModuleAsyncThunk = createAsyncThunk('modules/updateCompanyModule', async (data) => {
    const response = await updateCompanyModule(data);
    return response.data;
});

export const updateCompanySettingsAsyncThunk = createAsyncThunk('modules/updateCompanySettings', async (data) => {
    const response = await updateSettings(data);
    return response.data;
});

export const moduleSlice = createSlice({
    name: 'modules',
    initialState: {
        error: null,
        moduleList: [],
        module: null,
        selectedModule: null,
        selectedProgram: null,
        selectedCompanyId: null,
        selectedModuleId: null,
        updatedModule: null,

        status: null,
        webServicesList: [],
        commercialProgramsList: []
    },
    reducers: {
        setRequirements: (state, action) => {
            state.selectedModule = action.payload.serviceReq;
            state.selectedProgram = action.payload.programReq;
            state.module = action.payload.module;
        },
        setSelectedCompanyId: (state, action) => {
            state.selectedCompanyId = action.payload;
        },
        setSelectedModuleId: (state, action) => {
            state.selectedModuleId = action.payload;
        },
        setUpdatedModule: (state, action) => {
            state.updatedModule = action.payload;
        },
        setSelectedModule: (state, action) => {
            state.selectedModule = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllModulesAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllModulesAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = action.payload.data;
            })
            .addCase(getAllModulesAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addLucaAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = [...state.moduleList, action.payload.data];
            })
            .addCase(addLucaAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateLucaAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = action.payload.data.modules;
            })
            .addCase(updateLucaAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addDatasoftAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = [...state.moduleList, action.payload.data];
            })
            .addCase(addDatasoftAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateDatasoftAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.map((module) => (module._id === action.payload.data._id ? action.payload.data : module));
            })
            .addCase(updateDatasoftAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteModuleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.filter((module) => module._id !== action.payload.data._id);
            })
            .addCase(deleteModuleAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addParasutAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = [...state.moduleList, action.payload.data];
            })
            .addCase(addParasutAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateParasutAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = action.payload.data.modules;
            })
            .addCase(updateParasutAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addRapidAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = [...state.moduleList, action.payload.data];
            })
            .addCase(addRapidAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateRapidAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.map((module) => (module._id === action.payload.data._id ? action.payload.data : module));
            })
            .addCase(updateRapidAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateCompanyModuleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.map((module) => (module._id === action.payload.data._id ? action.payload.data : module));
            })
            .addCase(updateCompanyModuleAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateCompanySettingsAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.moduleList = state.moduleList.map((module) => (module.id === action.payload.data.id ? action.payload.data : module));
                
            })
    }
});

export const {
    setRequirements,
    setSelectedCompanyId,
    setSelectedModuleId,
    setUpdatedModule,
    setSelectedModule
} = moduleSlice.actions;

export default moduleSlice.reducer;
