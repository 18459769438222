import { createSlice } from '@reduxjs/toolkit';

export const zenomSlice = createSlice({
    name: 'zenom',
    initialState: {
        webServiceReq: null,
        programReq: null
    },
    reducers: {
        setWebservicesReq: (state, action) => {
            state.webServiceReq = action.payload;
        },
        updateWebServiceReq: (state, action) => {
            state.webServiceReq[action.payload.title] = action.payload.value;
        },
        setProgramReq: (state, action) => {
            state.programReq = action.payload;
        },
        updateProgramReq: (state, action) => {
            state.programReq[action.payload.title] = action.payload.value;
        }
    }
});

export const { updateWebServiceReq, setWebservicesReq, setProgramReq, updateProgramReq } = zenomSlice.actions;

export default zenomSlice.reducer;
