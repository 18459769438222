export const SidebarData = {
    Anasayfa: {
        key: 'anasayfa',
        label: 'Anasayfa',
        icon: 'pi pi-home',
        items: [
            {
                key: 'anasayfa',
                label: 'Anasayfa',
                icon: 'pi pi-home',
                data: 'Home',
                to: '/dashboard'
            }
        ]
    },
    'E-Defter': {
        key: 'e-defter',
        label: 'E-Defter',
        icon: 'pi pi-fw pi-folder',
        items: [
            {
                label: 'E-Defter İşlemleri',
                icon: 'pi pi-fw pi-book',
                items: [
                    {
                        key: 'e-defter-gonder',
                        label: 'E-Defter Hazırla',
                        icon: 'pi pi-fw pi-send',
                        data: 'Send E-Defters'
                    },
                    {
                        key: 'e-defter-taslaklar',
                        label: 'Taslak E-Defter',
                        icon: 'pi pi-fw pi-file',
                        data: 'List of CSVs'
                    },
                    {
                        key: 'moduleupdate',
                        label: 'Firma Bilgilerini Güncelle',
                        icon: 'pi pi-fw pi-refresh',
                        data: 'Module Update'
                    }
                ]
            }
        ]
    },

    'E-Fatura': {
        key: 'e-fatura',
        label: 'E-Fatura',
        icon: 'pi pi-fw pi-briefcase',
        items: [
            {
                label: 'E-Fatura İşlemleri',
                icon: 'pi pi-fw pi-book',
                items: [
                    {
                        key: 'e-fatura-gonder',
                        label: 'E-Fatura Gönder',
                        icon: 'pi pi-fw pi-send',
                        data: 'Send E-Invoice'
                    },
                    {
                        key: 'giden-e-fatura',
                        label: 'Giden E-Fatura',
                        icon: 'pi pi-fw pi-arrow-right',
                        data: 'Outgoing E-Invoices'
                    },
                    {
                        key: 'gelen-e-fatura',
                        label: 'Gelen E-Fatura',
                        icon: 'pi pi-fw pi-arrow-left',
                        data: 'Incoming E-Invoices'
                    },
                    {
                        key: 'moduleupdate',
                        label: 'Modül Güncelleme',
                        icon: 'pi pi-fw pi-refresh',
                        data: 'Module Update'
                    }
                ]
            }
        ]
    },

    'E-İrsaliye': {
        key: 'e-irsaliye',
        label: 'E-İrsaliye',
        icon: 'pi pi-fw pi-briefcase',
        items: [
            {
                label: 'E-İrsaliye İşlemleri',
                icon: 'pi pi-fw pi-book',
                items: [
                    {
                        key: 'e-irsaliye-gonder',
                        label: 'E-İrsaliye Gönder',
                        icon: 'pi pi-fw pi-send',
                        data: 'Send E-Waybill'
                    },
                    {
                        key: 'giden-e-irsaliye',
                        label: 'Giden E-İrsaliye',
                        icon: 'pi pi-fw pi-arrow-right',
                        data: 'Outgoing E-Waybills'
                    },
                    {
                        key: 'gelen-e-irsaliye',
                        label: 'Gelen E-İrsaliye',
                        icon: 'pi pi-fw pi-arrow-left',
                        data: 'Incoming E-Waybills'
                    },
                    {
                        key: 'moduleupdate',
                        label: 'Modül Güncelleme',
                        icon: 'pi pi-fw pi-refresh',
                        data: 'Module Update'
                    }
                ]
            }
        ]
    },
    'Eğitim Videoları': {
        key: 'egitim-videolari',
        label: 'Eğitim Videoları',
        icon: 'pi pi-fw pi-video',
        items: [
            {
                key: 'egitim-videolari',
                label: 'Eğitim Videoları',
                icon: 'pi pi-fw pi-video',
                data: 'Education Videos',
                to: '/education-videos'
            }
        ]
    }
};
