import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { convertReq, convertName } from "../../utils/utils";
import { updateProgramReq, setProgramReq } from "../../../../store/modules/parasutSlice";

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';


export default function ProgramReq({ req = null, title = null, onLogin = () => { }, isLogin = true }) {
    const { selectedProgram } = useSelector((state) => state.modules);
    const { programReq } = useSelector((state) => state.parasut);
    const [showPassword, setShowPassword] = useState(true);


    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedProgram && !req) {
            dispatch(setProgramReq(convertReq(selectedProgram?.requirements)));
        }

        if (req) {
            dispatch(setProgramReq(req));
        }
    }, [selectedProgram, req, dispatch]);

    const onProgramInputChange = (e, title) => {
        dispatch(updateProgramReq({ title, value: e.target.value }));
    };

    return (
        <div className="w-full">
            <form className="mt-5" onSubmit={onLogin}>
                <h3>{title || selectedProgram?.title}</h3>
                <div className="grid">
                    {programReq &&
                        Object.keys(programReq)?.map((req, i) => {
                            return (
                                <div key={i} className="py-3 col-6">
                                    <div className="w-full mb-2">
                                        <label htmlFor={req}>{convertName(req)}</label>
                                    </div>
                                    <div className="w-full relative">
                                        <InputText onChange={(e) => onProgramInputChange(e, req)}
                                            placeholder={convertName(req)} value={programReq[req]}
                                            type={req === "password" && showPassword ? "password" : "text"} className="w-full"
                                        />
                                        {
                                            req === "password" && (
                                                <Button
                                                    type="button"
                                                    className="p-button-text p-button-sm p-0 text-white ml-2 absolute right-0 top-0 h-full"
                                                    icon={showPassword ? "pi pi-eye" : "pi pi-eye-slash"}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        })}
                </div>
                {
                    !isLogin ? <Message className="py-3 my-3" severity="warn" text="Şirketleri görüntülemek için giriş yapınız." /> :
                        <Message className="py-3 my-3" severity='success' text="Şirketleri oluşturabilirsiniz." />
                }
                <div className="w-full">
                    <Button label="Giriş Yap" icon="pi pi-check" className="p-button-success text-white p-mr-2 mt-3" />
                </div>
            </form>
        </div>
    );
}