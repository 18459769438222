import axios from 'axios';
import { users } from '../url';

export const getAllUsers = async () => {
    return await axios.get(users.getAllUsers);
};

export const getCompaniesByUserId = async (id) => {
    return await axios.get(users.getCompaniesByUserId + `?id=${id}` );
};

export const createUser = async (user) => {
    return await axios.post(users.createUser, user);
};

export const updateUser = async (user) => {
    return await axios.put(users.updateUser, user);
};

export const deleteUser = async (id) => {
    return await axios.delete(users.deleteUser + `/${id}`);
};

export const updateProfile = async (password) => {
    return await axios.post(users.updateProfile, password);
};
