import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

export const translateDocType = (kaynakBelgeTuru) => {
    switch (kaynakBelgeTuru) {
        case 'invoice':
            return 'Fatura';
        case 'check':
            return 'Çek';
        case 'order-customer':
            return 'Müşteri Sipariş Belgesi';
        case 'order-vendor':
            return 'Satıcı Sipariş Belgesi';
        case 'voucher':
            return 'Senet';
        case 'shipment':
            return 'Navlun';
        case 'receipt':
            return 'Makbuz';
        case 'other':
            return 'Diğer';
        case 'Geçersiz girdi':
            return <Tag severity="danger" value={'BOŞ'} />;
        default:
            return kaynakBelgeTuru;
    }
};

export const docName = (docExp, docType) => {
    if (docType === 'other' && (docExp === '' || docExp === null || docExp === undefined)) {
        return <Tag severity="danger" value={'BOŞ'} />;
    }

    switch (docExp) {
        case 'Geçersiz girdi':
            return <Tag severity="danger" value={'BOŞ'} />;
        default:
            return docExp;
    }
};

export const documentData = (docNo, docDate, title, docType) => {
    if ((docType === 'other' || docType === 'invoice' || docType === 'check') && (docNo === '' || docNo === null || docNo === undefined) && (docDate === '' || docDate === null || docDate === undefined)) {
        return <Tag severity="danger" value={'BOŞ'} />;
    }

    if (docNo && !docDate) {
        if (title === 'evrak_tarihi') {
            return <Tag severity="danger" value={'BOŞ'} />;
        }
        return docNo;
    } else if (!docNo && docDate) {
        if (title === 'evrak_no') {
            return <Tag severity="danger" value={'BOŞ'} />;
        }
        return docDate;
    } else if (docNo && docDate) {
        return title === 'evrak_no' ? docNo : docDate;
    } else {
        return <Tag severity="danger" value={'BOŞ'} />;
    }
};

export const itemNo = (itemNo) => {
    if (itemNo) {
        return itemNo;
    } else if (itemNo === '' || itemNo === null || itemNo === undefined) {
        return '0';
    }
};

export const checkAmountAndCredit = (amount, credit, title) => {
    if ((amount === '' || amount === null || amount === undefined) && (credit === '' || credit === null || credit === undefined)) {
        return <Tag severity="danger" value={'BOŞ'} />;
    } else if (amount === 0 && credit === 0) {
        return <Tag severity="danger" value={'SIFIR olamaz.'} />;
    } else {
        let amount1 = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY'
        }).format(title === 'borc' ? amount : credit);

        return <p> {amount1} </p>;
    }
};

const controllReceiptDetails = (receiptDetails) => {
    for (let i = 0; i < receiptDetails.length; i++) {
        if (
            receiptDetails[i].belge_turu === null ||
            receiptDetails[i].belge_turu === undefined ||
            receiptDetails[i].belge_turu === '' ||
            receiptDetails[i].evrak_no === null ||
            receiptDetails[i].evrak_no === undefined ||
            receiptDetails[i].evrak_no === '' ||
            receiptDetails[i].evrak_tarihi === null ||
            receiptDetails[i].evrak_tarihi === undefined ||
            receiptDetails[i].evrak_tarihi === ''
        ) {
            return false;
        }
    }

    return true;
};

export const controlAllData = (data, messages = []) => {
    const receipt = data.receipt;
    const receiptDetails = data.receipt?.receiptDetails;

    if (receipt?.receiptDetails.length < 2) {
        messages.push('Fiş detayı en az 2 satır olmalıdır.');
        return false;
    }

    if (receipt.borc !== receipt.alacak) {
        messages.push('Borç ve Alacak eşit olmalıdır.');
        return false;
    }

    if (!(docName(receipt.kaynak_belge_turu_aciklama, receipt.kaynak_belge_turu) === receipt.kaynak_belge_turu_aciklama)) {
        if (receipt.belgesiz_fis === 1) {
            return true;
        }
        messages.push('Belge Türü hatalı.');
        return false;
    }

    if (receipt.belgesiz_fis === 0) {
        if (receiptDetails[0].evrak_no === '' || receiptDetails[0].evrak_tarihi === '') {
            messages.push('Belgesiz fiş için fiş detayı girilmelidir.');
            return false;
        }
    }

    if (!controllReceiptDetails(receiptDetails)) {
        messages.push('Fiş detayı hatalı.');
        return false;
    }

    return true;
};

export const rowIsTrue = (rowData) => {
    let messages = [];
    const check = controlAllData(rowData, messages);

    if (!check) {
        return <Button severity="danger" size="large" icon="pi pi-exclamation-triangle" tooltip={[...messages]} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />;
    }

    return <Button severity="success" icon="pi pi-check" tooltip="Doğru satır!" className="text-white" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />;
};

export const wrongRow = (rowData) => {
    if (!controlAllData(rowData))
    {
        return rowData;
    }
};

export const checkBorc = (rowData) => {
    let amount = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY'
    }).format(rowData.receipt.borc);

    return <p> {amount} </p>;
};

export const checkAlacak = (rowData) => {
    let amount = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY'
    }).format(rowData.receipt.alacak);

    return <p> {amount} </p>;
};
