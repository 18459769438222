import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllUsers, createUser, deleteUser, updateUser, getCompaniesByUserId } from '../../service/request/userRequest';

export const getAllUsersAsyncThunk = createAsyncThunk('user/getAllUsers', async () => {
    const response = await getAllUsers();
    return response.data;
});

export const getCompaniesByUserIdAsyncThunk = createAsyncThunk('user/getCompaniesByUserId', async (id) => {
    const response = await getCompaniesByUserId(id);
    return response.data;
});

export const createUserAsyncThunk = createAsyncThunk('user/createUser', async (user) => {
    const response = await createUser(user);
    return response.data;
});

export const updateUserAsyncThunk = createAsyncThunk('user/updateUser', async (user) => {
    const response = await updateUser(user);
    return response.data;
});

export const deleteUserAsyncThunk = createAsyncThunk('user/deleteUser', async (id) => {
    const response = await deleteUser(id);
    return id;
});

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        users: [],
        status: null,
        error: null,
        toastMessage: null,
        userCompanies: []
    },
    reducers: {
        clearToast: (state) => {
            state.status = null;
            state.error = null;
            state.toastMessage = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getAllUsersAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllUsersAsyncThunk.fulfilled, (state, action) => {
                state.users = action.payload.data;
                state.error = null;
            })
            .addCase(getAllUsersAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(createUserAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createUserAsyncThunk.fulfilled, (state, action) => {
                state.error = null;
                state.status = 'success';
                state.toastMessage = 'Kullanıcı başarıyla eklendi.';
                state.users = [...state.users, action.payload.data];
            })
            .addCase(createUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Kullanıcı eklenirken bir hata oluştu.';
            })

            .addCase(updateUserAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.users = state.users.map(user => user._id === action.payload.data._id ? action.payload.data : user);
                state.error = null;
                state.toastMessage = 'Kullanıcı başarıyla güncellendi.';
            })
            .addCase(updateUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Kullanıcı güncellenirken bir hata oluştu.';
            })

            .addCase(deleteUserAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.users = state.users.filter(user => user._id !== action.payload);
                state.error = null;
                state.toastMessage = 'Kullanıcı başarıyla silindi.';
            })
            .addCase(deleteUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Kullanıcı silinirken bir hata oluştu.';
            })
            
            .addCase(getCompaniesByUserIdAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCompaniesByUserIdAsyncThunk.fulfilled, (state, action) => {         
                state.userCompanies = action.payload.data;
            })
            .addCase(getCompaniesByUserIdAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});


export const { clearToast } = userSlice.actions;
export default userSlice.reducer;

