import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';


export default function XsltAddDialog({ addXltsDialog, companyId, invoiceType, onHideXsltDialog, onUploadXslt }) {
    const [selectedInvoiceType, setSelectedInvoiceType] = useState(null);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [isDefault, setIsDefault] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (!addXltsDialog) {
            setSelectedInvoiceType(null);
            setFileName('');
            setFile(null);
            setIsDefault(false);
            setIsActive(false);
        }
    }, [addXltsDialog]);

    const sendButtonIsDisabled = () => {
        return !selectedInvoiceType || !fileName || !file;
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append("invoiceTypeId", selectedInvoiceType);
        formData.append("companyId", companyId);
        formData.append("name", fileName);
        formData.append("xslt", file);
        formData.append("isDefault", isDefault ? 1 : 0);
        formData.append("isActive", isActive ? 1 : 0);
        onUploadXslt(formData);
    };

    return (
        <Dialog header="Görünüm ekle"
            visible={addXltsDialog}
            style={{ width: '50vw' }}
            modal onHide={() => onHideXsltDialog()}
        >
            <div className="mt-4">
                <label className="mb-2 block">Fatura Tipleri</label>
                <Dropdown dataKey="id" value={selectedInvoiceType}
                    onChange={(e) => setSelectedInvoiceType(e.value)}
                    options={invoiceType}
                    optionLabel="title" placeholder="Fatura Tipi Seçiniz"
                    filter className="w-full mb-2"
                    emptyMessage="Fatura Tipi Bulunamadı"
                    appendTo="self"
                />
            </div>
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Belge İsmi</label>
                </div>
                <div className="w-full">
                    <InputText onChange={(e) => setFileName(e.target.value)}
                        placeholder="Dosya Adı Giriniz" value={fileName}
                        type="text" className="w-full"
                    />
                </div>
            </div>
            <div className='mb-2'>
                <label className="mb-2 block">Dosya Yükle</label>
                <FileUpload name="demo[]"
                    accept=".xslt"
                    maxFileSize={3000000}
                    emptyTemplate={<p className="m-0">Dosyalarınızı buraya sürükleyip bırakabilirsiniz.</p>}
                    chooseOptions={{ label: 'Dosya Seç', icon: 'pi pi-fw pi-file', className: 'p-button-info' }}
                    uploadOptions={{ label: 'Yükle', icon: 'pi pi-fw pi-upload', className: 'p-button-success text-white' }}
                    cancelOptions={{ label: 'İptal', icon: 'pi pi-fw pi-times', className: 'p-button-danger' }}
                    className="w-full"
                    customUpload
                    uploadHandler={(e) => setFile(e.files[0])}
                    invalidFileSizeMessageDetail=""
                    invalidFileSizeMessageSummary=' Dosya boyutu 3MB dan büyük olamaz.'
                />
            </div>
            <div className="py-3">
                <Checkbox inputId="isDefault" onChange={(e) => setIsDefault(e.checked)} checked={isDefault} className='mr-2' />
                <label htmlFor="isDefault" className="p-checkbox-label">Varsayılan</label>
            </div>
            <div className="py-3">
                <Checkbox inputId="isActive" onChange={(e) => setIsActive(e.checked)} checked={isActive} className='mr-2' />
                <label htmlFor="isActive" className="p-checkbox-label">Aktif</label>
            </div>
            <div className="text-center mt-5">
                <Button label="Ekle" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => handleUpload()} disabled={sendButtonIsDisabled()} />
            </div>
        </Dialog>
    );
}