import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { removeToken, removeUser, setToken, setUser } from "../../service/tokenServices";
import { login, logout } from '../../service/request/authRequest';

export const loginAsyncThunk = createAsyncThunk("auth/login", async ({ email, password }) => {
    const response = await login(email, password);
    return response.data;
});

export const logoutAsyncThunk = createAsyncThunk("auth/logout", async () => {
    const response = await logout();
    return response.data;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isAuthenticated: false
    },
    reducers: {
        setStoreUser: (state, action) => {
            state.user = action.payload;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loginAsyncThunk.fulfilled, (state, action) => {
                state.user = action.payload.data.user;
                setToken(action.payload.data.token);
                setUser(action.payload.data.user);
                state.isAuthenticated = true;
            })
            .addCase(logoutAsyncThunk.fulfilled, (state, action) => {
                state.user = null;
                state.isAuthenticated = false;
                removeToken();
                removeUser();
            });
    }
});

export const { setStoreUser, setIsAuthenticated } = authSlice.actions;
export default authSlice.reducer;