import React, { useEffect, useState } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';

import InvoiceUpdateDialog from './eFaturaRapid/components/InvoiceUpdateDialog.js';
import ExemptionDialog from './eFaturaRapid/components/ExemptionDialog.js';
import InvoiceViewDialog from './eFaturaRapid/components/InvoiceViewDialog.js';
import InvoiceLogDialog from './eFaturaRapid/components/InvoiceLogDialog.js';

import { getInvoices, sendInvoices, viewInvoice, getInvoicesStatus, sendStatusInvoice } from '../module/rapid/utils/RapidRequest.js';
import { getErrorReceiptCount, sendReceiptBtnIsDisabled, getSendingDataDetails, invoiceDocType, sendTypeEnum, getReasonCode } from './eFaturaRapid/utils/utils.js';
import { controlAllData, rowIsTrue, wrongRow } from './eFaturaRapid/utils/controller.js';
import { localeDate, formatDate, formatAmount, HeaderProgram } from './utils/utils.js';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import Loading from '../Loading.js';

export default function RapidInvoicePage() {
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [invoices, setInvoices] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [selectedInvoiceDialog, setSelectedInvoiceDialog] = useState(false);
    const [vatExcemptionDialog, setVatExcemptionDialog] = useState(false);
    const [selectedInvoiceUnit, setSelectedInvoiceUnit] = useState(null);
    const [errorReceiptsCount, setErrorReceiptsCount] = useState(0);
    const [invoiceViewDialog, setInvoiceViewDialog] = useState(false);
    const [invoiceView, setInvoiceView] = useState(null);
    const [logDialog, setLogDialog] = useState(false);
    const [logList, setLogList] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [invoiceReasonCode, setInvoiceReasonCode] = useState(null);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [sendDialog, setSendDialog] = useState(false);
    const [pending, setPending] = useState(false);
    const [sendLogDialog, setSendLogDialog] = useState(false);
    const [checkErrorReceipts, setCheckErrorReceipts] = useState(false);

    const dt = useRef(null);
    const toast = useRef(null);
    addLocale('tr', localeDate);

    useEffect(() => {
        if (invoices.length !== 0) setErrorReceiptsCount(getErrorReceiptCount(invoices));
    }, [invoices]);

    useEffect(() => {
        if (selectedInvoiceUnit) {
            setInvoiceReasonCode(getReasonCode(invoices, selectedInvoiceUnit));
        }
    }, [selectedInvoiceUnit]);

    const getReceiptList = async () => {
        setSelectedReceipt(null);
        setLoading(true);
        let selectedModule = selectedCompany?.modules?.find((module) => module.title === 'E-Fatura');
        const req = selectedModule?.setting?.settings?.program?.requirements[0];

        try {
            const res = await getInvoices({
                clientId: req.client_id,
                clientSecret: req.client_secret,
                startDate: formatDate(startDate, 'dashReverse'),
                endDate: formatDate(endDate, 'dashReverse'),
                dealerID: req.DealerID,
                eInvoiceCode: req.EInvoiceCode,
                systemId: req.SystemId
            });

            const copy = [...res?.data.data];
            setInvoices(copy);
            setLoading(false);

            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fatura başarıyla getirildi.', life: 3000 });
            }, 100);
        } catch (error) {
            setLoading(false);
            setTimeout(() => {
                error.code === 'ERR_NETWORK' ? toast.current.show({ severity: 'error', summary: 'Hata', detail: "İstek zaman aşımına uğradı", life: 3000 }) : toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
            }, 100);
        }
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const rowExpansionTemplate = (rowData) => {
        const salesInvoiceDetails = rowData.EInvoicesLines;
        return (
            <div className="p-3">
                <h5>Fatura Detayı</h5>
                <DataTable emptyMessage="Ürün Bulunamadı" value={salesInvoiceDetails} dataKey="$id">
                    <Column field="UrunAdi" header="Ürün Adı" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="MalzemeKodu" header="Ürün Kodu" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="Birim" header="Birim" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="KDVOrani" header="KDV Oranı" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="IndirimTutari" header="İndirim Tutarı" body={(rowData) => formatAmount(rowData.IndirimTutari, 'TRY')} style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="KDVTutari" header="KDV Tutarı" body={(rowData) => formatAmount(rowData.KDVTutari, 'TRY')} style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="SatirTutari" header="Satır Tutarı" body={(rowData) => formatAmount(rowData.SatirTutari, 'TRY')} style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="Miktar" header="Miktar" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="Depo" header="Depo" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column header="İşlemler" body={actionSubTableTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>
        );
    };

    const sendViewReceipts = async (rowData) => {
        const data = [rowData];
        const reqData = getSendingDataDetails(selectedCompany);

        reqData.data = data;

        setPending(true);
        try {
            const res = await viewInvoice(reqData);
            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                setInvoiceView(res.data.data);
                setInvoiceViewDialog(true);
            }

            setPending(false);
        } catch (error) {
            setPending(false);
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    const logInvoices = async () => {
        const data = [...selectedReceipt];
        const reqData = getSendingDataDetails(selectedCompany);
        reqData.data = data;
        setSendLogDialog(false);

        setPending(true);
        try {
            const res = await sendStatusInvoice(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                setPending(false);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Faturalar başarıyla onaylandı.', life: 3000 });
                setTimeout(() => {
                    getReceiptList();
                }, 1500);
            }
        } catch (error) {
            setPending(false);
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    const sendReceipts = async () => {
        const data = [...selectedReceipt];
        const reqData = getSendingDataDetails(selectedCompany);
        reqData.data = data;
        setSendDialog(false);

        setPending(true);
        try {
            const res = await sendInvoices(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                setPending(false);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Faturalar başarıyla gönderildi.', life: 3000 });
                setTimeout(() => {
                    getReceiptList();
                }, 1500);
            }
        } catch (error) {
            setPending(false);
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h4 className="m-0">Fatura Listesi</h4>
                {invoices.length !== 0 && (
                    <div className="flex align-items-center">
                        <div className="m-0 p-badge p-overlay-badge p-badge-info">
                            Hatalı Fatura Sayısı <span className="p-badge p-badge-danger">{errorReceiptsCount}</span>
                        </div>
                        <div className="ml-6 flex align-items-end gap-2">
                            <label className="block text-white font-normal">Hatalı Faturalar</label>
                            <InputSwitch
                                checked={checkErrorReceipts}
                                onChange={(e) => {
                                    setCheckErrorReceipts(e.value);
                                    setSelectedReceipt(null);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div>
                <Button
                    icon="pi pi-check"
                    severity="warning"
                    className="mr-4 "
                    label="Gönderildi Olarak İşaretle"
                    visible={invoices.length > 0}
                    loading={pending}
                    disabled={sendReceiptBtnIsDisabled(selectedReceipt)}
                    onClick={() => setSendLogDialog(true)}
                />
                <Button
                    icon="pi pi-arrow-right"
                    severity="success"
                    className="mr-4 text-white"
                    label="Faturaları Gönder"
                    visible={invoices.length > 0}
                    loading={pending}
                    disabled={sendReceiptBtnIsDisabled(selectedReceipt)}
                    onClick={() => setSendDialog(true)}
                />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="align-items-center flex">
                <Button
                    icon="pi pi-eye"
                    severity="info"
                    className="mr-2"
                    size="large"
                    tooltip="Görüntüle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    disabled={!controlAllData(rowData)}
                    rounded
                    loading={pending}
                    onClick={() => sendViewReceipts(rowData)}
                />
                <Button
                    icon="pi pi-pencil"
                    severity="success"
                    tooltip="Düzenle"
                    size="small"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    className="text-white"
                    onClick={() => {
                        setSelectedInvoice(rowData);
                        setSelectedInvoiceDialog(true);
                    }}
                />
            </div>
        );
    };

    const updateInvoiceUnit = (selectedVatExemption) => {
        const copy = [...invoices];
        copy.forEach((invoice) => {
            invoice.EInvoicesLines.forEach((detail) => {
                if (detail.$id === selectedInvoiceUnit.$id) {
                    const vatExemption = invoiceReasonCode.filter((option) => option.value === selectedVatExemption)[0];
                    detail.vat_exemption_reason = vatExemption;
                }
            });
        });
        setInvoices(copy);
        setVatExcemptionDialog(false);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'İstisna başarıyla eklendi.', life: 3000 });
    };

    const actionSubTableTemplate = (rowData) => {
        if (rowData.KDVOrani === 0.0) {
            return (
                <React.Fragment>
                    <Button
                        icon="pi pi-file-excel"
                        tooltip="İstisna Ekle"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        onClick={(e) => {
                            setVatExcemptionDialog(true);
                            setSelectedInvoiceUnit(rowData);
                        }}
                    />
                </React.Fragment>
            );
        }
    };

    const onCloseVatExemptionDialog = () => {
        setVatExcemptionDialog(false);
        setSelectedInvoiceUnit(null);
    };

    const onCloseInvoiceViewDialog = () => {
        setInvoiceViewDialog(false);
        setInvoiceView(null);
    };

    const onCloseInvoiceDialog = () => {
        setSelectedInvoiceDialog(false);
        setSelectedInvoice(null);
    };

    const updateInvoice = (invoiceType, selectedAlias, checked, selectedCounter) => {
        setSelectedInvoiceDialog(false);
        const copy = [...invoices];
        copy.forEach((invoice) => {
            if (invoice.$id === selectedInvoice.$id) {
                const selectedMail = invoice.aliases.find((alias) => alias.alias === selectedAlias);
                invoice.alias = selectedMail;
                invoice.send_type = checked;
                invoice.fatura_tipi = invoiceType;
                invoice.counterId = {
                    id: selectedCounter.id,
                    prefix: selectedCounter.prefix
                };
            }
        });
        setInvoices(copy);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fatura başarıyla güncellendi.', life: 3000 });
    };

    const getStatus = async () => {
        let logDate = new Date();
        logDate = formatDate(logDate, 'slash');
        let selectedModule = selectedCompany.modules.find((module) => module.title === 'E-Fatura');
        getInvoicesStatus({
            companyId: selectedModule.setting.settings.program.requirements[0].DealerID + selectedModule.setting.settings.program.requirements[0].EInvoiceCode,
            date: logDate
        })
            .then((res) => {
                setLogList(res?.data.data);
                setLogDialog(true);
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: err.response.data.message, life: 3000 });
            });
    };

    const getCounterTemplate = (rowData) => {
        if (rowData.counterId === null) {
            return <span className="p-badge p-badge-danger text-white">Boş</span>;
        }
        return <span className="p-badge p-badge-success text-white">{rowData.counterId.prefix}</span>;
    };

    return (
        <div>
            <Toast ref={toast} />
            <HeaderProgram selectedCompany={selectedCompany} moduleName={'E-Fatura'} />
            <br />
            <h3 className="mb-5">Fatura Tablosu</h3>
            <div className="card flex align-items-end justify-content-between">
                <div className="flex align-items-end gap-5">
                    <div className="w-17rem">
                        <label className="block mb-2" htmlFor="cal_date">
                            Başlangıç Tarih
                        </label>
                        <Calendar className="w-full" value={startDate} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setStartDate(e.value)} showIcon placeholder="Lütfen başlangıç tarihi seçiniz" />
                    </div>
                    <div className="w-17rem">
                        <label className="block mb-2" htmlFor="cal_date">
                            Bitiş Tarih
                        </label>
                        <Calendar className="w-full" value={endDate} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setEndDate(e.value)} showIcon placeholder="Lütfen bitiş tarihi seçiniz" />
                    </div>
                    <Button icon="pi pi-ticket" onClick={() => getReceiptList()} className="px-4" label="Fatura Getir" disabled={!(startDate && endDate)} />
                </div>
                <Button icon="pi pi-ticket" className="px-4 " label="Geçmiş Faturalar" onClick={() => getStatus()} disabled={!startDate || !endDate} />
            </div>

            <div className="card">
                <DataTable
                    ref={dt}
                    value={checkErrorReceipts ? invoices.filter((invoice) => wrongRow(invoice)) : invoices}
                    dataKey="$id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} faturadan {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Fatura Bulunamadı"
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    className="p-datatable-gridlines"
                    selectionMode={'checkbox'}
                    selection={selectedReceipt}
                    onSelectionChange={(e) => setSelectedReceipt(e.value)}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column expander style={{ width: '2rem' }} />
                    <Column style={{ minWidth: '2rem' }} body={(rowData) => rowIsTrue(rowData)}></Column>
                    <Column field="send_type" header="Gönderim Şekli" sortable style={{ minWidth: '6rem' }} body={(rowData) => sendTypeEnum[rowData.send_type]}></Column>
                    <Column field="isEInvoice" header="Belge Tipi" sortable style={{ minWidth: '6rem' }} body={(rowData) => invoiceDocType(rowData.isEInvoice)}></Column>
                    <Column field="FaturaTarihi" header="Fatura Tarihi" sortable style={{ minWidth: '6rem' }} body={(rowData) => formatDate(rowData.FaturaTarihi, 'dot')}></Column>
                    <Column field="FaturaTipi" header="Fatura Türü" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="MusteriKodu" header="Müşteri Kodu" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="FaturalanacakMusteriAdi" header="Müşteri Adı" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="VergiNo" header="Vergi No" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="FaturaNo" header="Fatura No" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="KDVTutarToplami" header="Vergi Tutarı" sortable body={(rowData) => formatAmount(rowData.KDVTutarToplami, 'TRY')} style={{ minWidth: '6rem' }}></Column>
                    <Column field="TutarToplami" header="Vergiler Dahil Tutar" sortable body={(rowData) => formatAmount(rowData.TutarToplami, 'TRY')} style={{ minWidth: '6rem' }}></Column>
                    <Column field="IndirimToplami" header="İndirim Toplamı" sortable body={(rowData) => formatAmount(rowData.IndirimToplami, 'TRY')} style={{ minWidth: '6rem' }}></Column>
                    <Column field="AktarimToplami" header="Aktarım Toplamı" sortable body={(rowData) => formatAmount(rowData.AktarimToplami, 'TRY')} style={{ minWidth: '6rem' }}></Column>
                    <Column field="alias.alias" header="Alıcı Posta Kutusu" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="counterId.prefix" header="Sayaç Ön Ek" sortable style={{ minWidth: '6rem' }} body={(rowData) => getCounterTemplate(rowData)}></Column>
                    <Column header="İşlemler" body={actionBodyTemplate} className="text-center" exportable={false} style={{ minWidth: '5rem' }}></Column>
                </DataTable>
            </div>

            <ExemptionDialog reasonCode={invoiceReasonCode} dialogVisible={vatExcemptionDialog} onCloseDialog={onCloseVatExemptionDialog} onSubmitDialog={updateInvoiceUnit} />

            <InvoiceUpdateDialog dialogVisible={selectedInvoiceDialog} selectedInvoice={selectedInvoice} onCloseDialog={onCloseInvoiceDialog} onSubmitDialog={updateInvoice} />

            <InvoiceViewDialog invoiceVisible={invoiceViewDialog} invoiceView={invoiceView} onCloseDialog={onCloseInvoiceViewDialog} />

            <InvoiceLogDialog logVisible={logDialog} logList={logList} onCloseDialog={() => setLogDialog(false)} />

            <Dialog
                header="Fatura Gönderim İşlemi"
                visible={sendDialog}
                style={{ width: '20vw' }}
                onHide={() => setSendDialog(false)}
                footer={
                    <div className="flex justify-content-end">
                        <Button label="İptal" severity="secondary" className="mr-2" onClick={() => setSendDialog(false)} />
                        <Button label="Gönder" onClick={() => sendReceipts()} />
                    </div>
                }
            >
                {selectedReceipt?.length === 1 ? <p>Seçili faturayı göndermek istediğinize emin misiniz ?</p> : <p>Seçili {selectedReceipt?.length} faturayı göndermek istediğinize emin misiniz ?</p>}
            </Dialog>

            <Dialog
                header="Gönderildi Olarak İşaretleme İşlemi"
                visible={sendLogDialog}
                style={{ width: '20vw' }}
                onHide={() => setSendLogDialog(false)}
                footer={
                    <div className="flex justify-content-end">
                        <Button label="İptal" severity="secondary" className="mr-2" onClick={() => setSendLogDialog(false)} />
                        <Button label="Gönder" onClick={() => logInvoices()} />
                    </div>
                }
            >
                {selectedReceipt?.length === 1 ? <p>Seçili faturayı gönderildi olarak işaretlemek istediğinize emin misiniz ?</p> : <p>Seçili {selectedReceipt?.length} faturayı gönderildi olarak işaretlemek istediğinize emin misiniz ?</p>}
            </Dialog>
        </div>
    );
}
