import React from 'react';
import biengif from '../assets/flags/BienMovieGif.gif';

export default function Loading() {
    return (
        <div className="card flex justify-content-center align-items-center h-full w-full">
            <div className="flex flex-column justify-content-center align-items-center">
                <img width={180} height={80} src={biengif} alt="loading" />
                <h4 className="mb-0">İşleminiz Devam Ediyor</h4>
                <h5 className="mt-3">Lütfen Bekleyiniz</h5>
            </div>
        </div>
    );
}
