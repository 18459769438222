import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

export default function SendDialog({ visible, onCloseDialog, selectedReceiptLength, onSendReceipts }) {
    return (
        <Dialog
            header="Fatura Gönderim İşlemi"
            visible={visible}
            style={{ width: '20vw' }}
            onHide={() => onCloseDialog()}
            footer={
                <div className="flex justify-content-end">
                    <Button label="İptal" severity="secondary" className="mr-2" onClick={() => onCloseDialog()} />
                    <Button label="Gönder" onClick={() => onSendReceipts()} />
                </div>
            }
        >
            {
                selectedReceiptLength === 1 ?
                    <p>Seçili faturayı göndermek istediğinize emin misiniz ?</p> :
                    <p>Seçili {selectedReceiptLength} faturayı göndermek istediğinize emin misiniz ?</p>
            }
        </Dialog>
    )
}