import { getAllModulesAsyncThunk } from '../../store/modules/moduleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { setRequirements } from '../../store/modules/moduleSlice';
import { useNavigate } from 'react-router-dom';
import { convertToEnglishChars } from './utils/utils';

export default function AllModule() {
    const { moduleList } = useSelector((state) => state.modules);
    const { updatedModule } = useSelector((state) => state.modules);
    const dispatch = useDispatch();
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAllModulesAsyncThunk());
    }, [dispatch]);

    const getPrograms = (programs) => {
        return programs?.map((program) => {
            return {
                title: program.title,
                value: program._id
            };
        });
    };

    const getServices = (services) => {
        return services?.map((service) => {
            return {
                title: service.title,
                value: service._id
            };
        });
    };

    const isDisabled = (programs, webServices) => {
        if (selectedProgram && selectedService) {
            let program = programs.find((program) => program._id === selectedProgram);
            let webService = webServices.find((webService) => webService._id === selectedService);

            if (program && webService) {
                return false;
            }
        }
        return true;
    };

    const navigateModuleMatchPage = (id) => {
        const module = moduleList.find((module) => module._id === id);
        const programReq = module.programs.find((program) => program._id === selectedProgram);
        const serviceReq = module.webServices.find((service) => service._id === selectedService);
        dispatch(setRequirements({ programReq, serviceReq, module }));
        const programTitle = programReq?.title.split(' ')[0];
        navigate(`/matchmodules/${module.title}/${convertToEnglishChars(programTitle)}`);
    };

    const getModuleReq = () => {
        return moduleList?.filter((module) => updatedModule?.title === module.title);
    };

    return (
        <div className="h-full">
            <div className="h-full flex align-items-start gap-8 justify-content-start w-full">
                {getModuleReq().map((module) => {
                    return (
                        <div className="card md:w-25rem">
                            <h3>{module.title}</h3>
                            <label className="block mb-2">Program</label>
                            <Dropdown dataKey="_id" value={selectedProgram} onChange={(e) => setSelectedProgram(e.value)} options={getPrograms(module.programs)} optionLabel="title" placeholder="Program Seçiniz" filter className="w-full mb-4" />

                            <label className="block mb-2">Web Servisi</label>
                            <Dropdown
                                dataKey="_id"
                                value={selectedService}
                                onChange={(e) => setSelectedService(e.value)}
                                options={getServices(module.webServices)}
                                optionLabel="title"
                                placeholder="Web Servisi Seçiniz"
                                filter
                                className="w-full mb-4"
                            />

                            <div className="text-center">
                                <Button label="Güncelle" className="p-button-success w-full text-white" disabled={isDisabled(module.programs, module.webServices)} onClick={() => navigateModuleMatchPage(module._id)} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
