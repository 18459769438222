import receiptCode from '../../../assets/data/receiptCode.json';
import { Button } from 'primereact/button';

export const selectLastThreeMonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3);
    return threeMonthsAgo;
};

export const selectLastTwoMonths = () => {
    const today = new Date();
    const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3);
    const lastDayOfMonth = new Date(twoMonthsAgo.getFullYear(), twoMonthsAgo.getMonth() + 1, 0);
    return lastDayOfMonth;
};

export const getCompany = (selectedCompany) => {
    let copy = [];
    let selectedModule = selectedCompany.modules.find((module) => module.title === 'E-Defter');
    selectedCompany.modules[0].program.lucaCompanies.map((company) => {
        copy.push({ label: company.companyName, value: company.companyCode });
    });
    return copy;
};

export const getInvoice = (selectedCompany) => {
    let copy = [];
    selectedCompany.modules[1].program.parasutCompanies.map((company) => {
        copy.push({ label: company.name, value: company.id });
    });
    return copy;
};

export const getReceiptCode = (receipt) => {
    const copy = [];
    receipt.map((item) => {
        let find = null;

        if (item.EdefterEnum === 'other') {
            find = copy.find((x) => x.title === 'Diğer');
        }

        if (!find) {
            if (item.EdefterEnum !== 'other') {
                copy.push({ title: item.content, value: item.code });
            } else {
                copy.push({ title: 'Diğer', value: item.code });
            }
        }
    });

    return copy;
};

export const getSendingDataDetails = (selectedCompany, selectedCompanyCode) => {
    let req = null;
    let selectedModule = selectedCompany.modules.find((module) => module.title === 'E-Fatura');

    if (selectedModule.setting.settings.program.title === 'Paraşüt E-Fatura') {
        let company = selectedModule.setting.settings.program.parasutCompanies.find((company) => company.id === selectedCompanyCode);
        req = company.webService;
    } else {
        req = selectedModule.setting.settings.webService;
    }

    return {
        username: req.requirements.username,
        password: req.requirements.password,
        webServiceUrl: req.url
    };
};

export const isOther = (code) => {
    return receiptCode.find((item) => item.code === code).EdefterEnum === 'other';
};

export const getOtherCode = () => {
    const copy = [];
    receiptCode.map((item) => {
        if (item.EdefterEnum === 'other') {
            copy.push({ title: item.content, value: item.code });
        }
    });

    return copy;
};

//
export const formatDate = (inputDate, formatType) => {
    let newDate = null;
    let date = null;

    if (typeof inputDate === 'string') {
        newDate = inputDate.split('/');
        date = new Date(`${newDate[2]}-${newDate[1]}-${newDate[0]}`);
    } else {
        date = new Date(inputDate);
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    if (formatType === 'dot') {
        return `${day}.${month}.${year}`;
    } else if (formatType === 'slash') {
        return `${day}/${month}/${year}`;
    } else if (formatType === 'dash') {
        return `${day}-${month}-${year}`;
    } else if (formatType === 'dashReverse') {
        return `${year}-${month}-${day}`;
    }
};

export const mapTipNoToLabel = (tipNo) => {
    const tipLabels = {
        1: 'Tahsil',
        2: 'Tediye',
        0: 'Açılış',
        4: 'Kapanış',
        3: 'Mahsup'
    };

    return tipLabels[tipNo] || '';
};

export const localeDate = {
    firstDayOfWeek: 1,
    dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
    dayNamesShort: ['Pz', 'Pt', 'Sl', 'Çr', 'Pr', 'Cu', 'Ct'],
    dayNamesMin: ['P', 'P', 'S', 'Ç', 'P', 'C', 'C'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
    monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
    today: 'Bugün',
    clear: 'Temizle'
};

export const formatAmount = (money, formatType) => {
    if (formatType === 'TRL') {
        formatType = 'TRY';
    }

    let amount = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: formatType
    }).format(money);

    return <p> {amount} </p>;
};

export const HeaderProgram = ({ selectedCompany, moduleName }) => {
    if (!selectedCompany) {
        return null;
    }
    const module = selectedCompany?.modules?.filter((module) => module?.title === moduleName);
    const program = module[0]?.setting?.settings?.program?.title;

    //Program adına bakılacak
    if (program === 'Rapid360' || program === 'Datasoft') {
        const webService = module[0]?.setting?.settings?.webService?.title;
        return <h1>{program + ' / ' + webService}</h1>;
    }

    return <h1>{program}</h1>;
};

export const getCSVStatus = (status) => {
    if (status === '0') {
        return <span className=" p-badge p-badge-warning text-white w-auto h-auto">Kaynak Defter Oluşturuldu</span>;
    }
    if (status === '1') {
        return <span className=" p-badge p-badge-info text-white w-auto h-auto">Zip Oluşturuldu</span>;
    }
    if (status === '2') {
        return <span className=" p-badge p-badge-success text-white w-auto h-auto">Kaynak Defter Portale Gönderildi</span>;
    }

    return <span className="text-danger text-white w-auto h-auto">Hata</span>;
};

export const EditButtonTemplate = ({ data, onEdit = () => {}, onAllEdit = () => {} }) => {
    if (!data || data.length === 0) {
        return null;
    } else if (data.length === 1) {
        return <Button icon="pi pi-pencil" className="mr-4" label="Düzenle" onClick={() => onEdit()} />;
    } else if (data.length > 1) {
        return <Button icon="pi pi-pencil" className="mr-4" label="Toplu Düzenle" onClick={() => onAllEdit()} />;
    }
};

export const formatDateAndTime = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};
