import axios from 'axios';

let BASE_URL = process.env.REACT_APP_INT_URL;

async function importReceiptsExcel(data) {
    return await axios.post(`${BASE_URL}/data-soft/importReceiptsExcel`, data);
}

async function sendReceiptsData(data) {
    return await axios.post(`${BASE_URL}/data-soft/sendReceipts`, data);
}

async function getCsvList(data) {
    return await axios.post(`${BASE_URL}/edefter/getCsvList`, data);
}

async function sendCsv(data) {
    return await axios.post(`${BASE_URL}/edefter/sendCsv`, data);
}

async function deleteCsv(data) {
    return await axios.post(`${BASE_URL}/edefter/delete`, data);
}

async function portalDefterCheck(data) {
    return await axios.post(`${BASE_URL}/portal/checkELedger`, data);
}

export { importReceiptsExcel, sendReceiptsData, getCsvList, sendCsv, deleteCsv, portalDefterCheck };
