let BASE_URL = process.env.REACT_APP_SWAGGER_URL;

export const auth = {
    login: BASE_URL + '/login',
    logout: BASE_URL + '/logout',
    register: BASE_URL + '/register'
};

export const users = {
    getAllUsers: BASE_URL + '/user/getAll',
    getCompaniesByUserId: BASE_URL + '/user/getCompaniesByUserId',
    createUser: BASE_URL + '/user/create',
    updateUser: BASE_URL + '/user/update',
    deleteUser: BASE_URL + '/user/delete',
    updateProfile: BASE_URL + '/user/updateProfile'
};

export const companies = {
    getAllCompanies: BASE_URL + '/user/company/getAll',
    getModulesByCompany: BASE_URL + '/companies/modules/getModulesByCompany',
    createCompany: BASE_URL + '/user/company/create',
    updateCompany: BASE_URL + '/user/company/update',
    deleteCompany: BASE_URL + '/user/company/delete',
    attachUser: BASE_URL + '/companies/users/attachUser',
    detachUser: BASE_URL + '/companies/users/detachUser',
    attachModule: BASE_URL + '/companies/modules/attachModule',
    detachModule: BASE_URL + '/companies/modules/detachModule',
    setCompanySettings: BASE_URL + '/companies/settings/setCompanySettings',
    getCompanySettings: BASE_URL + '/companies/settings/getCompanySettings',

    getByUser: BASE_URL + '/user/getCompaniesByUser',
    getById: BASE_URL + '/user/getById',
    updateSettings: BASE_URL + '/company/setSettingsByUser'
};

export const modules = {
    getAllModules: BASE_URL + '/modules/getAll',
    addLuca: BASE_URL + '/modules/edefter/addLuca',
    updateLuca: BASE_URL + '/modules/edefter/updateLuca',
    deleteModule: BASE_URL + '/modules/deleteModule',
    getAllCommercialProgramsByModuleId: BASE_URL + '/modules/getAllCommercialProgramsByModuleId',
    getAllWebServicesByModuleId: BASE_URL + '/module/getWebServices',
    addParasut: BASE_URL + '/modules/efatura/addParasut',
    updateParasut: BASE_URL + '/modules/efatura/updateParasut',

    addRapid: BASE_URL + '/modules/efatura/addRapid',
    updateRapid: BASE_URL + '/modules/efatura/updateRapid',

    updateCompanyModule: BASE_URL + '/modules/updateCompanyModule',

    addDatasoft: BASE_URL + '/modules/edefter/addDatasoft',
    updateDatasoft: BASE_URL + '/modules/edefter/updateDatasoft',

    updateLucaPassword: BASE_URL + '/module/updateLucaPassword'
};

export const tickets = {
    getAll: BASE_URL + '/user/ticket/getAll',
    create: BASE_URL + '/user/ticket/create',
    sendMessage: BASE_URL + '/user/ticket/sendMessage',
    getById: BASE_URL + '/user/ticket/getById'
};

export const counters = {
    getCounters: BASE_URL + '/counters/getCounters',
    addCounter: BASE_URL + '/counters/addCounter',
    updateCounter: BASE_URL + '/counters/updateCounter',
    deleteCounter: BASE_URL + '/counters/deleteCounter'
};

export const education = {
    getAll: BASE_URL + '/education/category/getAll',
    getById: BASE_URL + '/education/category/getById',
    create: BASE_URL + '/education/category/create',
    update: BASE_URL + '/education/category/update',
    delete: BASE_URL + '/education/category/delete',
    getAllVideos: BASE_URL + '/education/videos/getAll',
    getVideoById: BASE_URL + '/education/videos/getById',
    createVideo: BASE_URL + '/education/videos/create',
    updateVideo: BASE_URL + '/education/videos/update',
    deleteVideo: BASE_URL + '/education/videos/delete'
};
