import React, {useState, useEffect} from 'react'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'

import { getVatWithholdingReasons } from '../../../module/parasut/utils/parasutRequest'


export default function VatWithholdingDialog({ visible, selectedInvoiceUnit, onCloseDialog, onSetVatWithholding}) {
    const [selectedVatWithholding, setSelectedVatWithholding] = useState(null)
    const [vatWithholding, setVatWithholding] = useState([])

    useEffect(() => {
        if (visible && vatWithholding.length === 0) {
            const req = async () => {
                const res = await getVatWithholdingReasons();
                setVatWithholding(res.data.data);
            };
            req();
        }
    }, [visible]);

    if (!visible) return null

    return (
        <Dialog header="Tevkifat Ekleme" visible={visible} style={{ width: '30vw' }} modal onHide={() => onCloseDialog()}>
            <div className="py-3">
                <div className="mb-2">
                    <label>Tevkifat Ekleme</label>
                </div>

                <div className="">
                    <Dropdown
                        dataKey="code"
                        value={selectedVatWithholding}
                        onChange={(e) => setSelectedVatWithholding(e.value)}
                        options={vatWithholding.filter((item) => item.rate[0] === selectedInvoiceUnit?.attributes.vat_withholding_rate[0])}
                        optionLabel={(option) => option.rate + ' - ' + option.code + ' - ' + option.name.substring(0, 70)}
                        placeholder="Tevkifat Seçiniz"
                        filter
                        className="w-full"
                        emptyMessage="Tevkifat Bulunamadı"
                        emptyFilterMessage="Tevkifat Bulunamadı"
                    />
                </div>
                <div className="justify-content-end flex mt-5">
                    <Button icon="pi pi-check" label="Tevkifat Ekle" onClick={() => onSetVatWithholding(selectedVatWithholding)} disabled={!selectedVatWithholding} />
                </div>
            </div>
        </Dialog>
    )
}