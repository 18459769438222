import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { getInvoiceTypes } from '../utils/parasutRequest';
import { addCounter, updateCounter } from '../../../../service/request/countersRequest';

export default function CounterAddDialog({ companyId, updateCounterDialog, onHideCounterDialog, selectedCounter }) {
    const [selectedInvoiceType, setSelectedInvoiceType] = useState(null);
    const [prefix, setPrefix] = useState('');
    const [year, setYear] = useState('');
    const [counter, setCounter] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const [invoiceType, setInvoiceType] = useState([]);

    useEffect(() => {
        if (!updateCounterDialog) {
            setSelectedInvoiceType(null);
            setPrefix('');
            setYear('');
            setCounter('');
            setIsDefault(false);
        } else {
            setSelectedInvoiceType(selectedCounter.invoice_type_id);
            setPrefix(selectedCounter.prefix);
            setYear(selectedCounter.year);
            setCounter(selectedCounter.counter);
            if (selectedCounter.is_default === 1) {
                setIsDefault(true);
            } else {
                setIsDefault(false);
            }
        }
    }, [updateCounterDialog]);

    useEffect(() => {
        if (updateCounterDialog && invoiceType.length === 0) {
            getInvoiceTypes().then((res) => {
                const copy = [];
                res.data.data.forEach((item) => {
                    copy.push({ title: item.name, value: item.id });
                });
                setInvoiceType(copy);
            });
        }
    }, [invoiceType, updateCounterDialog]);

    const sendButtonIsDisabled = () => {
        return !selectedInvoiceType || !year || !prefix || !counter;
    };

    const handleSave = () => {
        onHideCounterDialog();
        const data = {
            id: String(selectedCounter.id),
            companyId: companyId,
            invoiceTypeId: String(selectedInvoiceType),
            prefix: prefix,
            year: year,
            counter: counter,
            isDefault: isDefault ? 1 : 0
        };

        updateCounter(data)
            .then((res) => {
                onHideCounterDialog();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function convertToEnglishUpperCase(str) {
        const turkishChars = { ç: 'C', ğ: 'G', ı: 'I', i: 'I', ö: 'O', ş: 'S', ü: 'U', ü: 'U' };
        return str
            .replace(/[çğıiöşüĞÇİÖÜŞ0-9]/g, (m) => turkishChars[m] || m)
            .replace(/[^a-zA-Z0-9]/g, '')
            .toUpperCase();
    }

    return (
        <Dialog header="Sayaç ekle" visible={updateCounterDialog} style={{ width: '50vw' }} modal onHide={() => onHideCounterDialog()}>
            <div className="mt-4">
                <label className="mb-2 block">Fatura Tipleri</label>
                <Dropdown
                    dataKey="id"
                    value={selectedInvoiceType}
                    onChange={(e) => setSelectedInvoiceType(e.value)}
                    options={invoiceType}
                    optionLabel="title"
                    placeholder="Fatura Tipi Seçiniz"
                    filter
                    className="w-full mb-2"
                    emptyMessage="Fatura Tipi Bulunamadı"
                    appendTo="self"
                />
            </div>
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Yıl</label>
                </div>
                <div className="w-full">
                    <InputText onChange={(e) => setYear(e.target.value)} placeholder="Yıl Giriniz" value={year} type="number" className="w-full" />
                </div>
            </div>
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Ön Ek</label>
                </div>
                <div className="w-full">
                    <InputText onChange={(e) => setPrefix(convertToEnglishUpperCase(e.target.value))} placeholder="Ön Ek Giriniz" value={prefix} type="text" className="w-full" />
                </div>
            </div>
            <div className="py-3">
                <div className="w-full mb-2">
                    <label>Sayaç</label>
                </div>
                <div className="w-full">
                    <InputText onChange={(e) => setCounter(e.target.value)} placeholder="0" value={counter} type="number" className="w-full" />
                </div>
            </div>
            <div className="py-3">
                <Checkbox inputId="isDefault" onChange={(e) => setIsDefault(e.checked)} checked={isDefault} className="mr-2" />
                <label htmlFor="isDefault" className="p-checkbox-label">
                    Varsayılan
                </label>
            </div>
            <div className="text-center mt-5">
                <Button label="Güncelle" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => handleSave()} disabled={sendButtonIsDisabled()} />
            </div>
        </Dialog>
    );
}
